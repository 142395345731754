import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import CreateRatingForm from "../../components/forms/rating/CreateRatingForm";

type openModalProps = {
  reportTemplateId: string;
  sectionId: string;
};

export default function useCreateRating(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = (props: openModalProps) => {
    const { reportTemplateId, sectionId } = props;

    const modalId = modals.openModal({
      size: "xl",
      title: "Beoordelingspunt aanmaken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <CreateRatingForm
            reportTemplateId={reportTemplateId}
            sectionId={sectionId}
            onClose={() => closeModal(modalId, reportTemplateId)}
          />
        </>
      ),
    });
  };

  const closeModal = (id: string, reportTemplateId: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/report-templates/${reportTemplateId}`);
  };

  return {
    openModal,
  };
}
