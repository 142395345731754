import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { deleteSchoolYear } from "../../api/school-years";
import useMutateSchoolYearData from "./useMutateSchoolYearData";

export default function useDeleteSchoolYear() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Schooljaar verwijderd",
      icon: <IconCheck />,
      message: `Het schooljaar is verwijderd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het verwijderen van het schooljaar. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateSchoolYearData(deleteSchoolYear, onSuccess, onError);

  const onSubmit = async (modalId: string, id: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Schooljaar verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate(id);
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je dit schooljaar wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een schooljaar te verwijderen uit het systeem. Dit is een
            permanente actie en kan niet ongedaan worden gemaakt. Dit kan grote gevolgen hebben voor
            de reeds ingevulde verslagen. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId, id)} color="red">
              Schooljaar verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
