import { useQuery } from "@tanstack/react-query";
import { fetchReportSection } from "../../api/report-sections";

export default function useReportSectionData(
  reportId: string,
  sectionId: string,
  onSuccess?: any,
  onError?: any
) {
  return useQuery(
    ["report-sections", reportId, sectionId],
    () =>
      fetchReportSection({
        reportId,
        sectionId,
      }),
    {
      onSuccess,
      onError,
    }
  );
}
