import { Paper } from "@mantine/core";

type ReportSectionContainerProps = {
  children?: React.ReactNode;
};

export default function ReportSectionContainer(props: ReportSectionContainerProps) {
  const { children } = props;

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        background: "none",
      }}
    >
      {children ? children : null}
    </Paper>
  );
}
