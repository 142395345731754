import { Anchor } from "@mantine/core";
import { Link } from "react-router-dom";
import { Report } from "../../../types";
import StatusIcon from "../StatusIcon";

type ViewReportButtonProps = {
  report: Report;
  period: number;
  fullName?: string;
  year?: string;
};

export default function ViewReportButton(props: ViewReportButtonProps) {
  const { report, period, fullName, year } = props;

  if (
    (period === 1 && report.period_1_finished_at) ||
    (period === 2 && report.period_2_finished_at)
  ) {
    return (
      <Anchor
        title="Verslag weergeven"
        component={Link}
        to={`/reports/${report.id}/${period}?name=${fullName}&year=${year}`}
      >
        <StatusIcon />
      </Anchor>
    );
  }

  if (period === 1 && report.period_2_finished_at) {
    return null;
  }

  // if (period === 2 && !report.period_1_finished_at) {
  //   return null;
  // }

  return (
    <Anchor
      title="Verslag weergeven"
      component={Link}
      to={`/reports/${report.id}/${period}?name=${fullName}&year=${year}`}
    >
      <StatusIcon type="unsaved" />
    </Anchor>
  );
}
