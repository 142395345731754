import PageTitle from "../../components/PageTitle";
import ReportTable from "../../components/tables/ReportTable";

export default function Reports() {
  return (
    <>
      <PageTitle title="Mijn verslagen" />
      <ReportTable />
    </>
  );
}
