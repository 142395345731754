import { Title } from "@mantine/core";
import { Section } from "../../types";
import SectionRatingList from "./lists/SectionRatingList";
import ReportSectionContainer from "./ReportSectionContainer";

type ReportSectionProps = {
  reportId: string;
  section: Section;
  period: number;
  title?: string;
  disableComment?: boolean;
};

export default function ReportSection(props: ReportSectionProps) {
  const { reportId, section, period, title, disableComment } = props;

  return (
    <ReportSectionContainer>
      <Title mb={"md"} order={3}>
        {title ?? section?.name}
      </Title>

      <Title order={5} mb={"md"}>
        Beoordelingspunten
      </Title>

      <SectionRatingList
        reportId={reportId}
        section={section}
        ratings={section.ratings}
        period={period}
        disableComment={disableComment}
      />
    </ReportSectionContainer>
  );
}
