import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { saveAs } from "file-saver";
import { useState } from "react";
import { API_URL, FETCH_HEADERS } from "../../constants";
import { handleRequestErrors } from "../../tools";

export default function useExportReport() {
  const [isLoading, setIsLoading] = useState(false);

  type exportReportProps = {
    reportId: string;
    period: number | string;
    name: string;
    year: string;
  };

  const exportReport = async (props: exportReportProps) => {
    const { reportId, period, name, year } = props;

    showNotification({
      autoClose: false,
      disallowClose: true,
      loading: true,
      title: "Verslag exporteren",
      message: "Bezig met exporteren ...",
    });
    let fileName = `Verslag ${period} - ${year} - ${name}.pdf`;
    // let fileName = `Verslag_Periode_${period}_${reportId}.pdf`;

    await fetch(`${API_URL}/report/${reportId}/export/${period}`, {
      ...FETCH_HEADERS(),
    })
      .then((response) => handleRequestErrors(response))
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .then(() => {
        cleanNotifications();

        showNotification({
          title: "Verslag geëxporteerd",
          message: "Het verslag is geëxporteerd en de download is gestart.",
          autoClose: 10000,
          icon: <IconCheck />,
        });
      })
      .catch((e: any) => {
        cleanNotifications();

        showNotification({
          title: "Er ging iets mis",
          message:
            "Er is een fout opgetreden tijdens het exporteren van het verslag.",
          color: "red",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    exportReport,
    isLoading,
  };
}
