import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Section } from "../../../types";

export default function useMutateReportSectionData(
  mutationFn: (...args: any) => Promise<unknown>,
  onSuccess?: (values: Section) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reports"]);
      queryClient.invalidateQueries(["report-sections"]);
      queryClient.invalidateQueries(["sections"]);
      queryClient.invalidateQueries(["report-templates"]);

      onSuccess?.(data as any);
    },
    onError: (data) => {
      // Reload Report data (used for refetching the is_locked status and preventing double submits)
      queryClient.invalidateQueries(["reports"]);

      onError?.(data as any);
    },
  });
}
