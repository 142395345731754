import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableStudent, Student } from "../../../../types";
import { createStudent } from "../../../api/students";
import useMutateStudentData from "../../../hooks/student/useMutateStudentData";
import StudentForm from "./StudentForm";

type CreateStudentFormProps = {
  onClose: () => void;
};

function CreateStudentForm(props: CreateStudentFormProps) {
  const { onClose } = props;

  const onSuccess = (values: Student) => {
    showNotification({
      title: "Leerling aangemaakt",
      icon: <IconCheck />,
      message: `Leerling ${values.full_name} is aangemaakt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van de leerling. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateStudentData(createStudent, onSuccess, onError);

  const onSubmit = (values: PatchableStudent) => mutate(values);

  return (
    <>
      <StudentForm isLoading={isLoading} submitAction={(values) => onSubmit(values)} />
    </>
  );
}

export default CreateStudentForm;
