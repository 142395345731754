import { createStyles, Loader, Select } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Student } from "../../../types";
import { fetchStudents } from "../../api/students";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function StudentSelect() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState<Student[]>([]);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (query: string = "") => {
    setIsFetching(true);

    fetchStudents(0, 100, [], query)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const onChange = (value: string) => {
    let student = data.find((student) => student.id === value);
    let schoolClassId = student?.school_classes?.find(
      (schoolClass) => schoolClass.school_year?.active
    )?.id;

    if (schoolClassId) {
      showNotification({
        message: `Je bent genavigeerd naar de klas van ${student?.full_name}`,
      });

      navigate(`/school-classes/${schoolClassId}`);

      return;
    }

    showNotification({
      message: `De klas van ${student?.full_name} is niet gevonden`,
      color: "red",
    });
  };

  const onSearchChange = (value: string) => fetchData(value);

  if (isError) {
    return null;
  }

  return (
    <Select
      className={classes.search}
      placeholder="Zoek op leerling"
      searchable
      disabled={isError}
      value={null}
      onChange={onChange}
      onSearchChange={onSearchChange}
      rightSection={isFetching ? <Loader size={16} /> : <IconSearch size={16} />}
      data={data?.map((student: Student) => ({
        label: student.full_name,
        value: student.id,
      }))}
      styles={{
        rightSection: {
          pointerEvents: "none",
        },
      }}
    />
  );
}
