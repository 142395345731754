import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { PatchableSection, Section } from "../../../../types";
import { fetchSection, updateSection } from "../../../api/sections";
import useMutateSectionData from "../../../hooks/section/useMutateSectionData";
import SectionForm from "./SectionForm";

type EditSectionFormProps = {
  reportTemplateId: string;
  sectionId: string;
  onClose: () => void;
};

function EditSectionForm(props: EditSectionFormProps) {
  const { reportTemplateId, sectionId, onClose } = props;
  const [section, setSection] = useState<Section | undefined>();

  const onSuccess = (values: Section) => {
    showNotification({
      title: "Sectie bijgewerkt",
      icon: <IconCheck />,
      message: `De sectie ${values.name} is bijgewerkt`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de sectie. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateSectionData(updateSection, onSuccess, onError);

  const onSubmit = (values: PatchableSection) => {
    mutate({
      reportTemplateId,
      section: values,
    });
  };

  const onLoad = async () => {
    let data = await fetchSection({
      reportTemplateId,
      sectionId,
    });

    setSection(data);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      {!section ? (
        <Center>
          <Loader height={200} />
        </Center>
      ) : (
        <SectionForm
          isLoading={isLoading}
          section={section}
          submitAction={(values: PatchableSection) => onSubmit(values)}
        />
      )}
    </>
  );
}

export default EditSectionForm;
