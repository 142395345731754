import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { SchoolClass } from "../../../../types";
import { UserContext } from "../../../providers/user";

export default function useMutateTwoFactorAuth(
  mutationFn: (args?: any) => Promise<unknown>,
  onSuccess?: (values: SchoolClass) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();
  const { refetch } = useContext(UserContext);

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Refetch current user data
      queryClient.invalidateQueries(["users"]);
      refetch();

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
