import { Button, Group, PasswordInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import useLogin from "../../../hooks/auth/useLogin";
import { validateEmail, validateString } from "../../../validator";

export default function LoginForm() {
  const [emailFilled, setEmailFilled] = useState(false);
  const { loading, login } = useLogin();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => validateEmail(value),
      password: (value) => validateString(value, "Wachtwoord"),
    },
  });

  const toggleEmailFilled = () => setEmailFilled(!emailFilled);

  return (
    <form
      onSubmit={form.onSubmit((values) => login(values))}
      style={{ width: "100%" }}
    >
      {emailFilled ? (
        <>
          <PasswordInput
            data-autofocus
            required
            disabled={loading}
            label="Wachtwoord"
            placeholder="Wachtwoord"
            mb={"md"}
            {...form.getInputProps("password")}
          />

          <Group>
            <Button
              loading={loading}
              fullWidth
              type="submit"
              disabled={!form.isValid("password")}
            >
              Inloggen
            </Button>
            <Button fullWidth onClick={toggleEmailFilled}>
              Vorige
            </Button>
          </Group>
        </>
      ) : (
        <>
          <TextInput
            data-autofocus
            required
            disabled={loading}
            label="E-mailadres"
            placeholder="naam@organisatie.nl"
            type="email"
            mb={"md"}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();

                form.isValid("email") && toggleEmailFilled();
              }
            }}
            {...form.getInputProps("email")}
          />

          <Button
            fullWidth
            disabled={!form.isValid("email")}
            onClick={toggleEmailFilled}
          >
            Volgende
          </Button>
        </>
      )}
    </form>
  );
}
