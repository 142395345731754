import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { PatchableUser, User } from "../../../../types";
import { fetchUser, updateUser } from "../../../api/users";
import useMutateUserData from "../../../hooks/user/useMutateUserData";
import { UserContext } from "../../../providers/user";
import UserError from "../../UserError";
import UserForm from "./UserForm";

type EditUserFormProps = {
  userId: string;
  onClose?: () => void;
};

function EditUserForm(props: EditUserFormProps) {
  const { userId, onClose } = props;
  const { data: currentUser, refetch: refetchUser } = useContext(UserContext);
  const [user, setUser] = useState<User | undefined>();
  const [error, setError] = useState<string | null>(null);

  const onSuccess = (values: User) => {
    showNotification({
      title: "Gebruiker bijgewerkt",
      icon: <IconCheck />,
      message: `De gebruiker ${values.name} is bijgewerkt.`,
    });

    currentUser?.id === values.id && refetchUser();

    onClose?.();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de gebruiker. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateUserData(updateUser, onSuccess, onError);

  const onSubmit = (values: PatchableUser) => {
    mutate(values);
  };

  const onLoad = async () => {
    try {
      setError(null);
      let data = await fetchUser(userId);
      setUser(data);
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  if (error) {
    return <UserError error={error} />;
  }

  if (!user) {
    return (
      <Center>
        <Loader height={200} />
      </Center>
    );
  }

  return <UserForm isLoading={isLoading} user={user} submitAction={(values) => onSubmit(values)} />;
}

export default EditUserForm;
