import { API_URL, FETCH_HEADERS } from "../constants";
import { handleRequestErrors } from "../tools";

export const fetchTwoFactorAuthSecret = async () => {
  return await fetch(`${API_URL}/auth/2fa`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export const setupTwoFactorAuth = async (data: {
  two_factor_secret: string;
  otp: string;
}) => {
  return await fetch(`${API_URL}/auth/2fa/setup`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export const disableTwoFactorAuth = async () => {
  return await fetch(`${API_URL}/auth/2fa`, {
    ...FETCH_HEADERS(),
    method: "DELETE",
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export const disableForeignTwoFactorAuth = async (userId: string) => {
  return await fetch(`${API_URL}/auth/2fa/${userId}`, {
    ...FETCH_HEADERS(),
    method: "DELETE",
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};
