import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { Report } from "../../../types";
import CreateReportForm from "../../components/forms/report/CreateReportForm";

type openModalProps = {
  studentId: string;
  period: string | number;
};

export default function useCreateReport(
  navigateAway: boolean = false,
  name: string,
  year: string
) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = (props: openModalProps) => {
    const { studentId, period } = props;

    const modalId = modals.openModal({
      size: "xl",
      title: "Verslag aanmaken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <CreateReportForm
            studentId={studentId}
            onClose={(values: Report) => closeModal(modalId, values.id, period)}
          />
        </>
      ),
    });
  };

  const closeModal = (
    id: string,
    reportId: string,
    period: string | number
  ) => {
    modals.closeModal(id);
    navigateAway &&
      navigate(`/reports/${reportId}/${period}?name=${name}&year=${year}`);
  };

  return {
    openModal,
  };
}
