import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportTemplate } from "../../../types";

export default function useMutateReportTemplateData(
  mutationFn: (args: any) => Promise<unknown>,
  onSuccess?: (values: ReportTemplate) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload ReportTemplate data
      queryClient.invalidateQueries(["report-templates"]);

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
