import { Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableReportTemplate, ReportTemplate } from "../../../../types";
import { validateString } from "../../../validator";

type ReportTemplateFormProps = {
  reportTemplate?: ReportTemplate;
  submitText?: string;
  submitAction: (values: PatchableReportTemplate) => void;
  isLoading?: boolean;
};

function ReportTemplateForm(props: ReportTemplateFormProps) {
  const { reportTemplate, submitText, submitAction, isLoading = false } = props;

  const form = useForm<PatchableReportTemplate>({
    initialValues: {
      id: reportTemplate?.id ?? "",
      name: reportTemplate?.name ?? "",
    },

    validate: {
      name: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput data-autofocus mb={"md"} required label="Naam" {...form.getInputProps("name")} />

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default ReportTemplateForm;
