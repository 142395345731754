import { Anchor, Text } from "@mantine/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SchoolClass, Student } from "../../../types";
import { fetchStudents } from "../../api/students";
import useDeleteStudent from "../../hooks/student/useDeleteStudent";
import useEditStudent from "../../hooks/student/useEditStudent";
import { UserContext } from "../../providers/user";
import { readableDateTime } from "../../tools";
import CreateReportButton from "../buttons/CreateReportButton";
import ViewReportButton from "../buttons/ViewReportButton";
import TableCellText from "../TableCellText";
import TableHeaderText from "../TableHeaderText";
import FancyTable, { FancyTableProps } from "./FancyTable";

type StudentTableProps = Partial<FancyTableProps>;

export default function StudentTable(props: StudentTableProps) {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteStudent();
  const { openModal: openEditModal } = useEditStudent();

  const columns = React.useMemo(
    () => [
      {
        id: "student-table",
        columns: [
          {
            id: "first_name",
            Header: <TableHeaderText text="Voornaam" />,
            accessor: (d: Student) => (
              <TableCellText
                text={d.first_name}
                subtext={`Laatste bewerking door ${
                  d.updated_by?.name
                } - ${readableDateTime(d.updated_at)}`}
              />
            ),
          },
          {
            id: "last_name",
            Header: <TableHeaderText text="Achternaam" />,
            accessor: (d: Student) => <TableCellText text={d.full_last_name} />,
          },
          {
            id: "active_school_class.name",
            Header: <TableHeaderText text="Klas" />,
            accessor: (d: Student) => {
              return d.school_classes
                ?.filter((sc: SchoolClass) => sc.school_year?.active)
                ?.map((sc: SchoolClass) =>
                  isAuthorized(["school_class_show", "school_class_update"]) ? (
                    <Anchor component={Link} to={`/school-classes/${sc.id}`}>
                      <TableCellText text={sc.name} />
                    </Anchor>
                  ) : (
                    <TableCellText text={sc.name} />
                  )
                );
            },
          },
          {
            id: "active_school_year.name",
            Header: <TableHeaderText text="Schooljaar" />,
            accessor: (d: Student) => {
              return d.school_classes
                ?.filter((sc: SchoolClass) => sc.school_year?.active)
                ?.map((sc: SchoolClass) => (
                  <Text c="dimmed">{sc.school_year?.name}</Text>
                ));
            },
          },
          {
            id: "active_report.period_1_finished_at",
            Header: <TableHeaderText text="Verslag periode 1" />,
            accessor: (d: Student) => {
              const fullName = d.full_name;
              const year =
                d.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return d.active_report ? (
                <ViewReportButton
                  report={d.active_report}
                  period={1}
                  fullName={fullName}
                  year={year}
                />
              ) : (
                <CreateReportButton
                  studentId={d.id}
                  period={1}
                  fullName={fullName}
                  year={year}
                />
              );
            },
          },
          {
            id: "active_report.period_2_finished_at",
            Header: <TableHeaderText text="Verslag periode 2" />,
            accessor: (d: Student) => {
              const fullName = d.full_name;
              const year =
                d.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return d.active_report ? (
                <ViewReportButton
                  report={d.active_report}
                  period={2}
                  fullName={fullName}
                  year={year}
                />
              ) : (
                <CreateReportButton
                  studentId={d.id}
                  period={2}
                  fullName={fullName}
                  year={year}
                />
              );
              // return (
              //   d.active_report && (
              //     <ViewReportButton report={d.active_report} period={2} />
              //   )
              // );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <FancyTable
      {...props}
      queryKey={"students"}
      queryFn={fetchStudents}
      columns={columns}
      onDelete={isAuthorized("student_destroy") ? openDeleteModal : undefined}
      onEdit={isAuthorized("student_update") ? openEditModal : undefined}
    />
  );
}
