import { API_URL, FETCH_HEADERS } from "../constants";
import { blobToBase64, getAuthToken, handleRequestErrors } from "../tools";

type UpdateAvatarProps = {
    file: any;
}

export const fetchAvatar = async () => {
    return await fetch(`${API_URL}/auth/avatar`, {
        ...FETCH_HEADERS(),
    })
        .then((data) => handleRequestErrors(data))
        .then((response) => response.blob())
        .then((blob) => blobToBase64(blob))
        .catch(() => {
            return null;
        });
};

export const updateAvatar = async (props: UpdateAvatarProps) => {
    const { file } = props;

    const body = new FormData()
    body.append('avatar', file, file.name);

    return await fetch(`${API_URL}/auth/avatar`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
        },
        body: body
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteAvatar = async () => {
    return await fetch(`${API_URL}/auth/avatar`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}