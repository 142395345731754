import { Anchor, Avatar, Group, GroupProps, Stack, Text } from "@mantine/core";
import { useContext } from "react";
import { Link } from "react-router-dom";
import useAvatarData from "../hooks/avatar/useAvatarData";
import { UserContext } from "../providers/user";

export function UserButton(props: Partial<GroupProps>) {
  const { data: user } = useContext(UserContext);
  const { data: avatar } = useAvatarData();

  return (
    <Group spacing={42} {...props}>
      <Avatar size={60} radius="xl" src={avatar as string} />

      <Stack spacing={0}>
        <Text size="sm" weight={"bolder"}>
          Hallo {user?.name}!
        </Text>
        <Anchor component={Link} to={"/profile"} size={"xs"}>
          Mijn profiel
        </Anchor>
      </Stack>
    </Group>
  );
}
