import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { duplicateReportTemplate } from "../../api/report-templates";
import useMutateReportTemplateData from "./useMutateReportTemplateData";

export default function useDuplicateReportTemplate() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Verslag template gedupliceerd",
      icon: <IconCheck />,
      message: `Het verslag template is gedupliceerd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het dupliceren van het verslag template. ${error}`,
      color: "red",
      autoClose: 10000,
    });
  };

  const { mutate } = useMutateReportTemplateData(duplicateReportTemplate, onSuccess, onError);

  const onSubmit = async (modalId: string, id: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Verslag template dupliceren",
      message: "Bezig met dupliceren ...",
    });

    await mutate(id);
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je dit verslag template wilt dupliceren?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een verslag template te dupliceren. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId, id)}>Dupliceren</Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
