import { PatchableRating, Rating } from "../../../../types";
import { showNotification } from "@mantine/notifications";
import useMutateRatingData from "../../../hooks/rating/useMutateRatingData";
import { createRating } from "../../../api/ratings";
import RatingForm from "./RatingForm";
import { IconCheck } from "@tabler/icons-react";

type CreateRatingFormProps = {
  reportTemplateId: string;
  sectionId: string;
  onClose: () => void;
};

function CreateRatingForm(props: CreateRatingFormProps) {
  const { reportTemplateId, sectionId, onClose } = props;

  const onSuccess = (values: Rating) => {
    showNotification({
      title: "Beoordelingspunt aangemaakt",
      icon: <IconCheck />,
      message: `Het beoordelingspunt ${values.name} is aangemaakt`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van het beoordelingspunt. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateRatingData(createRating, onSuccess, onError);

  const onSubmit = (values: PatchableRating) => {
    mutate({
      reportTemplateId,
      sectionId,
      rating: values,
    });
  };

  return (
    <>
      <RatingForm
        isLoading={isLoading}
        submitAction={(values: PatchableRating) => onSubmit(values)}
      />
    </>
  );
}

export default CreateRatingForm;
