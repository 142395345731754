import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import EditSectionForm from "../../components/forms/section/EditSectionForm";

export default function useEditSection(
  reportTemplateId: string = "",
  navigateAway: boolean = false
) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = (props: { reportTemplateId: string; sectionId: string }) => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Sectie bewerken",
      closeOnClickOutside: false,
      centered: true,
      children: <EditSectionForm {...props} onClose={() => closeModal(modalId)} />,
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/report-templates/${reportTemplateId}`);
  };

  return {
    openModal,
  };
}
