import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { deleteUser } from "../../api/users";
import useMutateUserData from "./useMutateUserData";

export default function useDeleteUser() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Gebruiker verwijderd",
      icon: <IconCheck />,
      message: `De gebruiker is verwijderd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het verwijderen van de gebruiker. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateUserData(deleteUser, onSuccess, onError);

  const onSubmit = async (modalId: string, id: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Gebruiker verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate(id);
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je deze gebruiker wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een gebruiker te verwijderen uit het systeem. Dit is een
            permanente actie en kan niet ongedaan worden gemaakt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId, id)} color="red">
              Gebruiker verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
