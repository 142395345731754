import { Anchor, Center, createStyles, Group, Title } from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import VerifyEmailForm from "../../components/forms/auth/VerifyEmailForm";
import useEmailVerify from "../../hooks/auth/useEmailVerify";

const useStyles = createStyles((theme) => ({
  formContainer: {
    width: "100%",

    [theme.fn.largerThan("sm")]: {
      width: 500,
    },
  },
}));

function VerifyEmail() {
  const { classes } = useStyles();
  const { loading, setFormData, onSubmit } = useEmailVerify();
  const navigate = useNavigate();

  return (
    <Center mb={"md"} sx={{ height: "100%", flexDirection: "column", gap: 16 }}>
      <Title>Account instellen</Title>

      <Group className={classes.formContainer}>
        <VerifyEmailForm
          loading={loading}
          submitText={"Wachtwoord instellen"}
          onSubmit={onSubmit}
          setValues={setFormData}
        />
      </Group>

      <Anchor onClick={() => navigate("/login")}>
        <Group spacing={5}>
          <IconArrowNarrowLeft />
          Terug naar inloggen
        </Group>
      </Anchor>
    </Center>
  );
}

export default VerifyEmail;
