import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Role } from "../../../../types";
import { fetchRole, updateRole } from "../../../api/roles";
import useMutateRoleData from "../../../hooks/role/useMutateRoleData";
import RoleForm from "./RoleForm";

type EditRoleFormProps = {
  onClose: () => void;
  roleId: string;
};

export default function EditRoleForm(props: EditRoleFormProps) {
  const { onClose, roleId } = props;
  const [role, setRole] = useState();

  const onSuccess = (value: Role) => {
    showNotification({
      title: "Rol bijgewerkt",
      icon: <IconCheck />,
      message: `De rol ${value.name} is bijgewerkt!`,
    });

    onClose();
  };

  const { mutate, isLoading } = useMutateRoleData(updateRole, onSuccess);

  const onSubmit = async (value: Role) => {
    mutate(value);
  };

  const onLoad = async () => {
    const response = await fetchRole(roleId);

    response && setRole(response);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return !role ? (
    <Center>
      <Loader height={200} />
    </Center>
  ) : (
    <RoleForm onSubmit={onSubmit} role={role} loading={isLoading} />
  );
}
