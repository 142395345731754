import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { unblockReport } from "../../api/reports"; // CHANGE to unfinish
import useMutateReportData from "./useMutateReportData";

export default function useUnblockReport() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Verslag ontgrendeld",
      icon: <IconCheck />,
      message: `Het verslag is ontgrendeld.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het ontgrendeld van het verslag. ${error}`,
      color: "red",
      autoClose: 20000,
    });
  };

  const { mutate } = useMutateReportData(unblockReport, onSuccess, onError);

  const onSubmit = async (
    modalId: string,
    reportId: string,
    period: string | number
  ) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Verslag ontgrendeld",
      message: "Bezig met ontgrendeld ...",
    });

    await mutate({ reportId, period });
  };

  type openModalProps = {
    reportId: string;
    period: string | number;
  };

  const openModal = (props: openModalProps) => {
    const { reportId, period } = props;

    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je dit verslag wilt ontgrendelen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een verslag ontgrendelen in het systeem.
            Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>
              Annuleren
            </Button>
            <Button
              onClick={() => onSubmit(modalId, reportId, period)}
              color="red"
            >
              Ontgrendel
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
