import { Stack } from "@mantine/core";
import { useContext } from "react";
import { UserContext } from "../../../providers/user";
import TwoFactorAuthDisabled from "./_disabled";
import TwoFactorAuthEnabled from "./_enabled";

export default function TwoFactorAuthTab() {
  const { data: user } = useContext(UserContext);

  return (
    <Stack>{user?.["2fa_enabled"] ? <TwoFactorAuthEnabled /> : <TwoFactorAuthDisabled />}</Stack>
  );
}
