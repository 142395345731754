import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { disableForeignTwoFactorAuth } from "../../../api/two-factor-auth";
import useMutateTwoFactorAuth from "./useMutateTwoFactorAuth";
import DelayedButton from "../../../components/buttons/DelayedButton";

export default function useDisableForeignTwoFactorAuth(userId: string) {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "2FA uitgeschakeld",
      icon: <IconCheck />,
      message: `2FA is uitgeschakeld.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het uitgeschakelen van 2FA. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateTwoFactorAuth(
    disableForeignTwoFactorAuth,
    onSuccess,
    onError
  );

  const onSubmit = async (modalId: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "2FA uitgeschakelen",
      message: "Bezig met uitschakelen ...",
    });

    await mutate(userId);
  };

  const openModal = () => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je 2FA wilt uitschakelen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            U staat op het punt 2FA uit te schakelen. Dit wordt niet aanbevolen
            en het uitvoeren van deze actie heeft tot gevolg dat de gebruiker
            geen toegang meer heeft tot de gegevens in het systeem totdat hij
            2FA weer inschakelt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>
              Annuleren
            </Button>
            <DelayedButton
              title={"Uitschakelen"}
              timeout={10}
              onClick={() => onSubmit(modalId)}
              color="red"
            />
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
