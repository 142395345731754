import { useQuery } from "@tanstack/react-query";
import { fetchReportRating } from "../../api/report-ratings";

export default function useReportRatingData(
  reportId: string = "",
  sectionId: string = "",
  period: string = "",
  onSuccess?: any,
  onError?: any
) {
  return useQuery(
    ["report-ratings", reportId, sectionId, period],
    () => fetchReportRating(reportId, sectionId, period),
    {
      onSuccess,
      onError,
    }
  );
}
