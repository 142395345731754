import { createContext, ReactNode } from "react";
import useUser from "../hooks/auth/useUser";
import { FullPageSpinner } from "../components/FullPageSpinner";
import { Maybe, User } from "../../types";

type Props = {
  children?: ReactNode;
};

type UserProps = {
  data: Maybe<User>;
  isAuthed: boolean;
  isTwoFactorAuthed: boolean;
  refetch: () => void;
  isAuthorized: (permissions: string | string[]) => boolean;
};

export const UserContext = createContext<UserProps>({
  data: undefined,
  isAuthed: false,
  isTwoFactorAuthed: false,
  refetch: () => {},
  isAuthorized: () => false,
});

export default function UserProvider(props: Props) {
  const {
    data,
    isAuthed,
    isTwoFactorAuthed,
    refetch,
    isAuthorized,
    loading,
    initialFetch,
  } = useUser();

  return loading && initialFetch ? (
    <FullPageSpinner />
  ) : (
    <UserContext.Provider
      value={{ data, isAuthed, isTwoFactorAuthed, refetch, isAuthorized }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
