import { SortingRule } from "react-table";
import { Student, TableDataFilter } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { generateFilterString, generateSortString, handleRequestErrors } from "../tools";

export const fetchStudents = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "", filters: TableDataFilter[] = [] ) => {
    return await fetch(`${API_URL}/student?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}${generateFilterString(filters)}&q=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchStudent = async (id: string) => {
    return await fetch(`${API_URL}/student/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export const createStudent = async (student: Student) => {
    return await fetch(`${API_URL}/student`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(student)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const updateStudent = async (student: Student) => {
    return await fetch(`${API_URL}/student/${student.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(student)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteStudent = async (id: string) => {
    return await fetch(`${API_URL}/student/${id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}