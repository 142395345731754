import { Group, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import React, { useContext } from "react";
import { Report, SchoolClass } from "../../../types";
import { fetchReports } from "../../api/reports";
import useDeleteReport from "../../hooks/report/useDeleteReport";
import { UserContext } from "../../providers/user";
import ViewReportButton from "../buttons/ViewReportButton";
import TableHeaderText from "../TableHeaderText";
import FancyTable, { FancyTableProps } from "./FancyTable";

type ReportTableProps = Partial<FancyTableProps>;

export default function ReportTable(props: ReportTableProps) {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteReport();

  const columns = React.useMemo(
    () => [
      {
        id: "report-table",
        columns: [
          {
            id: "student.last_name",
            Header: <TableHeaderText text="Leerling" />,
            accessor: (d: Report) =>
              d.student ? (
                <Text>
                  {d.student?.last_name}, {d.student?.first_name}{" "}
                  {d.student?.last_name_affix}
                </Text>
              ) : (
                <Group spacing={5} noWrap>
                  <IconTrash color={"red"} size={18} />
                  <Text color={"red"}>Niet beschikbaar</Text>
                </Group>
              ),
          },
          {
            id: "school_class.name",
            Header: <TableHeaderText text="Klas" />,
            accessor: (d: Report) => <Text>{d.school_class?.name}</Text>,
          },
          {
            id: "school_year.name",
            Header: <TableHeaderText text="Schooljaar" />,
            accessor: (d: Report) => (
              <Text>{d.school_class?.school_year?.name}</Text>
            ),
          },
          {
            id: "period_1_finished_at",
            Header: <TableHeaderText text="Verslag periode 1" />,
            accessor: (d: Report) => {
              const fullName = d.student?.full_name;
              const year =
                d.student?.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return (
                <ViewReportButton
                  report={d}
                  period={1}
                  fullName={fullName}
                  year={year}
                />
              );
            },
          },
          {
            id: "period_2_finished_at",
            Header: <TableHeaderText text="Verslag periode 2" />,
            accessor: (d: Report) => {
              const fullName = d.student?.full_name;
              const year =
                d.student?.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return (
                <ViewReportButton
                  report={d}
                  period={2}
                  fullName={fullName}
                  year={year}
                />
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <FancyTable
        {...props}
        queryKey={"reports"}
        queryFn={fetchReports}
        columns={columns}
        onDelete={isAuthorized("report_destroy") ? openDeleteModal : undefined}
      />
    </>
  );
}
