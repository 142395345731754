import { useEffect, useState } from "react";
import { API_URL, FETCH_HEADERS } from "../../constants";
import { Maybe, Permission, User } from "../../../types";
import { hasAuthToken as hasAuthTokenFn } from "../../tools";
import { useNavigate } from "react-router-dom";

export default function useUser() {
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [isTwoFactorAuthed, setIsTwoFactorAuthed] = useState<boolean>(false);
  const [data, setData] = useState<Maybe<User>>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialFetch, setInitialFetch] = useState(true);

  const hasAuthToken = hasAuthTokenFn();
  const navigate = useNavigate();

  /**
   * Execute fetch on mount.
   */
  useEffect(() => {
    hasAuthToken ? fetchData() : setLoading(false);
  }, [hasAuthToken]);

  /**
   * Fetch the user's profile.
   */
  const fetchData = async () => {
    setLoading(true);

    const response = await fetch(`${API_URL}/auth/profile`, {
      method: "POST",
      ...FETCH_HEADERS(),
    }).then((res) => res.json());

    if (response.data) {
      setIsAuthed(true);
    }

    setData(response.data?.user);
    setIsTwoFactorAuthed(response.data?.["2fa_authenticated"]);

    if (response.data?.user["2fa_enabled"] && !response.data?.["2fa_authenticated"]) {
      // Redirect to 2FA page
      navigate("/2fa");
    }

    setLoading(false);
    setInitialFetch(false);
  };

  /**
   * Refetch the profile of the user.
   */
  const refetch = () => fetchData();

  /**
   * Check if the user is authorized for an action according to the permission(s) of the action.
   * The user is authorized if one of the permissions in the given array matches a permission of the user.
   * @param permissions
   */
  const isAuthorized = (permissions: string | string[]) => {
    if (typeof permissions === "string") permissions = [permissions];

    if (data?.is_admin) return true;

    if (!data || !data?.permissions) {
      return false;
    }

    return (
      data.permissions.filter((item: Permission) => permissions.includes(item?.name ?? "")).length >
      0
    );
  };

  return {
    isAuthed,
    isTwoFactorAuthed,
    data,
    loading,
    refetch,
    isAuthorized,
    initialFetch,
  };
}
