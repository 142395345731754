import { useModals } from "@mantine/modals";
import ImportStudentForm from "../../components/forms/import-student/ImportStudentForm";

export default function useImportSchoolClassStudents() {
  const modals = useModals();

  const openModal = (classId?: string) => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Leerlingen importeren",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <ImportStudentForm onClose={() => closeModal(modalId)} classId={classId} />
        </>
      ),
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
  };

  return {
    openModal,
  };
}
