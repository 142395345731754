import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { formatErrors, isEmptyArray } from "../../tools";
import useLazyFetch from "../useLazyFetch";

/**
 * Hook for handling email verification
 */
export default function useResetPassword() {
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });

  const { token, email, expires, signature } = useParams();
  const navigate = useNavigate();

  const { doFetch, loading } = useLazyFetch({
    url: `${API_URL}/auth/password-reset`,
    options: {
      method: "POST",
      body: JSON.stringify({
        token: token,
        email: email,
        expires: expires,
        signature: signature,
        ...formData,
      }),
    },
  });

  const onSubmit = async () => {
    const res = await doFetch();
    const errors = formatErrors(res);

    if (!isEmptyArray(errors)) {
      showNotification({
        title: `Er ging iets mis`,
        message: `We konden het door jou gekozen wachtwoord niet instellen. ${errors[0]}`,
        color: "red",
        autoClose: 10000,
      });

      return;
    }

    showNotification({
      title: "Wachtwoord ingesteld",
      icon: <IconCheck />,
      message: `Je wachtwoord is ingesteld. Je kunt nu inloggen.`,
    });

    navigate("/login");
  };

  return {
    onSubmit,
    setFormData,
    loading,
  };
}
