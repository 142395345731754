import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import CreateReportTemplateForm from "../../components/forms/report-template/CreateReportTemplateForm";

export default function useCreateReportTemplate(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = () => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Verslag template aanmaken",
      closeOnClickOutside: false,
      centered: true,
      children: <CreateReportTemplateForm onClose={() => closeModal(modalId)} />,
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/report-templates`);
  };

  return {
    openModal,
  };
}
