import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { disableTwoFactorAuth } from "../../../api/two-factor-auth";
import useMutateTwoFactorAuth from "./useMutateTwoFactorAuth";
import DelayedButton from "../../../components/buttons/DelayedButton";

export default function useDisableTwoFactorAuth() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "2FA uitgeschakeld",
      icon: <IconCheck />,
      message: `2FA is uitgeschakeld.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het uitgeschakelen van 2FA. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateTwoFactorAuth(disableTwoFactorAuth, onSuccess, onError);

  const onSubmit = async (modalId: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "2FA uitgeschakelen",
      message: "Bezig met uitschakelen ...",
    });

    await mutate(null);
  };

  const openModal = () => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je 2FA wilt uitschakelen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om 2FA uit te schakelen. Dit is niet aanbevolen en door het
            uitvoeren van deze actie heb je geen toegang meer tot data in het systeem, totdat je 2FA
            weer hebt ingeschakeld. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <DelayedButton
              title={"Uitschakelen"}
              timeout={10}
              onClick={() => onSubmit(modalId)}
              color="red"
            />
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
