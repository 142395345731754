import { Anchor, Group, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

type PreviousPageAnchorProps = {
  text?: string;
};

export default function PreviousPageAnchor(props: PreviousPageAnchorProps) {
  const { text } = props;
  const navigate = useNavigate();

  return (
    <Anchor onClick={() => navigate(-1)}>
      <Group>
        <IconArrowLeft />
        <Text>{text || "Terug naar de vorige pagina"}</Text>
      </Group>
    </Anchor>
  );
}
