import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import CreateSchoolClassForm from "../../components/forms/school-class/CreateSchoolClassForm";

export default function useCreateSchoolClass(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = () => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Klas aanmaken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <CreateSchoolClassForm onClose={() => closeModal(modalId)} />
        </>
      ),
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/admin/school-classes`);
  };

  return {
    openModal,
  };
}
