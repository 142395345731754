import { Maybe } from "../types";

export function validateString(
  string: Maybe<string>,
  name: string = "Dit veld",
  options: { numbers?: boolean; length?: number } = {}
) {
  if (!(string && string.trim())) {
    return `${name} is verplicht.`;
  }

  if (options.numbers) {
    if (string && string.match(/\D/)) {
      return `${name} mag alleen cijfers bevatten.`;
    }
  }

  if (options.length) {
    if (string && string.length !== options.length) {
      return `${name} moet ${options.length} tekens lang zijn.`;
    }
  }

  return null;
}

export function validateSame(string: Maybe<string>, string2: Maybe<string>, field: string) {
  return string === string2 ? null : `Dit veld moet gelijk zijn aan ${field}.`;
}

export function validateEmail(string: Maybe<string>, name: string = "Dit e-mailadres") {
  let valid = String(string)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (!valid) {
    return `${name} is niet geldig.`;
  }

  return null;
}
