import { Button, Container, createStyles, Group, Text, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

type ErrorViewProps = {
  error?: string;
};

export function ErrorView(props: ErrorViewProps) {
  const { error } = props;
  const { classes } = useStyles();

  const forceReload = () => {
    window.location.reload();
  };

  return (
    <Container className={classes.root}>
      <Title className={classes.title}>Dat ging niet helemaal goed.</Title>
      <Title mb={"md"}></Title>

      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        {error ||
          "Maak je geen zorgen, onze ontwikkelaars zijn zojuist op de hoogte gesteld. Er wordt aan een oplossing gewerkt."}
      </Text>
      <Group position="center">
        <Button variant="subtle" size="md" onClick={forceReload}>
          Herlaad de pagina
        </Button>
      </Group>
    </Container>
  );
}

export default ErrorView;
