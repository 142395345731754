import {
  Anchor,
  Burger,
  createStyles,
  Group,
  Header as MantineHeader,
  Menu,
  Paper,
  Transition,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLogout from "../../hooks/auth/useLogout";
import useCreateReportTemplate from "../../hooks/report-template/useCreateReportTemplate";
import useCreateRole from "../../hooks/role/useCreateRole";
import useCreateSchoolClass from "../../hooks/school-class/useCreateSchoolClass";
import useImportSchoolClassStudents from "../../hooks/school-class/useImportSchoolClassStudents";
import useCreateSchoolYear from "../../hooks/school-year/useCreateSchoolYear";
import useCreateStudent from "../../hooks/student/useCreateStudent";
import useCreateUser from "../../hooks/user/useCreateUser";
import { UserContext } from "../../providers/user";
import StudentSelect from "../selects/StudentSelect";
import UserSchoolClassSelect from "../selects/UserSchoolClassSelect";
import { UserButton } from "../UserButton";
import { AddButton } from "./_addButton";

const HEADER_HEIGHT = 90;
const HEADER_BREAKPOINT = "xl";

const useStyles = createStyles((theme) => ({
  root: {
    background: "unset",
    minHeight: HEADER_HEIGHT,
    zIndex: 200,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan(HEADER_BREAKPOINT)]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan(HEADER_BREAKPOINT)]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan(HEADER_BREAKPOINT)]: {
      display: "none",
    },
  },

  link: {
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    padding: "8px 12px",
    textDecoration: "none",
    color: "#0A1E27",
    fontSize: theme.fontSizes.xs,
    fontWeight: "bold",
    textTransform: "uppercase",
    height: "100%",
    fontFamily: "Poppins, sans-serif",

    "&:hover": {
      color: "#278ACA",
    },

    [theme.fn.smallerThan(HEADER_BREAKPOINT)]: {
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    position: "relative",

    [theme.fn.smallerThan(HEADER_BREAKPOINT)]: {
      textDecoration: "underline",
      textDecorationColor: "#278ACA",
      textUnderlineOffset: 2,
    },

    "&::after": {
      position: "absolute",
      content: '""',
      backgroundColor: "#278ACA",
      width: "50%",
      height: 5,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",

      [theme.fn.smallerThan(HEADER_BREAKPOINT)]: {
        content: "unset",
      },
    },
  },

  search: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

type HeaderProps = {
  links: HeaderLinkProps[];
};

type HeaderLinkProps = {
  link: string;
  label: string;
  permission?: string | string[];
  links?: HeaderLinkProps[];
};

export function Header({ links }: HeaderProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const { isAuthorized } = useContext(UserContext);
  const { classes, cx } = useStyles();
  const { logout } = useLogout();

  const { openModal: openCreateUserModal } = useCreateUser(true);
  const { openModal: openCreateStudentModal } = useCreateStudent(true);
  const { openModal: openCreateSchoolClassModal } = useCreateSchoolClass(true);
  const { openModal: openCreateSchoolYearModal } = useCreateSchoolYear(true);
  const { openModal: openCreateReportTemplateModal } = useCreateReportTemplate(true);
  const { openModal: openCreateRole } = useCreateRole(true);
  const { openModal: openImportStudentsModal } = useImportSchoolClassStudents();

  const addActions = [
    {
      label: "Leerling",
      action: openCreateStudentModal,
      permission: "student_store",
    },
    {
      label: "Verslag template",
      action: openCreateReportTemplateModal,
      permission: "report_template_store",
    },
    { label: "Klas", action: openCreateSchoolClassModal, permission: "school_class_store" },
    {
      label: "Leerlingen importeren",
      action: openImportStudentsModal,
      permission: "school_class_import",
    },
    { label: "Schooljaar", action: openCreateSchoolYearModal, permission: "school_year_store" },
    { label: "Gebruiker", action: openCreateUserModal, permission: "user_store" },
    { label: "Rol", action: openCreateRole, permission: "role_store" },
  ];

  const items = links.map((link) => {
    let renderLink =
      (link.links?.length ?? 0) > 0 ? false : !(link.permission && !isAuthorized(link.permission));
    const navigate = useNavigate();
    const location = useLocation();

    const isActive =
      link.link === "/" ? location.pathname === "/" : location.pathname.startsWith(link.link);

    const onClose = (link?: string) => {
      close();
      link && navigate(link);
    };

    const menuItems = link.links
      ?.filter((item) => !(item.permission && !isAuthorized(item.permission)))
      .map((item) => {
        return (
          <Menu.Item key={item.link} onClick={() => onClose(item.link)}>
            <Anchor sx={{ fontFamily: "Poppins, sans-serif" }}>{item.label}</Anchor>
          </Menu.Item>
        );
      });

    if (menuItems) {
      return menuItems.length > 0 ? (
        <Menu position="top-start" key={link.label} trigger="hover" exitTransitionDuration={0}>
          <Menu.Target>
            <Anchor
              key={link.label}
              className={cx(classes.link, { [classes.linkActive]: isActive })}
              sx={{ padding: "0 !important" }}
            >
              <Group spacing={0}>
                <span className={classes.link}>{link.label}</span>
                <IconChevronDown size={12} stroke={1.5} />
              </Group>
            </Anchor>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      ) : null;
    }

    if (!renderLink) return null;

    return (
      <Anchor
        component={Link}
        key={link.label}
        to={link.link}
        className={cx(classes.link, { [classes.linkActive]: isActive })}
        onClick={() => onClose()}
      >
        {link.label}
      </Anchor>
    );
  });

  return (
    <MantineHeader height={HEADER_HEIGHT} className={classes.root} withBorder={false}>
      <Group position="apart" className={classes.header}>
        <Group sx={{ height: "100%" }}>
          <UserButton px={24} />

          <Group spacing={0} className={classes.links} sx={{ height: "100%" }}>
            {items}

            <Anchor className={classes.link} onClick={() => logout()}>
              Uitloggen
            </Anchor>
          </Group>

          <Transition transition="pop-top-right" duration={200} mounted={opened}>
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {items}
                <Anchor className={classes.link} onClick={() => logout()}>
                  Uitloggen
                </Anchor>
              </Paper>
            )}
          </Transition>
        </Group>

        <Group position={"apart"} spacing={10}>
          <AddButton actions={addActions} />

          <UserSchoolClassSelect />

          <StudentSelect />

          <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
        </Group>
      </Group>
    </MantineHeader>
  );
}
