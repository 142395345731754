import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import usePasswordForgotten from "../../../hooks/auth/usePasswordForgotten";
import { validateSame, validateString } from "../../../validator";

function PasswordForgottenForm() {
  const { loading, setFormData, onSubmit } = usePasswordForgotten();

  const form = useForm({
    initialValues: {
      email: "",
      email_confirmation: "",
    },

    validate: (values) => ({
      email: validateString(values.email),
      email_confirmation: validateSame(
        values.email_confirmation,
        values.email,
        "het e-mailadres veld"
      ),
    }),
  });

  useEffect(() => {
    setFormData(form.values);
  }, [form.values]);

  return (
    <form onSubmit={form.onSubmit(() => onSubmit())} style={{ width: "100%" }}>
      <TextInput
        data-autofocus
        disabled={loading}
        mb={"md"}
        label="E-mailadres"
        placeholder="naam@organisatie.nl"
        required
        {...form.getInputProps("email")}
      />

      <TextInput
        disabled={loading}
        mb={"md"}
        label="E-mailadres bevestigen"
        placeholder="naam@organisatie.nl"
        required
        {...form.getInputProps("email_confirmation")}
      />

      <Button loading={loading} fullWidth type={"submit"}>
        Wachtwoord herstel aanvragen
      </Button>
    </form>
  );
}

export default PasswordForgottenForm;
