export function AppBackground() {
  return (
    <div
      style={{
        position: "absolute",
        overflow: "hidden",
        zIndex: -1,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "40%",
          width: "100%",
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 1920 1540">
          <path
            id="Icon_awesome-folder"
            data-name="Icon awesome-folder"
            d="M1740,244.5H1020L780,4.5H180A180,180,0,0,0,0,184.5v1080a180,180,0,0,0,180,180H1740a180,180,0,0,0,180-180v-840A180,180,0,0,0,1740,244.5Z"
            transform="translate(0 -4.5)"
            fill="#f5f9ff"
            opacity="0.24"
          />
          <path
            id="Icon_awesome-folder-2"
            data-name="Icon awesome-folder"
            d="M1740,244.5H1020L780,4.5H180A180,180,0,0,0,0,184.5v1080a180,180,0,0,0,180,180H1740a180,180,0,0,0,180-180v-840A180,180,0,0,0,1740,244.5Z"
            transform="translate(0 95.5)"
            fill="#f5f9ff"
            opacity="0.24"
          />
        </svg>
      </div>
    </div>
  );
}
