import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { deleteRating } from "../../api/ratings";
import useMutateRatingData from "./useMutateRatingData";

type openModalProps = {
  reportTemplateId: string;
  sectionId: string;
  ratingId: string;
};

export default function useDeleteRating(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Beoordelingspunt verwijderd",
      icon: <IconCheck />,
      message: `Het beoordelingspunt is verwijderd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het verwijderen van het beoordelingspunt. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateRatingData(deleteRating, onSuccess, onError);

  const onSubmit = async (
    modalId: string,
    reportTemplateId: string,
    sectionId: string,
    ratingId: string
  ) => {
    modals.closeModal(modalId);
    navigateAway && navigate(`/report-templates/${reportTemplateId}`);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Beoordelingspunt verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate({
      reportTemplateId,
      sectionId,
      ratingId,
    });
  };

  const openModal = ({ reportTemplateId, sectionId, ratingId }: openModalProps) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je dit beoordelingspunt wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een beoordelingspunt te verwijderen uit het systeem. Dit is een
            permanente actie en kan niet ongedaan worden gemaakt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button
              onClick={() => onSubmit(modalId, reportTemplateId, sectionId, ratingId)}
              color="red"
            >
              Verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
