import { Box, Flex, Group, Overlay, OverlayProps, Stack, Text, Title } from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";

type LockedOverlayProps = {
  visible: boolean;
  reason?: string;
  lockedUntil?: Date;
  onLockExpired?: () => void;
} & OverlayProps;

export default function LockedOverlay(props: LockedOverlayProps) {
  const { visible, reason, lockedUntil, onLockExpired, ...overlayProps } = props;

  const countdownRenderer = ({ minutes, seconds }: CountdownRenderProps) => {
    return (
      <span>
        {minutes}:{zeroPad(seconds, 2)} minuten
      </span>
    );
  };

  return (
    <>
      {visible && (
        <>
          <Overlay opacity={0.75} blur={3} zIndex={5} {...overlayProps} />
          <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 6 }}>
            <Flex sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Group>
                <IconLock size={64} />
                <Stack spacing={5}>
                  <Title>Verslag vergrendeld</Title>
                  {reason && <Text>{reason}</Text>}{" "}
                  {lockedUntil && (
                    <Text>
                      Het verslag zal worden ontgrendeld na{" "}
                      <Countdown
                        date={lockedUntil}
                        renderer={countdownRenderer}
                        onComplete={() => onLockExpired?.()}
                      />{" "}
                      als er in de tussentijd geen wijzigingen worden aangebracht.
                    </Text>
                  )}
                </Stack>
              </Group>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
}
