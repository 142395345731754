import { Box, Group, Input, Overlay, Text, useMantineTheme } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck, IconFileText, IconUpload, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { SchoolClass } from "../../../../types";
import { importStudent } from "../../../api/import-students";
import { fetchSchoolClasses } from "../../../api/school-class";
import useMutateSchoolClassData from "../../../hooks/school-class/useMutateSchoolClassData";
import { pluralize } from "../../../tools";
import { FancySelect } from "../../selects/FancySelect";

type ImportStudentFormProps = {
  classId?: string;
  onClose: () => void;
};

function ImportStudentForm(props: ImportStudentFormProps) {
  const { classId: classIdProp, onClose, ...restProps } = props;
  const [classId, setClassId] = useState(classIdProp);
  const theme = useMantineTheme();

  const onSuccess = (res: any) => {
    cleanNotifications();

    showNotification({
      title: "Leerlingen geïmporteerd",
      icon: <IconCheck />,
      message: `${res.imported_count}/${res.total_count} leerlingen uit ${
        res.file_count
      } ${pluralize(res.file_count, "bestand", "bestanden")} zijn geïmporteerd.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het importeren van de leerlingen in de klas. ${error}`,
      color: "red",
      autoClose: 15000,
    });
  };

  const { mutate, isLoading } = useMutateSchoolClassData(importStudent, onSuccess, onError);

  const onSubmit = (values: any) => {
    showNotification({
      disallowClose: true,
      loading: true,
      title: "Leerlingen importeren",
      message: "Bezig met importeren ...",
    });

    mutate({ classId, files: values });
  };

  const onReject = () =>
    onError(
      "Het bestand is niet geldig, alleen CSV-bestanden met een maximale grootte van 3MB zijn toegestaan."
    );

  return (
    <>
      {!classIdProp && (
        <FancySelect<SchoolClass>
          required
          mb={"md"}
          queryFn={(query: string) => fetchSchoolClasses(0, 100, [], query)}
          labelRenderer={(val) =>
            `${val.name} (${val.school_year?.name}${
              !val.school_year?.active ? " - niet actief" : ""
            })`
          }
          onItemSubmit={(val) => setClassId(val.data.id)}
          label="Klas"
          placeholder="Selecteer een klas"
          onClear={() => setClassId(undefined)}
        />
      )}

      <Input.Wrapper label="CSV-bestand">
        <Box sx={{ position: "relative" }}>
          {!classId && <Overlay />}
          <Dropzone
            disabled={!classId}
            loading={isLoading}
            onDrop={onSubmit}
            onReject={onReject}
            maxSize={3 * 1024 ** 2}
            accept={{ "text/csv": [".csv"] }}
            {...restProps}
          >
            <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: "none" }}>
              <Dropzone.Accept>
                <IconUpload
                  size={50}
                  stroke={1.5}
                  color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size={50}
                  stroke={1.5}
                  color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconFileText size={50} stroke={1.5} />
              </Dropzone.Idle>

              <div>
                <Text size="xl" inline>
                  {classId
                    ? "Sleep hier je bestand of klik hier om een bestand te selecteren"
                    : "Selecteer eerst een klas"}
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Je kunt alleen CSV-bestanden importeren met een maximale grootte van 3MB.
                </Text>
              </div>
            </Group>
          </Dropzone>
        </Box>
      </Input.Wrapper>
    </>
  );
}

export default ImportStudentForm;
