import { Loader, Stack, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Rating } from "../../../../types";
import { mutateReportRating } from "../../../api/report-ratings";
import { DEBOUNCE_TIMEOUT } from "../../../constants";
import useMutateReportRatingData from "../../../hooks/report-rating/useMutateReportRatingData";
import NumericRatingInput from "../../inputs/NumericRatingInput";

type SectionRatingListItemProps = {
  reportId: string;
  period: number;
  rating: Rating;
  defaultValue: string;
};

export default function SectionRatingListItem(props: SectionRatingListItemProps) {
  const { reportId, period, rating, defaultValue } = props;
  const [textValue, setTextValue] = useDebouncedState<string | null>(null, DEBOUNCE_TIMEOUT);
  const [numericValue, setNumericValue] = useState<number | null>(null);

  const onSuccess = () => {
    showNotification({
      title: "Beoordelingspunt bijgewerkt",
      icon: <IconCheck />,
      message: `De beoordeling is bijgewerkt`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de beoordeling. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading: isMutating } = useMutateReportRatingData(
    mutateReportRating,
    onSuccess,
    onError
  );

  const isLoading = isMutating;

  useEffect(() => {
    let value = null;

    if (rating.type === "RATING_TEXT" && textValue !== null) {
      value = textValue;
    } else if (rating.type === "RATING_NUMBERS" && numericValue !== null) {
      value = numericValue.toString();
    } else return;

    mutate({
      reportId,
      ratingId: rating.id,
      reportRating: {
        period,
        value,
      },
    });
  }, [textValue, numericValue]);

  if (period < 1 || period > 2) return null;
  if (period === 1 && !rating.period_1) return null;
  if (period === 2 && !rating.period_2) return null;

  return (
    <Stack spacing={5}>
      {rating.type === "RATING_TEXT" && (
        <TextInput
          required={rating.required}
          maxLength={20}
          sx={{ input: { background: "#FAFAFA", border: "none" } }}
          disabled={isLoading}
          rightSection={isLoading && <Loader size={16} />}
          defaultValue={defaultValue}
          onChange={(event) => setTextValue(event.currentTarget.value)}
          label={rating.name}
          placeholder="Voer hier uw tekst in"
        />
      )}
      {rating.type === "RATING_NUMBERS" && (
        <NumericRatingInput
          required={rating.required}
          isLoading={isLoading}
          label={rating.name}
          defaultValue={defaultValue ? parseInt(defaultValue) : 0}
          onUpdate={setNumericValue}
        />
      )}
    </Stack>
  );
}
