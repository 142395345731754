import { AppBackground } from "./components/AppBackground";
import FullRouteCollection from "./components/routing/FullRouteCollection";

function App() {
  return (
    <>
      <AppBackground />
      <FullRouteCollection />
    </>
  );
}

export default App;
