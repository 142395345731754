import { createStyles, Table } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Rating } from "../../../types";
import { RATING_TYPES } from "../../constants";

type RatingDetailsTableProps = {
  rating: Rating;
};

const useStyles = createStyles((theme) => ({
  table: {
    border: "none",
    width: "auto",

    "tbody tr td": {
      border: "none",
      padding: "2px 50px 2px 0",

      "&:last-child": {
        textAlign: "right",
        paddingRight: 0,
      },
    },
  },
}));

export default function TemplateRatingDetailsTable(props: RatingDetailsTableProps) {
  const { rating } = props;
  const { classes } = useStyles();

  return (
    <Table className={classes.table}>
      <tbody>
        <tr>
          <td>Type</td>
          <td>{RATING_TYPES.find((type) => type.value === rating.type)?.label}</td>
        </tr>
        <tr>
          <td>Periode 1</td>
          <td>{rating.period_1 ? <IconCheck /> : <IconX />}</td>
        </tr>
        <tr>
          <td>Periode 2</td>
          <td>{rating.period_2 ? <IconCheck /> : <IconX />}</td>
        </tr>
      </tbody>
    </Table>
  );
}
