import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableReportTemplate, ReportTemplate } from "../../../../types";
import { createReportTemplate } from "../../../api/report-templates";
import useMutateReportTemplateData from "../../../hooks/report-template/useMutateReportTemplateData";
import ReportTemplateForm from "./ReportTemplateForm";

type CreateReportTemplateFormProps = {
  onClose: () => void;
};

function CreateReportTemplateForm(props: CreateReportTemplateFormProps) {
  const { onClose } = props;

  const onSuccess = (values: ReportTemplate) => {
    showNotification({
      title: "Verslag template aangemaakt",
      icon: <IconCheck />,
      message: `Het verslag template ${values.name} is aangemaakt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van het verslag template. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateReportTemplateData(
    createReportTemplate,
    onSuccess,
    onError
  );

  const onSubmit = (values: PatchableReportTemplate) => mutate(values);

  return (
    <>
      <ReportTemplateForm isLoading={isLoading} submitAction={(values) => onSubmit(values)} />
    </>
  );
}

export default CreateReportTemplateForm;
