import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Box, Button, Group, LoadingOverlay, Stack } from "@mantine/core";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Section } from "../../../types";
import { updateReportTemplate } from "../../api/report-templates";
import PreviousPageAnchor from "../../components/anchors/PreviousPageAnchor";
import ReportTemplateNameInput from "../../components/inputs/ReportTemplateNameInput";
import ReportTemplateSection from "../../components/ReportTemplateSection";
import SortableItem from "../../components/SortableItem";
import useMutateReportTemplateData from "../../hooks/report-template/useMutateReportTemplateData";
import useReportTemplateData from "../../hooks/report-template/useReportTemplateData";
import useCreateSection from "../../hooks/section/useCreateSection";
import { UserContext } from "../../providers/user";

export default function ReportTemplateDetails() {
  const { id: reportTemplateId = "" } = useParams();
  const { isAuthorized } = useContext(UserContext);
  const { data: reportTemplate, isFetching } = useReportTemplateData(reportTemplateId);
  const { isLoading: isMutating, mutate: mutateReportTemplate } =
    useMutateReportTemplateData(updateReportTemplate);
  const isLoading = isMutating || isFetching;

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const { openModal: openCreateSection } = useCreateSection();

  const sections: Section[] = reportTemplate?.sections ?? [];
  const sectionIds = sections.map((section: Section) => section.id);

  const sortedData: any =
    sectionIds.map((id: string) => sections.find((item: Section) => item.id === id)) ?? [];

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activeIndex = sectionIds.indexOf(active.id as string);
      const overIndex = sectionIds.indexOf(over?.id as string);

      mutateReportTemplate({
        ...reportTemplate,
        section_order: arrayMove(sectionIds, activeIndex, overIndex),
      });
    }
  };

  const itemRenderer = (section: Section, listeners: any, attributes: any) => (
    <ReportTemplateSection
      key={section.id}
      reportTemplateId={reportTemplateId}
      section={section}
      listeners={listeners}
      attributes={attributes}
    />
  );

  return (
    <Stack sx={{ height: "100%" }}>
      <Group position="apart">
        <PreviousPageAnchor />

        {isAuthorized("report_template_update") && (
          <Button onClick={() => openCreateSection({ reportTemplateId })}>Sectie toevoegen</Button>
        )}
      </Group>

      <ReportTemplateNameInput
        section={reportTemplate}
        readOnly={!isAuthorized("report_template_update")}
      />

      <Box sx={{ position: "relative", height: "100%" }}>
        <LoadingOverlay visible={isLoading} />
        <Box sx={{ height: "100%", overflowY: "scroll" }}>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext items={sortedData} strategy={horizontalListSortingStrategy}>
              <Box sx={{ display: "flex", gap: 16, height: "100%" }}>
                {sortedData.map((section: Section) => (
                  <SortableItem
                    key={section.id}
                    id={section.id}
                    item={section}
                    itemRenderer={itemRenderer}
                    style={{ flex: "0 0 400px", height: "100%" }}
                  />
                ))}
              </Box>
            </SortableContext>
          </DndContext>
        </Box>
      </Box>
    </Stack>
  );
}
