import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import SearchProvider from "./search";
import UserProvider from "./user";

type ProvidersProps = {
  children?: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

export default function Providers(props: ProvidersProps) {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: "Karla, sans-serif",
            defaultRadius: 10,
            colors: {
              blue: [
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
                "#278ACA",
              ],
            },
            components: {
              Container: {
                defaultProps: {
                  sizes: {
                    xs: 540,
                    sm: 720,
                    md: 960,
                    lg: 1140,
                    xl: 1680,
                  },
                },
              },
            },
          }}
        >
          <UserProvider>
            <NotificationsProvider>
              <ModalsProvider>
                <SearchProvider>{props.children}</SearchProvider>
              </ModalsProvider>
            </NotificationsProvider>
          </UserProvider>
        </MantineProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
