import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { MutateDiscreteReport, Report } from "../../../types";
import { createReport } from "../../api/reports";
import useMutateReportData from "./useMutateReportData";

type createDiscreteProps = {
  studentId: string;
  period: number | string;
};

export default function useCreateReportDiscrete(
  navigateAway: boolean = false,
  name: string,
  year: string
) {
  const navigate = useNavigate();

  const onSuccess = (values: Report, variables: any) => {
    showNotification({
      title: "Verslag aangemaakt",
      icon: <IconCheck />,
      message: `Het verslag is aangemaakt.`,
    });

    navigateAway &&
      navigate(
        `/reports/${values.id}/${variables.period}?name=${name}&year=${year}`
      );
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van het verslag. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateReportData(createReport, onSuccess, onError);

  const createDiscrete = (props: createDiscreteProps) => {
    const { studentId, period } = props;

    mutate({ student_id: studentId, period } as MutateDiscreteReport);
  };

  return {
    createDiscrete,
  };
}
