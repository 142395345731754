import { Loader, TextInput, TextInputProps } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useEffect } from "react";
import { ReportTemplate, Section } from "../../../types";
import { updateReportTemplate } from "../../api/report-templates";
import { DEBOUNCE_TIMEOUT } from "../../constants";
import useMutateReportTemplateData from "../../hooks/report-template/useMutateReportTemplateData";

type ReportTemplateNameInputProps = {
  section: Section;
} & Partial<TextInputProps>;

export default function ReportTemplateNameInput(props: ReportTemplateNameInputProps) {
  const { section, ...restProps } = props;
  const [name, setName] = useDebouncedState<string | null>(null, DEBOUNCE_TIMEOUT);

  const onSuccess = (values: ReportTemplate) => {
    showNotification({
      title: "Verslag template bijgewerkt",
      icon: <IconCheck />,
      message: `Het verslag template ${values.name} is bijgewerkt.`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van het verslag template. ${error}`,
      color: "red",
    });
  };

  const { isLoading: isMutating, mutate } = useMutateReportTemplateData(
    updateReportTemplate,
    onSuccess,
    onError
  );

  useEffect(() => {
    if (name) {
      mutate({
        id: section.id,
        name,
      });
    }
  }, [name]);

  return (
    <TextInput
      disabled={isMutating}
      rightSection={isMutating && <Loader size={16} />}
      size="lg"
      defaultValue={section?.name}
      onChange={(e) => setName(e.currentTarget.value)}
      {...restProps}
    />
  );
}
