import { Button, Checkbox, Group, Input, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableSection, Section } from "../../../../types";
import { validateString } from "../../../validator";

type SectionFormProps = {
  section?: Section;
  submitText?: string;
  submitAction: (values: PatchableSection) => void;
  isLoading?: boolean;
};

function SectionForm(props: SectionFormProps) {
  const { section, submitText, submitAction, isLoading = false } = props;

  const form = useForm<PatchableSection>({
    initialValues: {
      id: section?.id ?? "",
      name: section?.name ?? "",
      new_page: section?.new_page ?? false,
    },

    validate: {
      name: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput data-autofocus pb={"md"} required label="Naam" {...form.getInputProps("name")} />

      <Input.Wrapper label={"Nieuwe pagina"} mb={"md"}>
        <Checkbox
          label={"Start deze sectie altijd op een nieuwe pagina"}
          {...form.getInputProps("new_page", { type: "checkbox" })}
        />
      </Input.Wrapper>

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default SectionForm;
