import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Section } from "../../../types";

export default function useMutateSectionData(
  mutationFn: (...args: any) => Promise<unknown>,
  onSuccess?: (values: Section) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload Report Template, Report & Section data
      queryClient.invalidateQueries(["reports"]);
      queryClient.invalidateQueries(["sections"]);
      queryClient.invalidateQueries(["report-templates"]);

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
