import { createStyles, Loader, Textarea, TextareaProps } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { ChangeEventHandler, useState } from "react";
import { Report } from "../../../types";
import { updateReport } from "../../api/reports";
import useMutateReportData from "../../hooks/report/useMutateReportData";
import UnsavedIcon from "../icons/UnsavedIcon";

type ReportCommentProps = {
  report: Report;
  onUpdate?: (value: string) => void;
} & TextareaProps;

const useStyles = createStyles((theme) => ({
  textarea: {
    "& textarea": {
      background: "#FAFAFA",
      border: "none",
    },
  },
}));

export default function ReportCommentInput(props: ReportCommentProps) {
  const { report, onUpdate, ...restProps } = props;
  const { classes } = useStyles();
  const [comment, setComment] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const onSuccess = () => {
    showNotification({
      title: "Verslag bijgewerkt",
      icon: <IconCheck />,
      message: `Het verslag is bijgewerkt.`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van het verslag. ${error}`,
      color: "red",
    });
  };

  const { isLoading: isMutating, mutate } = useMutateReportData(updateReport, onSuccess, onError);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    let value = event.currentTarget.value;
    setIsDirty(value !== report.comment);
    setComment(value);
    onUpdate?.(value);
  };

  const onBlur = () => {
    if (comment !== null && comment !== report.comment) {
      mutate({
        ...{ id: report.id },
        ...{ comment },
      });
    }

    setIsDirty(false);
  };

  const rightSection = isDirty ? <UnsavedIcon /> : isMutating ? <Loader size={16} /> : null;

  return (
    <Textarea
      minRows={10}
      disabled={isMutating}
      rightSection={rightSection}
      defaultValue={report?.comment ?? ""}
      onChange={onChange}
      onBlur={onBlur}
      className={classes.textarea}
      styles={{
        rightSection: {
          alignItems: "flex-start",
          marginTop: 10,
        },
      }}
      {...restProps}
    />
  );
}
