import { Box, Card, createStyles, Grid, Group, SimpleGrid, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconFile3d, IconFolder, IconUsers } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { ReportTemplate } from "../../types";
import ViewAllButton from "../components/buttons/ViewAllButton";
import PageTitle from "../components/PageTitle";
import SimpleReportTable from "../components/tables/SimpleReportTable";
import StudentTable from "../components/tables/StudentTable";
import useReportTemplates from "../hooks/report-template/useReportTemplates";

const useStyles = createStyles((theme) => ({
  templateCard: {
    cursor: "pointer",
    paddingTop: "3rem !important",
    paddingBottom: "3rem !important",

    "&:hover": {
      backgroundColor: "#944BAA",
      color: "white",
    },
  },

  orangeCard: {
    "&:hover": {
      backgroundColor: "#F9A52C",
    },
  },

  greenCard: {
    "&:hover": {
      backgroundColor: "#1ED26C",
    },
  },

  iconBackground: {
    background: "#E6ECF0",
    width: 35,
    aspectRatio: "1 / 1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },

  icon: {
    color: "#944BAA",
    fill: "#944BAA",
  },
}));

export default function Home() {
  const { classes, cx } = useStyles();
  const smallScreen = useMediaQuery("(max-width: 900px)");
  const { data: templates, isLoading } = useReportTemplates();
  const navigate = useNavigate();

  return (
    <>
      <SimpleGrid cols={smallScreen ? 1 : 2} mb={"md"}>
        <Box>
          <Group position="apart">
            <PageTitle ml={"xs"} title="Recente verslagen" />
            <ViewAllButton onClick={() => navigate("/reports")} />
          </Group>
          <SimpleReportTable queryPageSize={4} />
        </Box>
        <Box>
          <Group position="apart">
            <PageTitle ml={"xs"} title="Mijn templates" />
            <ViewAllButton onClick={() => navigate("/report-templates")} variant="subtle" />
          </Group>
          <Grid my={"md"} grow columns={3}>
            {templates?.data?.slice(0, 3).map((template: ReportTemplate, key: number) => (
              <Grid.Col span={1} sx={{ maxWidth: "100% !important" }}>
                <Card
                  sx={{ height: "100%" }}
                  key={template.id}
                  className={classes.templateCard}
                  onClick={() => navigate("/report-templates")}
                >
                  <Stack spacing={5}>
                    <Box className={classes.iconBackground}>
                      <IconFolder size={24} className={classes.icon} />
                    </Box>
                    <Text
                      sx={{
                        textOverflow: "ellipsis",
                        overflowWrap: "anywhere",
                      }}
                      size={"sm"}
                    >
                      {template.name.substring(0, 60)}
                      {template.name.length > 60 && "..."}
                    </Text>
                  </Stack>
                </Card>
              </Grid.Col>
            ))}

            <Grid.Col span={1}>
              <Card
                onClick={() => navigate("/students")}
                className={cx(classes.templateCard, classes.orangeCard)}
              >
                <Stack spacing={5}>
                  <Box className={classes.iconBackground}>
                    <IconUsers size={24} color={"#F9A52C"} />
                  </Box>
                  <Text size={"sm"}>Leerlingen</Text>
                </Stack>
              </Card>
            </Grid.Col>

            <Grid.Col span={1}>
              <Card
                onClick={() => navigate("/reports")}
                className={cx(classes.templateCard, classes.greenCard)}
              >
                <Stack spacing={5}>
                  <Box className={classes.iconBackground}>
                    <IconFile3d size={24} color={"#1ED26C"} />
                  </Box>
                  <Text size={"sm"}>Verslagen</Text>
                </Stack>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </SimpleGrid>
      <Box>
        <Group position="apart">
          <PageTitle ml={"xs"} title="Mijn leerlingen" />
          <ViewAllButton onClick={() => navigate("/students")} variant="subtle" />
        </Group>
        <StudentTable queryPageSize={4} />
      </Box>
    </>
  );
}
