import { Text } from "@mantine/core";

type PageTitleProps = {
  text: string;
};

export default function TableHeaderText(props: PageTitleProps) {
  return (
    <Text fw={"bold"} size={12} color={"#004877"}>
      {props.text}
    </Text>
  );
}
