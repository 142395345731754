import { Anchor, Button, Group, Text } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SchoolClass } from "../../../types";
import { fetchSchoolClasses } from "../../api/school-class";
import useDeleteSchoolClass from "../../hooks/school-class/useDeleteSchoolClass";
import useEditSchoolClass from "../../hooks/school-class/useEditSchoolClass";
import useImportSchoolClassStudents from "../../hooks/school-class/useImportSchoolClassStudents";
import { UserContext } from "../../providers/user";
import TableHeaderText from "../TableHeaderText";
import FancyTable from "./FancyTable";

export default function SchoolClassTable() {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteSchoolClass();
  const { openModal: openEditModal } = useEditSchoolClass();
  const { openModal: openImportModal } = useImportSchoolClassStudents();

  const columns = React.useMemo(
    () => [
      {
        id: "school-class-table",
        columns: [
          {
            id: "name",
            Header: <TableHeaderText text="Naam" />,
            accessor: (d: SchoolClass) => (
              <Anchor component={Link} to={`/school-classes/${d.id}`}>
                {d.name}
              </Anchor>
            ),
          },
          {
            id: "school_year.name",
            Header: <TableHeaderText text="Schooljaar" />,
            accessor: (d: any) => (
              <Group spacing={5}>
                <Text>
                  <Anchor component={Link} to={"/admin/school-years"}>
                    {d.school_year?.name}
                  </Anchor>
                </Text>
                <Text c="dimmed">({d.school_year?.active ? "actief" : "niet actief"})</Text>
              </Group>
            ),
          },
          {
            id: "students_count",
            Header: <TableHeaderText text="Aantal leerlingen" />,
            disableSortBy: true,
            accessor: (d: SchoolClass) => <Text>{d.students_count ?? "Onbekend"}</Text>,
          },
        ],
      },
    ],
    []
  );

  const additionalActionsRenderer = (row: SchoolClass) =>
    isAuthorized("school_class_import") ? (
      <Button onClick={() => openImportModal(row.id)}>
        <IconUpload />
      </Button>
    ) : null;

  return (
    <>
      <FancyTable
        queryKey={"school-classes"}
        queryFn={fetchSchoolClasses}
        columns={columns}
        onDelete={isAuthorized("school_class_destroy") ? openDeleteModal : undefined}
        onEdit={isAuthorized("school_class_update") ? openEditModal : undefined}
        additionalActionsRenderer={additionalActionsRenderer}
      />
    </>
  );
}
