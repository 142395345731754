import { Button, Stack, Text } from "@mantine/core";
import useDisableTwoFactorAuth from "../../../hooks/auth/2fa/useDisableTwoFactorAuth";

export default function TwoFactorAuthEnabled() {
  const { openModal } = useDisableTwoFactorAuth();

  return (
    <div>
      <Text>2FA ingeschakeld</Text>
      <Text size="sm" mb="md">
        Je account is beveiligd met tweestapsverificatie.
      </Text>
      <Button onClick={openModal} color="red">
        Uitschakelen
      </Button>
    </div>
  );
}
