import { Box, List } from "@mantine/core";
import React, { useContext } from "react";
import { Permission } from "../../../types";
import { fetchRoles } from "../../api/roles";
import useDeleteRole from "../../hooks/role/useDeleteRole";
import useEditRole from "../../hooks/role/useEditRole";
import { UserContext } from "../../providers/user";
import TableHeaderText from "../TableHeaderText";
import FancyTable from "./FancyTable";

export default function RoleTable() {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteRole();
  const { openModal: openEditModal } = useEditRole();

  const columns = React.useMemo(
    () => [
      {
        id: "role-table",
        columns: [
          {
            id: "name",
            Header: <TableHeaderText text="Naam" />,
            accessor: "name",
          },
          {
            id: "permissions",
            Header: <TableHeaderText text="Permissies" />,
            disableSortBy: true,
            accessor: (d: any) => {
              return (
                <Box sx={{ maxHeight: 90, overflowY: "auto" }}>
                  <List size={"sm"}>
                    {d.permissions.map((value: Permission) => (
                      <List.Item key={value?.name}>{value?.description}</List.Item>
                    ))}
                  </List>
                </Box>
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <FancyTable
        queryKey={"roles"}
        queryFn={fetchRoles}
        columns={columns}
        onDelete={isAuthorized("role_destroy") ? openDeleteModal : undefined}
        onEdit={isAuthorized("role_update") ? openEditModal : undefined}
      />
    </>
  );
}
