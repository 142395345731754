import { Button, ButtonProps } from "@mantine/core";
import { useEffect, useState } from "react";

type DelayedButtonProps = {
  title: string;
  timeout: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  color?: ButtonProps["color"];
};

export default function DelayedButton(props: DelayedButtonProps) {
  const { title, timeout, ...rest } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(timeout);

  useEffect(() => {
    if (remainingTime > 0) {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
    }
  }, [remainingTime]);

  const buttonTitle = isDisabled ? `${title} (${Math.ceil(remainingTime)})` : title;

  return (
    <Button disabled={isDisabled} {...rest}>
      {buttonTitle}
    </Button>
  );
}
