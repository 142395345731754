import { createStyles, Loader, Textarea, TextareaProps } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useState } from "react";
import { Section } from "../../../types";
import { mutateReportSection } from "../../api/report-sections";
import useMutateReportSectionData from "../../hooks/report-section/useMutateReportSectionData";
import useReportSectionData from "../../hooks/report-section/useReportSectionData";
import UnsavedIcon from "../icons/UnsavedIcon";

type SectionCommentProps = {
  reportId: string;
  sectionId: string;
  onUpdate?: (value: string) => void;
} & TextareaProps;

const useStyles = createStyles((theme) => ({
  textarea: {
    "& textarea": {
      background: "#FAFAFA",
      border: "none",
    },
  },
}));

export default function SectionCommentInput(props: SectionCommentProps) {
  const { reportId, sectionId, onUpdate, ...restProps } = props;
  const { classes } = useStyles();
  const [comment, setComment] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const onReportSuccess = (values: Section) => {
    onUpdate?.(values.comment ?? "");
  };

  const { data: section, isLoading } = useReportSectionData(reportId, sectionId, onReportSuccess);

  const onSuccess = () => {
    showNotification({
      title: "Sectie bijgewerkt",
      icon: <IconCheck />,
      message: `De sectie is bijgewerkt.`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de sectie. ${error}`,
      color: "red",
    });
  };

  const { isLoading: isMutating, mutate } = useMutateReportSectionData(
    mutateReportSection,
    onSuccess,
    onError
  );

  const isDisabled = isLoading || isMutating;

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.currentTarget.value;
    setIsDirty(value !== section?.comment);
    setComment(value);
    onUpdate?.(value);
  };

  const onBlur = () => {
    if (comment !== null && comment !== section?.comment) {
      mutate({
        reportId,
        section: {
          ...{ section_id: sectionId },
          ...{ comment },
        },
      });
    }

    setIsDirty(false);
  };

  const rightSection = isDirty ? <UnsavedIcon /> : isDisabled ? <Loader size={16} /> : null;

  return (
    <Textarea
      minRows={3}
      disabled={isDisabled}
      rightSection={rightSection}
      defaultValue={section?.comment ?? ""}
      onChange={onChange}
      onBlur={onBlur}
      className={classes.textarea}
      placeholder="Vul een opmerking in."
      styles={{
        rightSection: {
          alignItems: "flex-start",
          marginTop: 10,
        },
      }}
      {...restProps}
    />
  );
}
