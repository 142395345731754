import { Section } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { handleRequestErrors } from "../tools";

type FetchReportSectionProps = {
    reportId: string;
    sectionId: string;
}

export const fetchReportSection = async (values: FetchReportSectionProps) => {
    return await fetch(`${API_URL}/report/${values.reportId}/section/${values.sectionId}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

type MutateReportSectionProps = {
    reportId: string;
    section: Section;
}

export const mutateReportSection = async (values: MutateReportSectionProps) => {
    return await fetch(`${API_URL}/report/${values.reportId}/section`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(values.section)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}
