import { SortingRule } from "react-table";
import { User } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { generateSortString, handleRequestErrors } from "../tools";

export const fetchUsers = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/user?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&q=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchUser = async (id: string) => {
    return await fetch(`${API_URL}/user/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export const createUser = async (user: User) => {
    return await fetch(`${API_URL}/user`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(user)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const updateUser = async (user: User) => {
    return await fetch(`${API_URL}/user/${user.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(user)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteUser = async (id: string) => {
    return await fetch(`${API_URL}/user/${id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}