import { removeAuthToken } from "../../tools";
import { API_URL, FETCH_HEADERS } from "../../constants";

export default function useLogout() {
  const fetchData = async function () {
    return await fetch(`${API_URL}/auth/logout`, {
      method: "POST",
      ...FETCH_HEADERS(),
    }).then((res) => res.json());
  };

  /**
   * Logout the signed-in user.
   */
  const logout = async function () {
    const res = await fetchData();

    if (!res?.error) {
      removeAuthToken();
      window.location.href = "/";
    }
  };

  return {
    logout,
  };
}
