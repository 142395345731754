import { API_URL, FETCH_HEADERS } from "../constants";
import { handleRequestErrors } from "../tools";

export const fetchReportRating = async (reportId: string, sectionId: string, period: number | string) => {
    return await fetch(`${API_URL}/report/${reportId}/section/${sectionId}/${period}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

type PatchableReportRating = {
    period: number | string;
    value: string;
}

type PatchableReportRatingProps = {
    reportId: string;
    ratingId: string;
    reportRating: PatchableReportRating;
}

export const mutateReportRating = async (props: PatchableReportRatingProps) => {
    const { reportId, ratingId, reportRating } = props;

    return await fetch(`${API_URL}/report/${reportId}/rating/${ratingId}`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(reportRating)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}