import { Container, createStyles, Loader, Paper, Stack } from "@mantine/core";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

const useStyles = createStyles((theme) => ({
  fullWidth: {
    height: "100%",
  },

  contentContainer: {
    backgroundColor: "#EFF6FB",
    flexGrow: 1,
    overflowY: "auto",
    borderRadius: 15,
  },
}));

export default function NavWrapper() {
  const { classes } = useStyles();

  const links = [
    { link: "/", label: "Start" },
    { link: "/students", label: "Leerlingen" },
    { link: "/reports", label: "Mijn verslagen" },
    { link: "/report-templates", label: "Verslag templates" },
    {
      link: "/admin",
      label: "Beheer",
      links: [
        { link: "/admin/users", label: "Gebruikers", permission: "user_index" },
        { link: "/admin/school-classes", label: "Klassen", permission: "school_class_index" },
        { link: "/admin/school-years", label: "Schooljaren", permission: "school_year_index" },
      ],
    },
  ];

  return (
    <Container size={"xl"} className={classes.fullWidth} sx={{ height: "100vh" }}>
      <Stack className={classes.fullWidth} spacing={0}>
        <Header links={links} />
        <Paper className={classes.contentContainer} p={"md"}>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Paper>
        <Footer />
      </Stack>
    </Container>
  );
}
