import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { deleteSchoolClass } from "../../api/school-class";
import useMutateSchoolClassData from "./useMutateSchoolClassData";

export default function useDeleteSchoolClass() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Klas verwijderd",
      icon: <IconCheck />,
      message: `De klas is verwijderd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het verwijderen van de klas. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateSchoolClassData(deleteSchoolClass, onSuccess, onError);

  const onSubmit = async (modalId: string, id: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Klas verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate(id);
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je deze klas wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een klas te verwijderen uit het systeem. Dit is een permanente
            actie en kan niet ongedaan worden gemaakt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId, id)} color="red">
              Klas verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
