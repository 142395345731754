import { Loader, TransferList, TransferListData } from "@mantine/core";
import { useEffect, useState } from "react";
import { fetchPermissions } from "../../api/permissions";
import { Maybe, Permission } from "../../../types";

type PermissionTransferListProps = {
  onSelectedPermissionsChange: (selectedPermissionIds: Permission[]) => void;
  initialSelectedValues?: Maybe<Permission[]>;
};

export default function PermissionTransferList(props: PermissionTransferListProps) {
  const { onSelectedPermissionsChange, initialSelectedValues = [] } = props;
  const [selectedPermissions, setSelectedPermissions] = useState<[any, any]>([[], []]);
  const [loading, setLoading] = useState(true);

  /**
   * Event handler that is called when a change occurs on the TransferList.
   * @param value
   */
  const onChange = (value: TransferListData) => {
    // Pass an array with the ids of the selected items.
    onSelectedPermissionsChange(
      value[1].map((item) => {
        return {
          id: parseInt(item.value),
        };
      })
    );

    setSelectedPermissions(value);
  };

  /**
   * Format the given array in the TransferList format.
   * @param values
   */
  const formatList = (values: Permission[]) => {
    return values.map((permission: Permission) => {
      return {
        value: `${permission.id}`,
        label: permission?.description,
        group: permission?.group,
      };
    });
  };

  /**
   * Get the difference from the initialSelectedValues array.
   * Returns a new array where items that are present in the initialSelectedValues array are excluded.
   * @param values
   */
  const filterData = (values: Permission[]) => {
    // Return the given values if no initial values are selected.
    if (!initialSelectedValues) {
      return values;
    }

    return values.filter((item: Permission) => {
      // Only return true if the current item does not exist in the initialSelectedValues array.
      return !initialSelectedValues.find((val: Permission) => val.id === item.id);
    });
  };

  /**
   * Gets the permission data and sets it formatted in the TransferList.
   */
  const onLoad = async function () {
    const response = await fetchPermissions();

    if (!response?.data) {
      return;
    }

    setSelectedPermissions([
      formatList(filterData(response.data)),
      formatList(initialSelectedValues ?? []),
    ]);

    setLoading(false);
  };

  /**
   * Call onLoad on mount.
   */
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <TransferList
      value={selectedPermissions}
      onChange={onChange}
      titles={["Permissies", "Geselecteerde permissies"]}
      searchPlaceholder="Zoeken"
      nothingFound={loading ? <Loader /> : "Geen overeenkomende permissies gevonden"}
      breakpoint="xs"
      listHeight={350}
      styles={(theme) => ({
        transferListTitle: {
          fontSize: 14,
        },
      })}
    />
  );
}
