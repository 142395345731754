import { Popover, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons-react";

export default function UnsavedIcon() {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover width={250} withinPortal position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <IconAlertTriangle onMouseEnter={open} onMouseLeave={close} size={16} color={"orange"} />
      </Popover.Target>
      <Popover.Dropdown>
        <Stack spacing={0}>
          <Text size="sm" fw={"bold"}>
            Let op!
          </Text>
          <Text size="sm">
            Je hebt onopgeslagen wijzigingen. Klik buiten het veld om de wijzigingen op te slaan.
          </Text>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
