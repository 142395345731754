import { Center, createStyles, Group, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import TwoFactorAuthForm from "../../components/forms/auth/TwoFactorAuthForm";

const useStyles = createStyles((theme) => ({
  formContainer: {
    width: "100%",

    [theme.fn.largerThan("sm")]: {
      width: 500,
    },
  },
}));

export default function Login() {
  const { classes } = useStyles();

  return (
    <Center mb={"md"} sx={{ height: "100%", flexDirection: "column", gap: 16 }}>
      <Title>2FA</Title>
      <Group className={classes.formContainer}>
        <TwoFactorAuthForm />
      </Group>
    </Center>
  );
}
