import { Box, createStyles, Flex, Input, Loader } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";

type NumericRatingInputProps = {
  label: string;
  defaultValue: number;
  required?: boolean;
  isLoading?: boolean;
  onUpdate?: (value: number) => void;
};

const useStyles = createStyles((theme) => ({
  boxItem: {
    border: "1px solid gray",
    borderColor: "#B1C3CF",
    borderRadius: 10,
    aspectRatio: "1/1",
    height: 40,
    cursor: "pointer",
  },
  boxItemActive: {
    backgroundColor: "#FAFAFA",
    border: "none",
  },
}));

export default function NumericRatingInput(props: NumericRatingInputProps) {
  const { label, defaultValue, required = false, isLoading = false, onUpdate } = props;
  const [value, setValue] = useState<number>(defaultValue);
  const { cx, classes } = useStyles();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Box sx={{ position: "relative" }}>
      <Input.Wrapper label={label} required={required}>
        {isLoading ? (
          <Loader sx={{ display: "block" }} height={40} width={"100%"} />
        ) : (
          <Flex
            sx={{
              justifyContent: "space-between",
            }}
          >
            {Array.from({ length: 5 }, (_, i) => (
              <Flex
                key={i}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  let newValue = value === i + 1 ? 0 : i + 1;
                  setValue(newValue);
                  onUpdate?.(newValue);
                }}
                className={cx(classes.boxItem, i + 1 <= value ? classes.boxItemActive : undefined)}
              >
                {i + 1 === value && (
                  <IconCheck style={{ height: 20, width: "100%" }} color="green" />
                )}
              </Flex>
            ))}
          </Flex>
        )}
      </Input.Wrapper>
    </Box>
  );
}
