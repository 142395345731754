import {
  Box,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  Stack,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import PreviousPageAnchor from "../../components/anchors/PreviousPageAnchor";
import ReportSectionList from "../../components/lists/ReportSectionList";
import LockedOverlay from "../../components/overlays/LockedOverlay";
import useExportReport from "../../hooks/report/useExportReport";
import useFinishReport from "../../hooks/report/useFinishReport";
import useUnblockReport from "../../hooks/report/useUnblockReport";
import useReportData from "../../hooks/report/useReportData";
import { useContext } from "react";
import { UserContext } from "../../providers/user";

type ReportDetailParams = {
  id: string;
  period: string;
};

export default function ReportDetail() {
  const { id: reportId = "", period = "" } = useParams<ReportDetailParams>();
  const [searchParams] = useSearchParams();
  const {
    data: report,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useReportData(reportId);
  const { exportReport } = useExportReport();
  const { openModal: openFinishReport } = useFinishReport();
  const { openModal: openUnblockReport } = useUnblockReport();
  const { data } = useContext(UserContext);

  if (!isFetching && isError) {
    showNotification({
      title: "Er ging iets mis",
      message:
        "Er is een fout opgetreden bij het ophalen van de data. Je bent teruggestuurd naar het leerlingenoverzicht.",
      autoClose: 10000,
      color: "red",
    });

    return <Navigate to={"/students"} />;
  }

  if (["1", "2"].includes(period) === false) {
    showNotification({
      title: "Ongeldige periode",
      message: "De periode moet gelijk zijn aan 1 of 2.",
    });

    return <Navigate to={"/students"} />;
  }

  // Initial loading state
  if (isLoading) {
    return <Loader />;
  }

  const isFinished = !!(period === "1"
    ? report.period_1_finished_at
    : report.period_2_finished_at);
  const isLocked = report.is_locked && !isFinished;

  const onLockExpired = () => refetch();

  return (
    <Stack
      sx={{ height: "100%", position: "relative", flexGrow: 1 }}
      p={isLocked ? "md" : undefined}
    >
      <LockedOverlay
        visible={isLocked}
        reason={`Dit verslag is vergrendeld omdat het op dit moment bewerkt wordt door ${report?.updated_by?.name}.`}
        lockedUntil={report?.locked_until}
        onLockExpired={onLockExpired}
      />
      <Group position="apart">
        <PreviousPageAnchor />
        <Group>
          {isFinished && data?.is_admin && (
            <Button onClick={() => openUnblockReport({ reportId, period })}>
              Ontgrendelen
            </Button>
          )}
          <Button
            onClick={() =>
              exportReport({
                reportId,
                period,
                name: searchParams.get("name") || "",
                year: searchParams.get("year") || "",
              })
            }
          >
            Verslag downloaden
          </Button>
          <Button
            title={isFinished ? "Het verslag is reeds afgerond" : undefined}
            disabled={isFinished}
            onClick={() => openFinishReport({ reportId, period })}
          >
            Verslag afronden
          </Button>
        </Group>
      </Group>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <LoadingOverlay visible={isFetching} />

        <ReportSectionList report={report} period={parseInt(period)} />
      </Box>
    </Stack>
  );
}
