import { Title } from "@mantine/core";
import PageTitle from "../../components/PageTitle";
import ReportTemplateTable from "../../components/tables/ReportTemplateTable";

export default function ReportTemplates() {
  return (
    <>
      <PageTitle title="Verslag templates" />
      <ReportTemplateTable />
    </>
  );
}
