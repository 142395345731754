import { Text, TextProps, Title } from "@mantine/core";

type PageTitleProps = {
  title: string;
} & Partial<TextProps>;

export default function PageTitle(props: PageTitleProps) {
  return (
    <Text fw={"bold"} ff={"Poppins, sans-serif"} size={18} color={"#1C4458"}>
      {props.title}
    </Text>
  );
}
