import { SortingRule } from "react-table";
import { PatchableReportTemplate } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { generateSortString, handleRequestErrors } from "../tools";

export const fetchReportTemplates = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/report-template?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&q=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchReportTemplate = async (id: string) => {
    return await fetch(`${API_URL}/report-template/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export const createReportTemplate = async (reportTemplate: PatchableReportTemplate) => {
    return await fetch(`${API_URL}/report-template`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(reportTemplate)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const updateReportTemplate = async (reportTemplate: PatchableReportTemplate) => {
    return await fetch(`${API_URL}/report-template/${reportTemplate.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(reportTemplate)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteReportTemplate = async (id: string) => {
    return await fetch(`${API_URL}/report-template/${id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}

export const duplicateReportTemplate = async (id: string) => {
    return await fetch(`${API_URL}/report-template/${id}/duplicate`, {
        ...FETCH_HEADERS(),
        method: 'POST',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}