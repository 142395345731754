import { Button, Container, createStyles, Group, Text, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 700,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
}));

type UserErrorProps = {
  error: string;
};

export function UserError(props: UserErrorProps) {
  const { error } = props;
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <Title className={classes.title}>Er ging iets mis</Title>

      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        Waarschijnlijk heb je een actie uitgevoerd die niet is toegestaan op basis van jouw rechten.
        Zie onderstaande foutmelding voor meer informatie.
      </Text>
      <Text color="dimmed" size="lg" align="center" className={classes.description}>
        {error || "Er is een onbekende fout opgetreden. Probeer het later opnieuw."}
      </Text>
    </Container>
  );
}

export default UserError;
