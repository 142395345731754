import RoleForm from "./RoleForm";
import { Role } from "../../../../types";
import useMutateRoleData from "../../../hooks/role/useMutateRoleData";
import { createRole } from "../../../api/roles";
import { showNotification } from "@mantine/notifications";
import React from "react";
import { IconCheck } from "@tabler/icons-react";

type CreateRoleFormProps = {
  onClose: () => void;
};

export default function CreateRoleForm(props: CreateRoleFormProps) {
  const { onClose } = props;

  const onSuccess = (value: Role) => {
    showNotification({
      title: "Rol aangemaakt",
      icon: <IconCheck />,
      message: `De rol ${value.name} is aangemaakt!`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: "Er ging iets mis",
      color: "red",
      message: `Er is een fout opgetreden tijdens het aanmaken van de rol. ${error}`,
    });
  };

  const { mutate, isLoading } = useMutateRoleData(createRole, onSuccess, onError);

  const onSubmit = (value: Role) => {
    mutate(value);
  };

  return <RoleForm onSubmit={onSubmit} loading={isLoading} submitText={"Create"} />;
}
