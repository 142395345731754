import {
  Button,
  Checkbox,
  createStyles,
  Group,
  Input,
  TextInput,
  Text,
  Stack,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext } from "react";
import { PatchableUser, Role, SchoolClass, User } from "../../../../types";
import { fetchRoles } from "../../../api/roles";
import { fetchSchoolClasses } from "../../../api/school-class";
import { UserContext } from "../../../providers/user";
import {
  validateEmail,
  validateSame,
  validateString,
} from "../../../validator";
import FancyMultiSelect from "../../selects/FancyMultiSelect";
import TwoFactorAuthTab from "../../tabs/TwoFactorAuthTab";
import useDisableForeignTwoFactorAuth from "../../../hooks/auth/2fa/useDisableForeignTwoFactorAuth";

type UserFormProps = {
  user?: User;
  submitText?: string;
  submitAction: (values: PatchableUser) => void;
  isLoading?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

function UserForm(props: UserFormProps) {
  const { user, submitText, submitAction, isLoading = false } = props;
  const { classes } = useStyles();
  const { data: currentUser, isAuthorized } = useContext(UserContext);
  const { openModal } = useDisableForeignTwoFactorAuth(user?.id ?? "");

  const validateEmailConfirmation: any = (value: string) => {
    if (user) {
      return null;
    }

    return validateSame(value, form.values.email, "het e-mailadres veld");
  };

  const form = useForm<PatchableUser>({
    initialValues: {
      id: user?.id ?? "",
      name: user?.name ?? "",
      email: user?.email ?? "",
      email_confirmation: "",
      active: user?.active ?? true,
      is_admin: user?.is_admin ?? false,
      school_classes: user?.school_classes?.map((i: SchoolClass) => i.id) ?? [],
      roles: user?.roles?.map((i: Role) => i.id) ?? [],
    },

    validate: (values) => ({
      name: validateString(values.name),
      email: validateEmail(values.email),
      email_confirmation: validateEmailConfirmation(values.email_confirmation),
    }),
  });

  const showClass =
    currentUser?.id === user?.id
      ? currentUser?.is_admin
      : isAuthorized(["user_update", "user_store"]);

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput
        data-autofocus
        mb={"md"}
        required
        label="Volledige naam"
        {...form.getInputProps("name")}
      />

      <Group grow className={classes.group} mb={"md"}>
        <TextInput
          required
          label="E-mailadres"
          {...form.getInputProps("email")}
        />

        {!user && (
          <TextInput
            required
            label="E-mailadres bevestigen"
            {...form.getInputProps("email_confirmation")}
          />
        )}
      </Group>

      {showClass && (
        <FancyMultiSelect<SchoolClass>
          onSelectedValuesChange={(values) =>
            form.setFieldValue("school_classes", values)
          }
          defaultValue={user?.school_classes?.map(
            (val: SchoolClass) => `${val.id}`
          )}
          queryFn={(query: string) => fetchSchoolClasses(0, 100, [], query)}
          labelRenderer={(val) => `${val.name} (${val.school_year?.name})`}
          label="Klassen"
          pb={"md"}
        />
      )}

      {currentUser?.id !== user?.id && (
        <>
          <Input.Wrapper label={"Actief"} mb={"md"}>
            <Checkbox
              label={`Activeer het account van ${form.values.name}`}
              {...form.getInputProps("active", { type: "checkbox" })}
            />
          </Input.Wrapper>

          {currentUser?.is_admin && (
            <Input.Wrapper label={"Administrator"} mb={"md"}>
              <Checkbox
                label={`Maak ${form.values.name} een administrator`}
                {...form.getInputProps("is_admin", { type: "checkbox" })}
              />
            </Input.Wrapper>
          )}

          <FancyMultiSelect<Role>
            onSelectedValuesChange={(values) =>
              form.setFieldValue(
                "roles",
                values.map((i) => parseInt(i))
              )
            }
            defaultValue={user?.roles?.map((val: Role) => `${val.id}`)}
            queryFn={() => fetchRoles(0, 100)}
            labelRenderer={(val) => `${val.name}`}
            label="Rollen"
            placeholder="De rollen van de gebruiker"
            pb={"sm"}
          />
        </>
      )}
      <Flex align="center" gap={12}>
        {user?.["2fa_enabled"] ? (
          <>
            <Button onClick={openModal} color="red">
              Uitschakelen
            </Button>
            <Text>2FA ingeschakeld</Text>
          </>
        ) : (
          <Text>2FA uitgeschakeld</Text>
        )}
      </Flex>
      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default UserForm;
