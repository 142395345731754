import { Button, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { validateSame, validateString } from "../../../validator";

type VerifyEmailFormProps = {
  loading?: boolean;
  onSubmit: () => void;
  setValues: (values: any) => void;
  submitText?: string;
};

function VerifyEmailForm(props: VerifyEmailFormProps) {
  const { loading, onSubmit, setValues, submitText } = props;

  const form = useForm({
    initialValues: {
      password: "",
      password_confirmation: "",
    },

    validate: (values) => ({
      password: validateString(values.password),
      password_confirmation: validateSame(
        values.password_confirmation,
        values.password,
        "het wachtwoord veld"
      ),
    }),
  });

  useEffect(() => {
    setValues(form.values);
  }, [form.values]);

  return (
    <form onSubmit={form.onSubmit(() => onSubmit())} style={{ width: "100%" }}>
      <PasswordInput
        data-autofocus
        disabled={loading}
        required
        label="Wachtwoord"
        placeholder="Wachtwoord"
        mb={"md"}
        {...form.getInputProps("password")}
      />

      <PasswordInput
        disabled={loading}
        required
        label="Bevestig wachtwoord"
        placeholder="Bevestig wachtwoord"
        mb={"md"}
        {...form.getInputProps("password_confirmation")}
      />

      <Button loading={loading} fullWidth type={"submit"}>
        {submitText ?? "Opslaan"}
      </Button>
    </form>
  );
}

export default VerifyEmailForm;
