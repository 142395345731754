import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Sx } from "@mantine/core";

type SortableItemProps<T> = {
  id: string;
  item: T;
  itemRenderer: (
    item: T,
    listeners: SyntheticListenerMap | undefined,
    attributes: DraggableAttributes
  ) => JSX.Element;
  style?: Sx | (Sx | undefined)[] | undefined;
};

export default function SortableItem<T>(props: SortableItemProps<T>) {
  const { id, item, itemRenderer, style: styleProp } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} sx={{ ...styleProp }} style={style}>
      {itemRenderer(item, listeners, attributes)}
    </Box>
  );
}
