import { showNotification } from "@mantine/notifications";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, FETCH_HEADERS } from "../../../constants";
import { UserContext } from "../../../providers/user";
import { formatErrors } from "../../../tools";

type LoginValues = {
  otp: string;
};

type TwoFactorAuthProps = LoginValues;

export default function useTwoFactorAuth() {
  const { refetch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async function (loginValues: LoginValues) {
    return await fetch(`${API_URL}/auth/2fa`, {
      method: "POST",
      ...FETCH_HEADERS(),
      body: JSON.stringify(loginValues),
    }).then((res) => res.json());
  };

  /**
   * Login the user.
   */
  const login = async function (twoFactorAuthProps: TwoFactorAuthProps) {
    setLoading(true);

    const res = await fetchData(twoFactorAuthProps);

    if (res?.data) {
      refetch();
      navigate("/");

      setLoading(false);
      return;
    }

    let errors = formatErrors(res);

    showNotification({
      title: `Er ging iets mis`,
      message: errors ? errors[0] : "Er ging iets mis??",
      color: "red",
    });

    setLoading(false);
  };

  return {
    login,
    loading,
  };
}
