import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import DefaultWrapper from "../../DefaultWrapper";
import NavWrapper from "../../NavWrapper";
import Login from "../../pages/auth/Login";
import TwoFactorAuth from "../../pages/auth/TwoFactorAuth";
import PasswordForgotten from "../../pages/auth/PasswordForgotten";
import ResetPassword from "../../pages/auth/ResetPassword";
import VerifyEmail from "../../pages/auth/VerifyEmail";
import Home from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import Profile from "../../pages/profile";
import ReportTemplates from "../../pages/report-templates";
import ReportTemplateDetails from "../../pages/report-templates/detail";
import Reports from "../../pages/reports";
import ReportDetail from "../../pages/reports/detail";
import Roles from "../../pages/roles";
import SchoolClasses from "../../pages/school-classes";
import SchoolClassDetails from "../../pages/school-classes/detail";
import SchoolYears from "../../pages/school-years";
import Students from "../../pages/students";
import Users from "../../pages/users";
import { UserContext } from "../../providers/user";
import { ConditionalComponent } from "./ConditionalComponent";

export default function FullRouteCollection() {
  const { isAuthed, isAuthorized } = useContext(UserContext);

  return (
    <Routes>
      <Route path={"/"} element={<NavWrapper />}>
        <Route
          path={"/"}
          element={<ConditionalComponent condition={isAuthed} Component={Home} title={"Start"} />}
        />

        <Route
          path={"students"}
          element={
            <ConditionalComponent condition={isAuthed} Component={Students} title={"Leerlingen"} />
          }
        />

        <Route
          path={"reports"}
          element={
            <ConditionalComponent condition={isAuthed} Component={Reports} title={"Verslagen"} />
          }
        />

        <Route
          path={"reports/:id/:period"}
          element={
            <ConditionalComponent
              condition={isAuthed}
              Component={ReportDetail}
              title={"Verslagen"}
            />
          }
        />

        <Route
          path={"report-templates"}
          element={
            <ConditionalComponent
              condition={isAuthed}
              Component={ReportTemplates}
              title={"Verslag templates"}
            />
          }
        />

        <Route
          path={"report-templates/:id"}
          element={
            <ConditionalComponent
              condition={isAuthed}
              Component={ReportTemplateDetails}
              title={"Verslag templates"}
            />
          }
        />

        <Route
          path={"school-classes/:schoolClassId"}
          element={
            <ConditionalComponent
              condition={isAuthed}
              Component={SchoolClassDetails}
              title={"Klassen"}
            />
          }
        />

        <Route
          path={"admin/users"}
          element={
            <ConditionalComponent
              condition={isAuthed && isAuthorized("user_index")}
              Component={Users}
              title={"Gebruikers"}
            />
          }
        />

        <Route
          path={"admin/school-years"}
          element={
            <ConditionalComponent
              condition={isAuthed && isAuthorized("school_year_index")}
              Component={SchoolYears}
              title={"Schooljaren"}
            />
          }
        />

        <Route
          path={"admin/school-classes"}
          element={
            <ConditionalComponent
              condition={isAuthed && isAuthorized("school_class_index")}
              Component={SchoolClasses}
              title={"Klassen"}
            />
          }
        />

        <Route
          path={"admin/roles"}
          element={
            <ConditionalComponent
              condition={isAuthed && isAuthorized("role_index")}
              Component={Roles}
              title={"Rollen"}
            />
          }
        />

        <Route
          path={"profile"}
          element={
            <ConditionalComponent condition={isAuthed} Component={Profile} title={"Profiel"} />
          }
        />

        <Route
          path={"*"}
          element={
            <ConditionalComponent
              condition={isAuthed}
              Component={NotFound}
              title={"Niet gevonden"}
            />
          }
        />
      </Route>

      {/* Routes for non-logged in users */}
      <Route path={"/"} element={<DefaultWrapper />}>
        <Route
          path={"login"}
          element={
            <ConditionalComponent
              condition={!isAuthed}
              redirectTo={"/"}
              title={"Inloggen"}
              Component={Login}
            />
          }
        />

        <Route
          path={"2fa"}
          element={
            <ConditionalComponent
              condition={isAuthed}
              redirectTo={"/"}
              title={"2FA"}
              Component={TwoFactorAuth}
            />
          }
        />

        <Route
          path={"/verify/:id/:hash/:expires/:signature"}
          element={
            <ConditionalComponent
              condition={!isAuthed}
              redirectTo={"/"}
              title={"Account instellen"}
              Component={VerifyEmail}
            />
          }
        />

        <Route
          path={"/password-forgotten"}
          element={
            <ConditionalComponent
              condition={!isAuthed}
              redirectTo={"/"}
              Component={PasswordForgotten}
              title={"Wachtwoord vergeten"}
            />
          }
        />
        <Route
          path={"/password-reset/:token/:email/:expires/:signature"}
          element={
            <ConditionalComponent
              condition={!isAuthed}
              redirectTo={"/"}
              Component={ResetPassword}
              title={"Wachtwoord herstellen"}
            />
          }
        />
      </Route>
    </Routes>
  );
}
