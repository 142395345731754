import { Section } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { handleRequestErrors } from "../tools";

type FetchSectionProps = {
    reportTemplateId: string;
    sectionId: string;
}

export const fetchSection = async (values: FetchSectionProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.sectionId}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

type MutateSectionProps = {
    reportTemplateId: string;
    section: Section;
}

export const createSection = async (values: MutateSectionProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(values.section)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const updateSection = async (values: MutateSectionProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.section.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(values.section)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteSection = async (values: FetchSectionProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.sectionId}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}