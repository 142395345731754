import PageTitle from "../../components/PageTitle";
import StudentTable from "../../components/tables/StudentTable";

export default function Students() {
  return (
    <>
      <PageTitle title={"Leerlingen"} />
      <StudentTable />
    </>
  );
}
