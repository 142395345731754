import { Button, ButtonProps } from "@mantine/core";

type ViewAllButtonProps = {
  title?: string;
  onClick?: () => void;
} & ButtonProps;

export default function ViewAllButton(props: ViewAllButtonProps) {
  const { title, ...rest } = props;

  return (
    <Button
      styles={{
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          fontSize: 12,

          "&:hover": {
            color: "#F5F9FF",
          },
        },
      }}
      variant="subtle"
      {...rest}
    >
      {title || "Bekijk alle"}
    </Button>
  );
}
