import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableSchoolYear, SchoolYear } from "../../../../types";
import { updateSchoolYear } from "../../../api/school-years";
import useMutateSchoolYearData from "../../../hooks/school-year/useMutateSchoolYearData";
import useSchoolYearData from "../../../hooks/school-year/useSchoolYearData";
import UserError from "../../UserError";
import SchoolYearForm from "./SchoolYearForm";

type EditSchoolYearFormProps = {
  schoolYearId: string;
  onClose: () => void;
};

function EditSchoolYearForm(props: EditSchoolYearFormProps) {
  const { schoolYearId, onClose } = props;
  const { data: schoolYear, isFetching, isError, error } = useSchoolYearData(schoolYearId);

  const onSuccess = (values: SchoolYear) => {
    showNotification({
      title: "Schooljaar bijgewerkt",
      icon: <IconCheck />,
      message: `Het schooljaar ${values.name} is bijgewerkt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van het schooljaar. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading: isMutating } = useMutateSchoolYearData(
    updateSchoolYear,
    onSuccess,
    onError
  );

  const onSubmit = (values: PatchableSchoolYear) => mutate(values);

  const isLoading = isFetching || isMutating;

  if (isFetching)
    return (
      <Center>
        <Loader height={200} />
      </Center>
    );

  if (isError) return <UserError error={error as string} />;

  return (
    <SchoolYearForm
      isLoading={isLoading}
      schoolYear={schoolYear}
      submitAction={(values) => onSubmit(values)}
    />
  );
}

export default EditSchoolYearForm;
