import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { deleteAvatar } from "../../api/avatar";
import useMutateAvatarData from "./useMutateAvatarData";

export default function useDeleteRating() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Avatar verwijderd",
      icon: <IconCheck />,
      message: `Je avatar is verwijderd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het verwijderen van je avatar. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateAvatarData(deleteAvatar, onSuccess, onError);

  const onSubmit = async (modalId: string) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Avatar verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate(null);
  };

  const openModal = () => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je je profielfoto wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om je profielfoto te verwijderen uit het systeem. Dit is een
            permanente actie en kan niet ongedaan worden gemaakt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId)} color="red">
              Verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
