import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchAvatar } from "../../api/avatar";
import { UserContext } from "../../providers/user";

export default function useAvatarData(onSuccess?: any, onError?: any) {
  const { data: user } = useContext(UserContext);

  return useQuery(["users", user?.id], () => fetchAvatar(), {
    onSuccess,
    onError,
  });
}
