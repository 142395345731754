import { Anchor, Center, createStyles, Group, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/forms/auth/LoginForm";

const useStyles = createStyles((theme) => ({
  formContainer: {
    width: "100%",

    [theme.fn.largerThan("sm")]: {
      width: 500,
    },
  },
}));

export default function Login() {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Center mb={"md"} sx={{ height: "100%", flexDirection: "column", gap: 16 }}>
      <Title>Inloggen met je schoolaccount</Title>
      <Group className={classes.formContainer}>
        <LoginForm />
      </Group>

      <Text>
        Wachtwoord vergeten?{" "}
        <Anchor onClick={() => navigate("/password-forgotten")}>
          Klik hier om je wachtwoord opnieuw in te stellen
        </Anchor>
      </Text>
    </Center>
  );
}
