import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SchoolYear } from "../../../types";

export default function useMutateSchoolYearData(
  mutationFn: (args: any) => Promise<unknown>,
  onSuccess: (values: SchoolYear) => void,
  onError: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload SchoolYear data
      queryClient.invalidateQueries(["school-years"]);

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
