import { Group, Text } from "@mantine/core";
import React from "react";
import { Role, SchoolClass, User } from "../../../types";
import { fetchUsers } from "../../api/users";
import useDeleteUser from "../../hooks/user/useDeleteUser";
import useEditUser from "../../hooks/user/useEditUser";
import TableHeaderText from "../TableHeaderText";
import FancyTable from "./FancyTable";

export default function UserTable() {
  const { openModal: openDeleteModal } = useDeleteUser();
  const { openModal: openEditModal } = useEditUser();

  const columns = React.useMemo(
    () => [
      {
        id: "user-table",
        columns: [
          {
            id: "name",
            Header: <TableHeaderText text="Naam" />,
            accessor: (d: User) => (
              <Group spacing={5}>
                <Text>{d.name}</Text>
                <Text size={"xs"} c={"dimmed"}>
                  {d.is_admin ? "(Administrator)" : ""}
                </Text>
              </Group>
            ),
          },
          {
            id: "email",
            Header: <TableHeaderText text="E-mailadres" />,
            accessor: "email",
          },
          {
            id: "active",
            Header: <TableHeaderText text="Status" />,
            accessor: (d: User) => (d.active ? "Actief" : "Inactief"),
          },
          {
            id: "school_classes",
            Header: <TableHeaderText text="Klassen" />,
            disableSortBy: true,
            accessor: (d: User) => {
              return d.school_classes?.map((sc: SchoolClass) => (
                <Text>{`${sc.name} (${sc.school_year?.name})`}</Text>
              ));
            },
          },
          {
            id: "roles",
            Header: <TableHeaderText text="Rollen" />,
            disableSortBy: true,
            accessor: (d: User) => {
              return d.roles?.map((r: Role) => <Text>{r.name}</Text>);
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <FancyTable
        queryKey={"users"}
        queryFn={fetchUsers}
        columns={columns}
        onDelete={openDeleteModal}
        onEdit={openEditModal}
      />
    </>
  );
}
