import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Student } from "../../../types";

export default function useMutateStudentData(
  mutationFn: (args: any) => Promise<unknown>,
  onSuccess: (values: Student) => void,
  onError: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload Student data
      queryClient.invalidateQueries(["students"]);

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
