import { Title } from "@mantine/core";
import PageTitle from "../../components/PageTitle";
import RoleTable from "../../components/tables/RoleTable";

export default function Roles() {
  return (
    <>
      <PageTitle title="Rollen" />
      <RoleTable />
    </>
  );
}
