import { ActionIcon, Button, createStyles, Group, Menu } from "@mantine/core";
import { IconChevronDown, IconTrash } from "@tabler/icons-react";
import { useContext } from "react";
import useDeleteAvatar from "../../hooks/avatar/useDeleteAvatar";
import useEditAvatar from "../../hooks/avatar/useEditAvatar";
import { UserContext } from "../../providers/user";

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white}`,
  },
}));

export default function UpdateAvatarButton() {
  const { classes } = useStyles();
  const { data: user } = useContext(UserContext);
  const { openModal: openEditAvatarModal } = useEditAvatar();
  const { openModal: openDeleteAvatarModal } = useDeleteAvatar();

  return (
    <Group noWrap spacing={0} sx={{ width: 300 }}>
      <Button
        fullWidth
        title="Nieuwe afbeelding uploaden"
        className={classes.button}
        onClick={openEditAvatarModal}
      >
        Afbeelding {user?.avatar ? "wijzigen" : "instellen"}
      </Button>
      <Menu transition="pop" position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="filled" color={"blue"} size={36} className={classes.menuControl}>
            <IconChevronDown size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openDeleteAvatarModal} icon={<IconTrash size={16} stroke={1.5} />}>
            Afbeelding verwijderen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
