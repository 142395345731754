import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableReport, Report } from "../../../../types";
import { createReport } from "../../../api/reports";
import useMutateReportData from "../../../hooks/report/useMutateReportData";
import ReportForm from "./ReportForm";

type CreateReportFormProps = {
  studentId: string;
  onClose: (values: Report) => void;
};

function CreateReportForm(props: CreateReportFormProps) {
  const { studentId, onClose } = props;

  const onSuccess = (values: Report) => {
    showNotification({
      title: "Verslag aangemaakt",
      icon: <IconCheck />,
      message: `Het verslag is aangemaakt.`,
    });

    onClose(values);
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van het verslag. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateReportData(createReport, onSuccess, onError);
  const onSubmit = (values: PatchableReport) => mutate(values);

  return (
    <>
      <ReportForm
        isLoading={isLoading}
        submitAction={(values) => onSubmit(values)}
        studentId={studentId}
      />
    </>
  );
}

export default CreateReportForm;
