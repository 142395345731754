import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Rating } from "../../../types";

export default function useMutateRatingData(
  mutationFn: (...args: any) => Promise<unknown>,
  onSuccess: (values: Rating) => void,
  onError: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload Report Template & Sections data
      queryClient.invalidateQueries(["report-templates"]);
      queryClient.invalidateQueries(["sections"]);

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
