import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import EditRatingForm from "../../components/forms/rating/EditRatingForm";

type openModalProps = {
  ratingId: string;
  reportTemplateId: string;
  sectionId: string;
};

export default function useEditRating(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = (props: openModalProps) => {
    const { ratingId, reportTemplateId, sectionId } = props;

    const modalId = modals.openModal({
      size: "xl",
      title: "Beoordelingspunt bewerken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <EditRatingForm
            reportTemplateId={reportTemplateId}
            sectionId={sectionId}
            ratingId={ratingId}
            onClose={() => closeModal(modalId, reportTemplateId)}
          />
        </>
      ),
    });
  };

  const closeModal = (id: string, reportTemplateId: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/report-templates/${reportTemplateId}`);
  };

  return {
    openModal,
  };
}
