import React, { useContext } from "react";
import { fetchSchoolYears } from "../../api/school-years";
import useDeleteSchoolYear from "../../hooks/school-year/useDeleteSchoolYear";
import useEditSchoolYear from "../../hooks/school-year/useEditSchoolYear";
import { UserContext } from "../../providers/user";
import TableHeaderText from "../TableHeaderText";
import FancyTable from "./FancyTable";

export default function SchoolYearTable() {
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteSchoolYear();
  const { openModal: openEditModal } = useEditSchoolYear();

  const columns = React.useMemo(
    () => [
      {
        id: "school_year-table",
        columns: [
          {
            id: "name",
            Header: <TableHeaderText text="Naam" />,
            accessor: "name",
          },
          {
            id: "active",
            Header: <TableHeaderText text="Actief" />,
            accessor: (d: any) => (d.active ? "Ja" : "Nee"),
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <FancyTable
        queryKey={"school-years"}
        queryFn={fetchSchoolYears}
        columns={columns}
        onDelete={isAuthorized("school_year_destroy") ? openDeleteModal : undefined}
        onEdit={isAuthorized("school_year_update") ? openEditModal : undefined}
      />
    </>
  );
}
