import { ActionIcon, Button, createStyles, Group, Menu } from "@mantine/core";
import { IconChevronDown, IconTemplate } from "@tabler/icons-react";
import useCreateReport from "../../hooks/report/useCreateReport";
import useCreateReportDiscrete from "../../hooks/report/useCreateReportDiscrete";

type CreateReportButtonProps = {
  studentId: string;
  period: number;
  fullName: string;
  year: string;
};

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },

  buttonContainer: {
    "& > button": {
      backgroundColor: "white",
      fontSize: 12,
    },

    "&:hover > button": {
      backgroundColor: "rgba(208, 235, 255, 0.65)",
    },
  },
}));

export default function CreateReportButton(props: CreateReportButtonProps) {
  const { studentId, period, fullName, year } = props;
  const { classes } = useStyles();
  const { openModal: openCreateReportModal } = useCreateReport(
    true,
    fullName,
    year
  );
  const { createDiscrete: createReportDiscrete } = useCreateReportDiscrete(
    true,
    fullName,
    year
  );

  return (
    <Group noWrap spacing={0} className={classes.buttonContainer}>
      <Button
        title="Verslag aanmaken met standaard template"
        className={classes.button}
        variant="light"
        onClick={() => createReportDiscrete({ studentId, period })}
      >
        Verslag maken
      </Button>
      <Menu transition="pop" position="bottom-end">
        <Menu.Target>
          <ActionIcon color={"blue"} size={36} className={classes.menuControl}>
            <IconChevronDown size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={() =>
              openCreateReportModal({
                studentId,
                period: period,
              })
            }
            icon={<IconTemplate size={16} stroke={1.5} />}
          >
            Aangepast template gebruiken
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
