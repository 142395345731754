import PageTitle from "../../components/PageTitle";
import UserTable from "../../components/tables/UserTable";

export default function Users() {
  return (
    <>
      <PageTitle title="Gebruikers" />
      <UserTable />
    </>
  );
}
