import { useForm } from "@mantine/form";
import { Button, Code, Group, Stack, Text, TextInput } from "@mantine/core";
import { Role } from "../../../../types";
import PermissionTransferList from "../../transferLists/PermissionTransferList";
import { validateString } from "../../../validator";

type RoleFormProps = {
  onSubmit: (values: any) => void;
  role?: Role;
  loading: boolean;
  submitText?: string;
};

export default function RoleForm(props: RoleFormProps) {
  const { onSubmit, role, loading, submitText } = props;

  const form = useForm<Role>({
    initialValues: {
      id: role?.id ?? 0,
      name: role?.name ?? "",
      permissions: role?.permissions,
    },
    validate: {
      name: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput data-autofocus label="Naam" required {...form.getInputProps("name")} />

        <PermissionTransferList
          onSelectedPermissionsChange={(values) => form.setFieldValue("permissions", values)}
          initialSelectedValues={role?.permissions}
        />

        <Text size="sm">
          Sommige rechten geven toegang tot andere rechten zodat de applicatie correct kan
          functioneren. Voorbeeld: als je iemand de rechten <Code>Update roles</Code> geeft, worden
          de rechten voor <Code>View roles</Code> automatisch toegewezen, ook als je deze niet hebt
          geselecteerd.
        </Text>

        <Group position="right">
          <Button type="submit" loading={loading}>
            {submitText || "Opslaan"}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
