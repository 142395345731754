import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { PatchableRating, Rating } from "../../../../types";
import { fetchRating, updateRating } from "../../../api/ratings";
import useMutateRatingData from "../../../hooks/rating/useMutateRatingData";
import RatingForm from "./RatingForm";

type EditRatingFormProps = {
  reportTemplateId: string;
  sectionId: string;
  ratingId: string;
  onClose: () => void;
};

function EditRatingForm(props: EditRatingFormProps) {
  const { reportTemplateId, sectionId, ratingId, onClose } = props;
  const [rating, setRating] = useState<Rating | undefined>();

  const onSuccess = (values: Rating) => {
    showNotification({
      title: "Beoordelingspunt bijgewerkt",
      icon: <IconCheck />,
      message: `Het beoordelingspunt ${values.name} is bijgewerkt`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van het beoordelingspunt. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateRatingData(updateRating, onSuccess, onError);

  const onSubmit = (values: PatchableRating) => {
    mutate({
      reportTemplateId,
      sectionId,
      rating: values,
    });
  };

  const onLoad = async () => {
    let data = await fetchRating({
      reportTemplateId,
      sectionId,
      ratingId,
    });

    setRating(data);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      {!rating ? (
        <Center>
          <Loader height={200} />
        </Center>
      ) : (
        <RatingForm
          isLoading={isLoading}
          rating={rating}
          submitAction={(values: PatchableRating) => onSubmit(values)}
        />
      )}
    </>
  );
}

export default EditRatingForm;
