import {API_URL, FETCH_HEADERS} from "../constants";
import {SortingRule} from "react-table";
import { generateSortString, handleRequestErrors } from "../tools";
import { SchoolClass } from "../../types";

export const fetchSchoolClasses = async (page: number = 0, pageSize: number = 20, sortByData: SortingRule<object>[] = [], query: string = "") => {
    return await fetch(`${API_URL}/school-class?page=${page + 1}&limit=${pageSize}${generateSortString(sortByData)}&q=${query}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
};

export const fetchSchoolClass = async (id: string) => {
    return await fetch(`${API_URL}/school-class/${id}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

export const createSchoolClass = async (schoolClass: SchoolClass) => {
    return await fetch(`${API_URL}/school-class`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(schoolClass)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const updateSchoolClass = async (schoolClass: SchoolClass) => {
    return await fetch(`${API_URL}/school-class/${schoolClass.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(schoolClass)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteSchoolClass = async (id: string) => {
    return await fetch(`${API_URL}/school-class/${id}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}
