import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { deleteRole } from "../../api/roles";
import useMutateRoleData from "./useMutateRoleData";

export default function useDeleteRole(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Rol verwijderd",
      icon: <IconCheck />,
      message: "De rol is verwijderd.",
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: "Er ging iets mis",
      color: "red",
      message: `Er is een fout opgetreden tijdens het verwijderen van de rol. ${error}`,
    });
  };

  const { mutate } = useMutateRoleData(deleteRole, onSuccess, onError);

  const onSubmit = async (modalId: string, id: string) => {
    modals.closeModal(modalId);
    navigateAway && navigate(`/admin/roles`);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Rol verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate(id);
  };

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je deze rol wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een rol te verwijderen uit het systeem. Dit is een permanente
            actie en kan niet ongedaan worden gemaakt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId, id)} color="red">
              Verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
