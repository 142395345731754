import {
  Anchor,
  Box,
  Button,
  createStyles,
  Flex,
  Group,
  LoadingOverlay,
  Overlay,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { Fragment, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { Report, Section } from "../../../types";
import useReportTemplateData from "../../hooks/report-template/useReportTemplateData";
import { readableDateTime } from "../../tools";
import ReportCommentInput from "../inputs/ReportCommentInput";
import ReportNameInput from "../inputs/ReportNameInput";
import PageTitle from "../PageTitle";
import ReportSection from "../ReportSection";
import ReportSectionContainer from "../ReportSectionContainer";

type ReportDetailProps = {
  report: Report;
  period: number;
};

const useStyles = createStyles((theme) => ({
  outerContainer: {
    position: "relative",
    width: "100%",
    gap: 16,
    display: "flex",
    flexDirection: "row",
  },
  scrollContainer: {
    display: "flex",
    gap: 16,
    height: "100%",
    overflowY: "auto",
    scrollSnapType: "x mandatory",
    flexGrow: 1,

    "& > *": {
      padding: "1rem",

      "&:nth-child(even)": {
        backgroundColor: "#F4F8FC",
      },
    },
  },
  scrollBlock: {
    flex: "0 0 400px",
    height: "100%",
    position: "relative",
    scrollSnapAlign: "start",
  },
  scrollNavigation: {
    backgroundColor: "#F4F8FC",
    overflowY: "auto",
    flex: "0 0 300px",
    height: "100%",
    padding: "2rem",
    boxShadow: "-10px 0px 15px rgba(0, 0, 0, 0.03)",
  },
  scrollNavigationItem: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#0A1E27",
  },
  scrollNavigationItemActive: {
    position: "relative",
    color: "#278ACA",

    "&::before": {
      content: '""',
      position: "absolute",
      left: -12,
      top: 0,
      bottom: 0,
      width: 4,
      backgroundColor: "#278ACA",
    },
  },
}));

export default function ReportSectionList(props: ReportDetailProps) {
  const { report, period } = props;
  const { classes, cx } = useStyles();
  const [charCount, setCharCount] = useState(report.comment?.length ?? 0);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const smallScreen = useMediaQuery("(max-width: 900px)");
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const {
    data: reportTemplate,
    isLoading,
    isError,
  } = useReportTemplateData(report.report_template_id);

  if (isError) {
    showNotification({
      title: "Er ging iets mis",
      message:
        "Er is een fout opgetreden bij het ophalen van het verslag template. Je bent teruggestuurd naar het leerlingenoverzicht.",
      autoClose: 10000,
      color: "red",
    });

    return <Navigate to={"/students"} />;
  }

  const sections: Section[] = reportTemplate?.sections ?? [];

  const ReportSubtitle = () => {
    if (period === 1 && report.period_1_finished_at) {
      return (
        <Text mt={5} size={"xs"} color={"dimmed"} mb={"md"}>
          Verslag periode {period} van {report?.student?.full_name} is afgerond door{" "}
          {report?.period_1_finished_by?.name} op {readableDateTime(report?.updated_at)}
        </Text>
      );
    }

    if (period === 2 && report.period_2_finished_at) {
      return (
        <Text mt={5} size={"xs"} color={"dimmed"} mb={"md"}>
          Verslag periode {period} van {report?.student?.full_name} is afgerond door{" "}
          {report?.period_2_finished_by?.name} op {readableDateTime(report?.updated_at)}
        </Text>
      );
    }

    return (
      <Text mt={5} size={"xs"} color={"dimmed"} mb={"md"}>
        Verslag periode {period} van {report?.student?.full_name} is voor het laatst bewerkt door{" "}
        {report?.updated_by?.name} op {readableDateTime(report?.updated_at)}
      </Text>
    );
  };

  const SCROLL_DISTANCE = 400 * period;
  const SCROLL_INDEX = sections?.findIndex((s) => s.id === activeSection) ?? 0;

  // Scroll states
  const [currentScroll, setCurrentScroll] = useState(0);
  const scrollPrevDisabled = currentScroll === 0;
  const scrollNextDisabled = activeSection === null;

  const sectionScrollDistance = (section: Section): number => {
    // Get the index of the section
    const index = sections.findIndex((s) => s.id === section.id) ?? 0;
    const scrollDistance = index * SCROLL_DISTANCE;
    const spacing = (index + 1) * 16 * period;

    return scrollDistance + spacing;
  };

  const onSectionScroll = (section: Section) => {
    scrollContainerRef?.current?.scrollTo({
      left: sectionScrollDistance(section),
      behavior: "smooth",
    });
  };

  const scrollToEnd = () => {
    scrollContainerRef?.current?.scrollTo({
      left: scrollContainerRef?.current?.scrollWidth,
      behavior: "smooth",
    });
  };

  const scrollToIndex = (index: number) => {
    let section = sections[index < 0 ? sections.length - 1 : index];

    if (!section) {
      scrollToEnd();
      return;
    }

    onSectionScroll(section);
  };

  const onScroll = () => {
    const scrollDistance = scrollContainerRef?.current?.scrollLeft ?? 0;

    // Update the current scroll state
    setCurrentScroll(scrollDistance);

    // Set the active section based on the scroll position
    const activeSection = sections.find((section) => {
      let sectionDistance = sectionScrollDistance(section);
      return scrollDistance <= sectionDistance;
    });

    setActiveSection(activeSection?.id ?? null);
  };

  useEffect(() => {
    scrollContainerRef?.current?.addEventListener("scroll", onScroll);

    return () => {
      scrollContainerRef?.current?.removeEventListener("scroll", onScroll);
    };
  });

  useEffect(() => {
    !isLoading && onScroll();
  }, [isLoading]);

  const isFinished = !!(period === 1 ? report.period_1_finished_at : report.period_2_finished_at);

  return (
    <>
      <ReportNameInput
        report={report}
        period={period}
        defaultValue={
          (period.toString() === "1" ? report?.name_1 : report?.name_2) ??
          `Verslag periode ${period}`
        }
      />

      <ReportSubtitle />

      <Box
        className={classes.outerContainer}
        sx={{
          flexGrow: 1,
        }}
      >
        <LoadingOverlay visible={isLoading} />
        <Box ref={scrollContainerRef} className={classes.scrollContainer}>
          {sections.map((section: Section) => (
            <Fragment key={section.id}>
              {period === 2 && (
                <Group className={classes.scrollBlock}>
                  <Overlay color={"#EFF6FB"} zIndex={4} />
                  <ReportSection
                    reportId={report.id}
                    section={section}
                    period={1}
                    title={`${section.name} - Periode 1`}
                    disableComment={true}
                  />
                </Group>
              )}
              <Group className={classes.scrollBlock}>
                {isFinished && <Overlay color="transparent" />}
                <ReportSection reportId={report.id} section={section} period={period} />
              </Group>
            </Fragment>
          ))}

          {!isLoading && (
            <Group className={classes.scrollBlock}>
              {isFinished && <Overlay color="transparent" />}
              <ReportSectionContainer>
                <Group position="apart">
                  <Title mb={"md"} order={3}>
                    Slotopmerking
                  </Title>
                  <Text>{charCount} / 1100</Text>
                </Group>

                <ReportCommentInput
                  placeholder={"Vul een slotopmerking in. Deze is hetzelfde voor beide periodes."}
                  report={report}
                  maxLength={1100}
                  onUpdate={(value: string) => setCharCount(value.length ?? 0)}
                />
              </ReportSectionContainer>
            </Group>
          )}
        </Box>
        {!smallScreen && (
          <Box className={classes.scrollNavigation}>
            <Flex
              sx={{
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Flex gap={16} direction={"column"}>
                <PageTitle title={"Ga naar"} />
                {sections.map((section: Section) => (
                  <Anchor
                    key={section.id}
                    onClick={() => onSectionScroll(section)}
                    className={cx(classes.scrollNavigationItem, {
                      [classes.scrollNavigationItemActive]: activeSection === section.id,
                    })}
                  >
                    {section.name}
                  </Anchor>
                ))}
                <Anchor
                  onClick={scrollToEnd}
                  className={cx(classes.scrollNavigationItem, {
                    [classes.scrollNavigationItemActive]: scrollNextDisabled,
                  })}
                >
                  Slotopmerking
                </Anchor>
              </Flex>
              <Flex gap={16}>
                <Button
                  onClick={() => scrollToIndex(SCROLL_INDEX - 1)}
                  disabled={scrollPrevDisabled}
                >
                  Vorige
                </Button>
                <Button
                  onClick={() => scrollToIndex(SCROLL_INDEX + 1)}
                  disabled={scrollNextDisabled}
                >
                  Volgende
                </Button>
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
    </>
  );
}
