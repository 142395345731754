import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Report } from "../../../types";

export default function useMutateReportData(
  mutationFn: (args: any) => Promise<unknown>,
  onSuccess?: (values: Report, variables?: any) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data, variables) => {
      // Reload Report & Student data
      queryClient.invalidateQueries(["reports"]);
      queryClient.invalidateQueries(["students"]);

      onSuccess?.(data as any, variables as any);
    },
    onError: (data) => {
      // Reload Report data (used for refetching the is_locked status and preventing double submits)
      queryClient.invalidateQueries(["reports"]);

      onError?.(data as any);
    },
  });
}
