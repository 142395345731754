import { AutocompleteItem, Button, Group } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableReport, Report, ReportTemplate } from "../../../../types";
import { fetchReportTemplates } from "../../../api/report-templates";
import { validateString } from "../../../validator";
import { FancySelect } from "../../selects/FancySelect";

type ReportFormProps = {
  studentId: string;
  report?: Report;
  submitText?: string;
  submitAction: (values: PatchableReport) => void;
  isLoading?: boolean;
};

function ReportForm(props: ReportFormProps) {
  const { studentId, report, submitText, submitAction, isLoading = false } = props;

  const form = useForm<PatchableReport>({
    initialValues: {
      id: report?.id ?? "",
      student_id: report?.student?.id ?? studentId,
      report_template_id: report?.report_template?.id ?? "",
    },

    validate: {
      student_id: (value) => validateString(value, "Een geldige student"),
      report_template_id: (value) => validateString(value, "Een geldig template"),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <FancySelect<ReportTemplate>
        data-autofocus
        error={form.errors.report_template_id ?? null}
        required
        pb={"sm"}
        label={"Template"}
        placeholder={"Zoek een template"}
        defaultValue={report?.report_template?.name}
        onClear={() => form.setFieldValue("report_template_id", "")}
        onItemSubmit={(item: AutocompleteItem) =>
          form.setFieldValue("report_template_id", item.data.id)
        }
        queryFn={(query: string) => fetchReportTemplates(0, 100, [], query)}
        labelRenderer={(item) => item.name}
      />

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default ReportForm;
