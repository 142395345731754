import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableSchoolClass, SchoolClass } from "../../../../types";
import { updateSchoolClass } from "../../../api/school-class";
import useMutateSchoolClassData from "../../../hooks/school-class/useMutateSchoolClassData";
import useSchoolClassData from "../../../hooks/school-class/useSchoolClassData";
import UserError from "../../UserError";
import SchoolClassForm from "./SchoolClassForm";

type EditSchoolClassFormProps = {
  schoolClassId: string;
  onClose: () => void;
};

function EditSchoolClassForm(props: EditSchoolClassFormProps) {
  const { schoolClassId, onClose } = props;
  const { data: schoolClass, isFetching, isError, error } = useSchoolClassData(schoolClassId);

  const onSuccess = (values: SchoolClass) => {
    showNotification({
      title: "Klas bijgewerkt",
      icon: <IconCheck />,
      message: `De klas ${values.name} is bijgewerkt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de klas. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading: isMutating } = useMutateSchoolClassData(
    updateSchoolClass,
    onSuccess,
    onError
  );

  const onSubmit = (values: PatchableSchoolClass) => mutate(values);

  const isLoading = isFetching || isMutating;

  if (isFetching)
    return (
      <Center>
        <Loader height={200} />
      </Center>
    );

  if (isError) return <UserError error={error as string} />;

  return (
    <SchoolClassForm
      isLoading={isLoading}
      schoolClass={schoolClass}
      submitAction={(values) => onSubmit(values)}
    />
  );
}

export default EditSchoolClassForm;
