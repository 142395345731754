import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportTemplate } from "../../../types";

export default function useMutateReportRatingData(
  mutationFn: (args: any) => Promise<unknown>,
  onSuccess?: (values: ReportTemplate) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload Report Rating data
      queryClient.invalidateQueries(["report-ratings"]);

      onSuccess?.(data as any);
    },
    onError: (data) => {
      // Reload Report data (used for refetching the is_locked status and preventing double submits)
      queryClient.invalidateQueries(["reports"]);

      onError?.(data as any);
    },
  });
}
