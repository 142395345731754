import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { deleteSection } from "../../api/sections";
import useMutateSectionData from "./useMutateSectionData";

type openModalProps = {
  sectionId: string;
  reportTemplateId: string;
};

export default function useDeleteSection(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Sectie verwijderd",
      icon: <IconCheck />,
      message: `De sectie is verwijderd.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het verwijderen van de sectie. ${error}`,
      color: "red",
    });
  };

  const { mutate } = useMutateSectionData(deleteSection, onSuccess, onError);

  const onSubmit = async (modalId: string, reportTemplateId: string, sectionId: string) => {
    modals.closeModal(modalId);
    navigateAway && navigate(`/report-templates/${reportTemplateId}`);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Sectie verwijderen",
      message: "Bezig met verwijderen ...",
    });

    await mutate({
      reportTemplateId,
      sectionId,
    });
  };

  const openModal = ({ reportTemplateId, sectionId }: openModalProps) => {
    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je deze sectie wilt verwijderen?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een sectie te verwijderen uit het systeem. Dit is een permanente
            actie en kan niet ongedaan worden gemaakt. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>Annuleren</Button>
            <Button onClick={() => onSubmit(modalId, reportTemplateId, sectionId)} color="red">
              Sectie verwijderen
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
