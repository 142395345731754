import { Loader, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { ChangeEventHandler, useState } from "react";
import { Report } from "../../../types";
import { updateReport } from "../../api/reports";
import useMutateReportData from "../../hooks/report/useMutateReportData";
import UnsavedIcon from "../icons/UnsavedIcon";

type ReportNameInputProps = {
  report: Report;
  period: number;
  defaultValue: string;
};

export default function ReportNameInput(props: ReportNameInputProps) {
  const { report, period, defaultValue } = props;
  const [name, setName] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const currentName = period === 1 ? report.name_1 : report.name_2;

  const onSuccess = () => {
    showNotification({
      title: "Verslag  bijgewerkt",
      icon: <IconCheck />,
      message: `Het verslag is bijgewerkt.`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van het verslag . ${error}`,
      color: "red",
    });
  };

  const { isLoading: isMutating, mutate } = useMutateReportData(updateReport, onSuccess, onError);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let value = event.currentTarget.value;
    setIsDirty(value !== currentName);
    setName(value);
  };

  const onBlur = () => {
    if (name && name !== currentName) {
      mutate({
        id: report.id,
        ...(period === 1 ? { name_1: name } : { name_2: name }),
      });
    }

    setIsDirty(false);
  };

  const rightSection = isDirty ? <UnsavedIcon /> : isMutating ? <Loader size={16} /> : null;

  return (
    <TextInput
      disabled={isMutating}
      size="lg"
      rightSection={rightSection}
      defaultValue={(period === 1 ? report.name_1 : report.name_2) ?? defaultValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
