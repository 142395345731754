import { Anchor, Center, createStyles, Group, Title } from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import VerifyEmailForm from "../../components/forms/auth/VerifyEmailForm";
import useResetPassword from "../../hooks/auth/useResetPassword";

const useStyles = createStyles((theme) => ({
  formContainer: {
    width: "100%",

    [theme.fn.largerThan("sm")]: {
      width: 500,
    },
  },
}));

function ResetPassword() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { loading, setFormData, onSubmit } = useResetPassword();

  return (
    <Center mb={"md"} sx={{ height: "100%", flexDirection: "column", gap: 16 }}>
      <Title>Wachtwoord opnieuw instellen</Title>

      <Group className={classes.formContainer}>
        <VerifyEmailForm setValues={setFormData} onSubmit={onSubmit} loading={loading} />
      </Group>

      <Anchor onClick={() => navigate("/login")}>
        <Group spacing={5}>
          <IconArrowNarrowLeft />
          Terug naar inloggen
        </Group>
      </Anchor>
    </Center>
  );
}

export default ResetPassword;
