import { Loader, TextInput, TextInputProps } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { ChangeEventHandler, useState } from "react";
import { Section } from "../../../types";
import { updateSection } from "../../api/sections";
import useMutateSectionData from "../../hooks/section/useMutateSectionData";
import UnsavedIcon from "../icons/UnsavedIcon";

type SectionNameProps = {
  reportTemplateId: string;
  section: Section;
} & TextInputProps;

export default function SectionNameInput(props: SectionNameProps) {
  const { reportTemplateId, section, ...restProps } = props;
  const [name, setName] = useState<string>();
  const [isDirty, setIsDirty] = useState(false);

  const onSuccess = (values: Section) => {
    showNotification({
      title: "Sectie bijgewerkt",
      icon: <IconCheck />,
      message: `De sectie ${values.name} is bijgewerkt.`,
    });
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de sectie. ${error}`,
      color: "red",
    });
  };

  const { isLoading: isMutating, mutate } = useMutateSectionData(updateSection, onSuccess, onError);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let value = event.currentTarget.value;
    setIsDirty(value !== section.name);
    setName(value);
  };

  const onBlur = () => {
    if (name && name !== section.name) {
      mutate({
        reportTemplateId,
        section: { ...section, ...{ name } },
      });

      setIsDirty(false);
    }
  };

  const rightSection = isDirty ? <UnsavedIcon /> : isMutating ? <Loader size={16} /> : null;

  return (
    <TextInput
      error={isDirty && !name}
      value={name}
      maxLength={255}
      disabled={isMutating}
      defaultValue={section.name}
      rightSection={rightSection}
      onChange={onChange}
      onBlur={onBlur}
      {...restProps}
    />
  );
}
