import { Tabs } from "@mantine/core";
import { IconCameraSelfie, IconUser } from "@tabler/icons-react";
import AccountSettingsTab from "../components/tabs/AccountSettingsTab";
import ProfileImageTab from "../components/tabs/ProfileImageTab";
import TwoFactorAuthTab from "../components/tabs/TwoFactorAuthTab";

export default function Profile() {
  return (
    <Tabs defaultValue="profile">
      <Tabs.List mb={"sm"}>
        <Tabs.Tab value="profile" icon={<IconUser />}>
          Accountgegevens
        </Tabs.Tab>
        <Tabs.Tab value="avatar" icon={<IconCameraSelfie />}>
          Profielafbeelding
        </Tabs.Tab>
        <Tabs.Tab value="2fa" icon={<IconUser />}>
          2FA
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="profile">
        <AccountSettingsTab />
      </Tabs.Panel>
      <Tabs.Panel value="avatar">
        <ProfileImageTab />
      </Tabs.Panel>
      <Tabs.Panel value="2fa">
        <TwoFactorAuthTab />
      </Tabs.Panel>
    </Tabs>
  );
}
