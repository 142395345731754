import { Anchor, Center, Container, Loader, Stack, Text } from "@mantine/core";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import AppIcon from "./components/icons/AppIcon";

export default function DefaultWrapper() {
  return (
    <Container sx={{ height: "100vh" }}>
      <Center sx={{ flexDirection: "column", gap: 16, height: "100%" }}>
        <Stack align={"center"}>
          <AppIcon />

          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>

          <Text size={"sm"} color={"dimmed"}>
            Ontwikkeld door{" "}
            <Anchor href={"https://webba.nl/"} target={"_blank"}>
              Webba
            </Anchor>
          </Text>
        </Stack>
      </Center>
    </Container>
  );
}
