import { ColorSwatch } from "@mantine/core";
import { IconCheck, IconFileText } from "@tabler/icons-react";

type StatusIconProps = {
  type?: "checkmark" | "unsaved";
};

export default function StatusIcon(props: StatusIconProps) {
  const { type = "checkmark" } = props;

  const colors = {
    checkmark: "#1ABA60",
    unsaved: "#FFA940",
  };

  return (
    <>
      {type === "unsaved" && <IconFileText color={colors[type]} size={16} />}
      {type !== "unsaved" && (
        <ColorSwatch color={colors[type]} size={16} withShadow={false}>
          {type === "checkmark" && <IconCheck color="white" size={12} />}
        </ColorSwatch>
      )}
    </>
  );
}
