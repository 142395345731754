import { createStyles, Loader, Select } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SchoolClass } from "../../../types";
import { fetchSchoolClasses } from "../../api/school-class";

const useStyles = createStyles((theme) => ({
  search: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function UserSchoolClassSelect() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState<SchoolClass[]>([]);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [query, setQuery] = useDebouncedState<string | null>(null, 200);

  const doSearch = () => {
    setIsFetching(true);

    fetchSchoolClasses(0, 100)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => doSearch(), []);

  useEffect(() => doSearch(), [query]);

  const onChange = (value: string) => navigate(`/school-classes/${value}`);

  if (isError) {
    return null;
  }

  return (
    <Select
      styles={{
        rightSection: {
          pointerEvents: "none",
        },
      }}
      className={classes.search}
      placeholder="Mijn klassen"
      searchable
      onSearchChange={setQuery}
      value={null}
      onChange={onChange}
      rightSection={isFetching ? <Loader size={16} /> : <IconChevronDown size={16} />}
      data={data?.map((schoolClass: SchoolClass) => ({
        label: schoolClass.name,
        value: schoolClass.id,
      }))}
    />
  );
}
