import { Text } from "@mantine/core";
import React from "react";
import { Report, SchoolClass } from "../../../../types";
import { fetchReports } from "../../../api/reports";
import { readableDateTime } from "../../../tools";
import ViewReportButton from "../../buttons/ViewReportButton";
import TableHeaderText from "../../TableHeaderText";
import FancyTable, { FancyTableProps } from "../FancyTable";
import ReportNameText from "./_reportNameText";

type ReportTableProps = Partial<FancyTableProps>;

export default function SimpleReportTable(props: ReportTableProps) {
  const columns = React.useMemo(
    () => [
      {
        id: "report-table",
        columns: [
          {
            id: "student.last_name",
            Header: <TableHeaderText text="Bestandsnaam" />,
            accessor: (d: Report) => <ReportNameText report={d} />,
          },
          {
            id: "period_1_finished_at",
            Header: <TableHeaderText text="Verslag periode 1" />,
            accessor: (d: Report) => {
              const fullName = d.student?.full_name;
              const year =
                d.student?.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return (
                <ViewReportButton
                  report={d}
                  period={1}
                  fullName={fullName}
                  year={year}
                />
              );
            },
          },
          {
            id: "period_2_finished_at",
            Header: <TableHeaderText text="Verslag periode 2" />,
            accessor: (d: Report) => {
              const fullName = d.student?.full_name;
              const year =
                d.student?.school_classes?.find(
                  (sc: SchoolClass) => sc.school_year?.active
                )?.school_year?.name ?? "";
              return (
                <ViewReportButton
                  report={d}
                  period={2}
                  fullName={fullName}
                  year={year}
                />
              );
            },
          },
          {
            id: "updated_at",
            Header: <TableHeaderText text="Datum" />,
            accessor: (d: Report) => (
              <Text>{readableDateTime(d.updated_at)}</Text>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <FancyTable
      {...props}
      queryKey={"reports"}
      queryFn={fetchReports}
      columns={columns}
    />
  );
}
