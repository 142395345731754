import PageTitle from "../../components/PageTitle";
import SchoolYearTable from "../../components/tables/SchoolYearTable";

export default function SchoolYears() {
  return (
    <>
      <PageTitle title="Schooljaren" />
      <SchoolYearTable />
    </>
  );
}
