import { Maybe, TableDataFilter } from "../types";
import { SortingRule } from "react-table";

/**
 * Get a environment variable.
 */
export function env(name: string, defaultValue: string = "") {
  return import.meta.env[`VITE_${name}`] || defaultValue;
}

/**
 * Test if localstorage has a token.
 */
export function hasAuthToken() {
  return Boolean(getAuthToken());
}

/**
 * Test if localstorage has a token.
 */
export function getAuthToken() {
  return localStorage.getItem("token");
}

/**
 * Set the authentication token in the local storage.
 */
export function setAuthToken(token: string) {
  localStorage.setItem("token", token);
}

/**
 * Remove authorization token from the local storage.
 */
export function removeAuthToken(): void {
  localStorage.removeItem("token");
}

export function generateSortString(sort: SortingRule<object>[]) {
  let columns = sort.map((s: SortingRule<object>) => `${s.desc ? "-" : ""}${s.id}`).join(",");

  return `&sort=${columns}`;
}

export function isEmptyArray(input: Maybe<any[]>) {
  return Array.isArray(input) && !input.length;
}

export function isArray(input: Maybe<any[]>) {
  return Array.isArray(input);
}

/**
 * Format errors from backend
 */
export function formatErrors(response: any) {
  if (response?.errors?.stacktrace) {
    delete response.errors.stacktrace;
  }

  let messages: Array<string> = [];

  response.message && messages.push(response.message);

  response.errors &&
    Object.values(response.errors).map((errors: any) =>
      Object.values(errors).map((err: any) => messages.push(err))
    );

  response.error && messages.push(response.error);

  return messages;
}

export async function handleRequestErrors(response: Response) {
  if (!response.ok) {
    let responseClone = response.clone();
    let errors;

    try {
      errors = formatErrors(JSON.parse(await responseClone.text()));
    } catch (err) {
      throw new Error(response.statusText);
    }

    if (!isEmptyArray(errors)) {
      throw new Error(errors[0]);
    }

    throw new Error("An unknown error occurred.");
  }

  return response;
}

export function readableDateTime(date: Maybe<string | Date>) {
  if (!date) {
    return "";
  }

  return new Date(date).toLocaleString();
}

export function generateFilterString(filters: TableDataFilter[]): string {
  let output = "";
  for (const filtersKey in filters) {
    if (typeof filters[filtersKey].value === "string") {
      output = output.concat(`&${filters[filtersKey].column}=${filters[filtersKey].value}`);
      continue;
    }

    let formattedArray = "";
    for (const arrayItem of filters[filtersKey].value) {
      formattedArray = `${formattedArray}&${filters[filtersKey].column}[]=${arrayItem}`;
    }

    output = output.concat(formattedArray);
  }

  return output;
}

export function pluralize(count: number, singular: string, plural: string) {
  return count === 1 ? singular : plural;
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
}
