import { SortingRule } from "react-table";
import { PatchableReport } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { generateSortString, handleRequestErrors } from "../tools";

export const fetchReports = async (
  page: number = 0,
  pageSize: number = 20,
  sortByData: SortingRule<object>[] = [],
  query: string = ""
) => {
  return await fetch(
    `${API_URL}/report?page=${page + 1}&limit=${pageSize}${generateSortString(
      sortByData
    )}&q=${query}`,
    {
      ...FETCH_HEADERS(),
    }
  )
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};

export const fetchReport = async (id: string) => {
  return await fetch(`${API_URL}/report/${id}`, {
    ...FETCH_HEADERS(),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export const createReport = async (report: PatchableReport) => {
  return await fetch(`${API_URL}/report`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify(report),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export const updateReport = async (report: PatchableReport) => {
  return await fetch(`${API_URL}/report/${report.id}`, {
    ...FETCH_HEADERS(),
    method: "PUT",
    body: JSON.stringify(report),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

export const deleteReport = async (id: string) => {
  return await fetch(`${API_URL}/report/${id}`, {
    ...FETCH_HEADERS(),
    method: "DELETE",
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};

type FinishReportProps = {
  reportId: string;
  period: string | number;
};

export const finishReport = async (values: FinishReportProps) => {
  const { reportId, period } = values;

  return await fetch(`${API_URL}/report/${reportId}/finish`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify({ period }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};

export const unblockReport = async (values: FinishReportProps) => {
  const { reportId, period } = values;

  return await fetch(`${API_URL}/report/${reportId}/unblock`, {
    ...FETCH_HEADERS(),
    method: "POST",
    body: JSON.stringify({ period }),
  })
    .then((data) => handleRequestErrors(data))
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      throw error.message;
    });
};
