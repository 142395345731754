import PageTitle from "../../components/PageTitle";
import SchoolClassTable from "../../components/tables/SchoolClassTable";

export default function SchoolClasses() {
  return (
    <>
      <PageTitle title="Klassen" />
      <SchoolClassTable />
    </>
  );
}
