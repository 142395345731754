import {
  Button,
  Checkbox,
  createStyles,
  Group,
  Input,
  TextInput,
  Select,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableSchoolYear, SchoolYear } from "../../../../types";
import { validateString } from "../../../validator";
import { DatePicker } from "@mantine/dates";

type SchoolYearFormProps = {
  schoolYear?: SchoolYear;
  submitText?: string;
  submitAction: (values: PatchableSchoolYear) => void;
  isLoading?: boolean;
};

const months = [
  { value: "januari", label: "januari" },
  { value: "februari", label: "februari" },
  { value: "maart", label: "maart" },
  { value: "april", label: "april" },
  { value: "mei", label: "mei" },
  { value: "juni", label: "juni" },
  { value: "juli", label: "juli" },
  { value: "augustus", label: "augustus" },
  { value: "september", label: "september" },
  { value: "oktober", label: "oktober" },
  { value: "november", label: "november" },
  { value: "december", label: "december" },
];

function SchoolYearForm(props: SchoolYearFormProps) {
  const { schoolYear, submitText, submitAction, isLoading = false } = props;

  const form = useForm<PatchableSchoolYear>({
    initialValues: {
      id: schoolYear?.id ?? "",
      name: schoolYear?.name ?? "",
      active: schoolYear?.active ?? false,
      first_period_start: schoolYear?.first_period_start ?? "september",
      first_period_end: schoolYear?.first_period_end ?? "december",
      second_period_start: schoolYear?.second_period_start ?? "januari",
      second_period_end: schoolYear?.second_period_end ?? "mei",
    },

    validate: {
      name: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput
        data-autofocus
        mb={"md"}
        required
        label={"Naam"}
        {...form.getInputProps("name")}
      />
      <Select
        label="Start periode 1"
        placeholder="Start periode 1"
        required
        mb={"md"}
        data={months}
        {...form.getInputProps("first_period_start")}
      />
      <Select
        label="Eind periode 1"
        placeholder="Eind periode 1"
        required
        mb={"md"}
        data={months}
        {...form.getInputProps("first_period_end")}
      />
      <Select
        label="Start periode 2"
        placeholder="Start periode 2"
        required
        mb={"md"}
        data={months}
        {...form.getInputProps("second_period_start")}
      />
      <Select
        label="Eind periode 2"
        placeholder="Eind periode 2"
        required
        mb={"md"}
        data={months}
        {...form.getInputProps("second_period_end")}
      />
      <Input.Wrapper label={"Actief"} mb={"md"}>
        <Checkbox
          label={"Activeer dit schooljaar"}
          {...form.getInputProps("active", { type: "checkbox" })}
        />
      </Input.Wrapper>
      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default SchoolYearForm;
