import { API_URL, FETCH_HEADERS } from "../constants";
import { getAuthToken, handleRequestErrors } from "../tools";

type ImportStudentProps = {
    classId: string;
    files: any;
}

export const importStudent = async (props: ImportStudentProps) => {
    const { classId, files } = props;

    const body = new FormData()

    for (const file of files) {
        body.append('files[]', file, file.name);
    }

    return await fetch(`${API_URL}/school-class/${classId}/import`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getAuthToken()}`,
        },
        body: body
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}
