import {
  Input,
  InputWrapperBaseProps,
  InputWrapperStylesNames,
  Loader,
  MultiSelect
} from "@mantine/core";
import { DefaultProps } from "@mantine/styles";
import { createRef, ReactNode, useEffect, useState } from "react";

type FancyMultiSelectProps<T> = DefaultProps<InputWrapperStylesNames> &
  InputWrapperBaseProps & {
    onSelectedValuesChange: (values: string[]) => void;
    defaultValue?: string[];
    queryFn: (...params: any) => any;
    placeholder?: string;
    labelRenderer: (value: T) => ReactNode;
    disabled?: boolean;
  };

export default function FancyMultiSelect<T extends { id?: number | string }>(
  props: FancyMultiSelectProps<T>
) {
  const {
    onSelectedValuesChange,
    queryFn,
    defaultValue = [],
    label,
    labelRenderer,
    placeholder = "",
    disabled = false,
    ...restProps
  } = props;
  const ref = createRef<HTMLInputElement>();
  const [data, setData] = useState([]);
  const [values, setValues] = useState<string[]>(defaultValue);
  const [loading, setLoading] = useState(true);

  const isDisabled = disabled || loading;

  /**
   * Event handler that is triggered when the selected values on the MultiSelect have been changed.
   * @param values
   */
  const onChange = (values: string[]) => {
    // Lose focus on the input field, so the dropdown closes.
    ref?.current?.blur();

    onSelectedValuesChange(values);
    setValues(values);
  };

  /**
   * Fetch the data, format it and update the data state.
   */
  const onLoad = async () => {
    const data = await queryFn();

    if (!data?.data) {
      return;
    }

    setData(
      data.data.map((value: T) => ({
        value: `${value.id}`,
        label: labelRenderer(value),
      }))
    );

    setLoading(false);
  };

  /**
   * Fetch data on mount.
   */
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Input.Wrapper label={label} {...restProps}>
      <MultiSelect
        ref={ref}
        data={data}
        value={values}
        onChange={onChange}
        searchable
        placeholder={placeholder}
        maxDropdownHeight={280}
        disabled={isDisabled}
        rightSection={loading ? <Loader size={16} /> : undefined}
      />
    </Input.Wrapper>
  );
}
