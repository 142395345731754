import { Anchor, Button, Menu } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useContext } from "react";
import { Maybe } from "../../../types";
import { UserContext } from "../../providers/user";

type AddButtonProps = {
  actions: AddButtonActionProps[];
};

type AddButtonActionProps = {
  label: string;
  action: Maybe<() => void>;
  permission?: string | string[];
};

export function AddButton({ actions }: AddButtonProps) {
  const { isAuthorized } = useContext(UserContext);
  let renderButton = false;

  const menuItems = actions.map((action, key) => {
    if (action.permission && !isAuthorized(action.permission)) {
      return null;
    }

    renderButton = true;

    return (
      <Menu.Item disabled={!action.action} key={key} onClick={() => action.action?.()}>
        <Anchor>{action.label}</Anchor>
      </Menu.Item>
    );
  });

  return renderButton ? (
    <Menu trigger="hover" exitTransitionDuration={0}>
      <Menu.Target>
        <Button disabled={menuItems.length < 1} p={5}>
          <IconPlus />
        </Button>
      </Menu.Target>
      {menuItems && <Menu.Dropdown>{menuItems}</Menu.Dropdown>}
    </Menu>
  ) : null;
}
