import { Anchor } from "@mantine/core";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReportTemplate } from "../../../types";
import { fetchReportTemplates } from "../../api/report-templates";
import useDeleteReportTemplate from "../../hooks/report-template/useDeleteReportTemplate";
import { UserContext } from "../../providers/user";
import DuplicateReportTemplateButton from "../buttons/DuplicateReportTemplateButton";
import TableHeaderText from "../TableHeaderText";
import FancyTable from "./FancyTable";

export default function ReportTemplateTable() {
  const navigate = useNavigate();
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openDeleteModal } = useDeleteReportTemplate();

  const columns = React.useMemo(
    () => [
      {
        id: "report-template-table",
        columns: [
          {
            id: "name",
            Header: <TableHeaderText text="Naam" />,
            accessor: (d: ReportTemplate) => (
              <Anchor component={Link} to={`/report-templates/${d.id}`}>
                {d.name}
              </Anchor>
            ),
          },
          {
            id: "sections",
            Header: "Aantal secties",
            accessor: "sections_count",
            disableSortBy: true,
          },
        ],
      },
    ],
    []
  );

  const onDetails = (id: string) => navigate(`/report-templates/${id}`);

  const additionalActionsRenderer = (reportTemplate: ReportTemplate) => (
    <>
      {isAuthorized("report_template_duplicate") && (
        <DuplicateReportTemplateButton reportTemplateId={reportTemplate.id} />
      )}
    </>
  );

  return (
    <>
      <FancyTable
        queryKey={"report-templates"}
        queryFn={fetchReportTemplates}
        columns={columns}
        onDelete={isAuthorized("report_template_destory") ? openDeleteModal : undefined}
        onEdit={onDetails}
        additionalActionsRenderer={additionalActionsRenderer}
      />
    </>
  );
}
