import { Anchor, Center, createStyles, Group, Text, Title } from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import PasswordForgottenForm from "../../components/forms/auth/PasswordForgottenForm";

const useStyles = createStyles((theme) => ({
  formContainer: {
    width: "100%",

    [theme.fn.largerThan("sm")]: {
      width: 500,
    },
  },
}));

function PasswordForgotten() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Center mb={"md"} sx={{ height: "100%", flexDirection: "column", gap: 16 }}>
      <Title>Wachtwoord vergeten</Title>
      <Group className={classes.formContainer}>
        <PasswordForgottenForm />
      </Group>

      <Anchor onClick={() => navigate("/login")}>
        <Group spacing={5}>
          <IconArrowNarrowLeft />
          Terug naar inloggen
        </Group>
      </Anchor>
    </Center>
  );
}

export default PasswordForgotten;
