import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableUser, User } from "../../../../types";
import { createUser } from "../../../api/users";
import useMutateUserData from "../../../hooks/user/useMutateUserData";
import UserForm from "./UserForm";

type CreateUserFormProps = {
  onClose: () => void;
};

function CreateUserForm(props: CreateUserFormProps) {
  const { onClose } = props;

  const onSuccess = (values: User) => {
    showNotification({
      title: "Gebruiker aangemaakt",
      icon: <IconCheck />,
      message: `Gebruiker ${values.name} is aangemaakt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van de gebruiker. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateUserData(createUser, onSuccess, onError);

  const onSubmit = (values: PatchableUser) => mutate(values);

  return (
    <>
      <UserForm isLoading={isLoading} submitAction={(values) => onSubmit(values)} />
    </>
  );
}

export default CreateUserForm;
