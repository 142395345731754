import { Button, Checkbox, Group, Input, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableRating, Rating } from "../../../../types";
import { RATING_TYPES } from "../../../constants";
import { validateString } from "../../../validator";

type RatingFormProps = {
  rating?: Rating;
  submitText?: string;
  submitAction: (values: PatchableRating) => void;
  isLoading?: boolean;
};

function RatingForm(props: RatingFormProps) {
  const { rating, submitText, submitAction, isLoading = false } = props;

  const form = useForm<PatchableRating>({
    initialValues: {
      id: rating?.id ?? "",
      name: rating?.name ?? "",
      order: rating?.order ?? null,
      type: rating?.type ?? RATING_TYPES[0]?.value ?? "",
      required: rating?.required ?? true,
      period_1: rating?.period_1 ?? true,
      period_2: rating?.period_2 ?? true,
    },

    validate: {
      name: (value) => validateString(value),
      type: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput data-autofocus mb={"md"} required label="Naam" {...form.getInputProps("name")} />

      <Select
        mb={"md"}
        required
        label="Type"
        placeholder="Kies een type"
        data={RATING_TYPES}
        {...form.getInputProps("type")}
      />

      <Input.Wrapper label={"Periode 1"} mb={"md"}>
        <Checkbox
          label={"Activeer dit beoordelingspunt voor periode 1"}
          {...form.getInputProps("period_1", { type: "checkbox" })}
        />
      </Input.Wrapper>

      <Input.Wrapper label={"Periode 2"} mb={"md"}>
        <Checkbox
          label={"Activeer dit beoordelingspunt voor periode 2"}
          {...form.getInputProps("period_2", { type: "checkbox" })}
        />
      </Input.Wrapper>

      <Input.Wrapper label={"Verplicht beoordelingspunt"} mb={"md"}>
        <Checkbox
          label={"Maak dit beoordelingspunt verplicht"}
          {...form.getInputProps("required", { type: "checkbox" })}
        />
      </Input.Wrapper>

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default RatingForm;
