import { Group } from "@mantine/core";
import AppIcon from "./icons/AppIcon";

export function Footer() {
  return (
    <Group position={"center"} py={"md"}>
      <AppIcon hideText={false} />
    </Group>
  );
}
