import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import EditSchoolYearForm from "../../components/forms/school-year/EditSchoolYearForm";

export default function useEditSchoolYear(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = (id: string) => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Schooljaar bewerken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <EditSchoolYearForm schoolYearId={id} onClose={() => closeModal(modalId)} />
        </>
      ),
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
    navigateAway && navigate("/admin/school-years");
  };

  return {
    openModal,
  };
}
