import { useModals } from "@mantine/modals";
import EditAvatarForm from "../../components/forms/avatar/EditAvatarForm";

export default function useEditAvatar() {
  const modals = useModals();

  const openModal = () => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Avatar bijwerken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <>
          <EditAvatarForm onClose={() => closeModal(modalId)} />
        </>
      ),
    });
  };

  const closeModal = (id: string) => {
    modals.closeModal(id);
  };

  return {
    openModal,
  };
}
