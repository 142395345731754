import { Center, Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableStudent, Student } from "../../../../types";
import { updateStudent } from "../../../api/students";
import useMutateStudentData from "../../../hooks/student/useMutateStudentData";
import useStudentData from "../../../hooks/student/useStudentData";
import UserError from "../../UserError";
import StudentForm from "./StudentForm";

type EditStudentFormProps = {
  studentId: string;
  onClose: () => void;
};

function EditStudentForm(props: EditStudentFormProps) {
  const { studentId, onClose } = props;

  const { data: student, isFetching, isError, error } = useStudentData(studentId);

  const onMutateSuccess = (values: Student) => {
    showNotification({
      title: "Leerling bijgewerkt",
      icon: <IconCheck />,
      message: `De leerling ${values.full_name} is bijgewerkt.`,
    });

    onClose();
  };

  const onMutateError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van de leerling. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading: isMutating } = useMutateStudentData(
    updateStudent,
    onMutateSuccess,
    onMutateError
  );

  const onSubmit = (values: PatchableStudent) => {
    mutate(values);
  };

  if (isFetching) {
    return (
      <Center>
        <Loader height={200} />
      </Center>
    );
  }

  if (isError) {
    return <UserError error={error as string} />;
  }

  return (
    <StudentForm
      isLoading={isMutating}
      student={student}
      submitAction={(values) => onSubmit(values)}
    />
  );
}

export default EditStudentForm;
