import { Box, Group, Input, LoadingOverlay, ScrollArea, Text } from "@mantine/core";
import { useState } from "react";
import { Rating, Section } from "../../../../types";
import useReportRatingData from "../../../hooks/report-rating/useReportTemplateData";
import SectionCommentInput from "../../inputs/SectionCommentInput";
import SectionRatingListItem from "./_listItem";

type SectionRatingListProps = {
  reportId: string;
  period: number;
  section: Section;
  ratings: Rating[];
  disableComment?: boolean;
};

export default function SectionRatingList(props: SectionRatingListProps) {
  const { reportId, section, period, disableComment = false } = props;
  const [charCount, setCharCount] = useState(section.comment?.length ?? 0);
  const { data, isFetching } = useReportRatingData(reportId, section.id, period.toString());

  const ratings: Rating[] = section?.ratings ?? [];

  return (
    <ScrollArea offsetScrollbars sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          gap: 16,
          height: "100%",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <LoadingOverlay visible={isFetching} />

        {ratings.map((rating: Rating) => (
          <SectionRatingListItem
            key={rating.id}
            reportId={reportId}
            rating={rating}
            period={period}
            defaultValue={data?.find((item: any) => item.rating_id === rating.id)?.value}
          />
        ))}

        {!disableComment && (
          <Input.Wrapper
            sx={{ label: { width: "100%" } }}
            label={
              <Group position="apart">
                <Text>Opmerking</Text>
                <Text>{charCount} / 300</Text>
              </Group>
            }
          >
            <SectionCommentInput
              onUpdate={(value: string) => setCharCount(value?.length ?? 0)}
              reportId={reportId}
              sectionId={section.id}
              maxLength={300}
            />
          </Input.Wrapper>
        )}
      </Box>
    </ScrollArea>
  );
}
