import { useModals } from "@mantine/modals";
import EditRoleForm from "../../components/forms/role/EditRoleForm";

export default function useEditRole() {
  const modals = useModals();

  const openModal = (roleId: string) => {
    const modalId = modals.openModal({
      size: "xl",
      title: "Rol bewerken",
      closeOnClickOutside: false,
      children: (
        <>
          <EditRoleForm onClose={() => closeModal(modalId)} roleId={roleId} />
        </>
      ),
    });
  };

  const closeModal = (modalId: string) => {
    modals.closeModal(modalId);
  };

  return {
    openModal,
  };
}
