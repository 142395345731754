import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import useEnableTwoFactorAuth from "../../../hooks/auth/2fa/useMutateTwoFactorAuth";
import { validateString } from "../../../validator";
import { setupTwoFactorAuth } from "../../../api/two-factor-auth";

type EnableTwoFactorAuthFormProps = {
  twoFactorSecret: string;
};

export default function EnableTwoFactorAuthForm(props: EnableTwoFactorAuthFormProps) {
  const { twoFactorSecret } = props;
  const { mutate, isLoading } = useEnableTwoFactorAuth(setupTwoFactorAuth);

  const form = useForm({
    initialValues: {
      otp: "",
    },
    validate: {
      otp: (value) => validateString(value, "OTP-code"),
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        mutate({ ...values, two_factor_secret: twoFactorSecret })
      )}
      style={{ width: "100%" }}
    >
      <TextInput
        required
        disabled={isLoading}
        label="OTP-code"
        placeholder="123456"
        type="number"
        mb={"md"}
        {...form.getInputProps("otp")}
      />

      <Button fullWidth disabled={!form.isValid("otp")} type="submit">
        Verstuur
      </Button>
    </form>
  );
}
