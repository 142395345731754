import { Stack, Text } from "@mantine/core";

type TableCellTextProps = {
  text: string;
  subtext?: string;
};

export default function TableCellText(props: TableCellTextProps) {
  const { text, subtext } = props;

  if (!text) return null;

  return (
    <Stack spacing={5}>
      <Text fw={"bold"}>{text}</Text>
      {subtext && (
        <Text size={"xs"} color={"dimmed"}>
          {subtext}
        </Text>
      )}
    </Stack>
  );
}
