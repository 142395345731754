import { Group, Text, useMantineTheme } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck, IconFileText, IconUpload, IconX } from "@tabler/icons-react";
import { updateAvatar } from "../../../api/avatar";
import useMutateAvatarData from "../../../hooks/avatar/useMutateAvatarData";

type EditAvatarFormProps = {
  onClose: () => void;
};

function EditAvatarForm(props: EditAvatarFormProps) {
  const { onClose, ...restProps } = props;
  const theme = useMantineTheme();

  const onSuccess = (res: any) => {
    cleanNotifications();

    showNotification({
      title: "Avatar bijgewerkt",
      icon: <IconCheck />,
      message: `Je avatar is bijgewerkt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het bijwerken van je avatar. ${error}`,
      color: "red",
      autoClose: 15000,
    });
  };

  const { mutate, isLoading } = useMutateAvatarData(updateAvatar, onSuccess, onError);

  const onSubmit = (values: any) => {
    showNotification({
      disallowClose: true,
      loading: true,
      title: "Avatar bijwerken",
      message: "Bezig met bijwerken ...",
    });

    mutate({ file: values[0] });
  };

  const onReject = () => onError("Het bestand voldoet niet aan de vereisten.");

  return (
    <>
      <Dropzone
        loading={isLoading}
        onDrop={onSubmit}
        onReject={onReject}
        maxSize={2 * 1024 ** 2}
        {...restProps}
      >
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: "none" }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileText size={50} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Sleep hier je bestand of klik hier om een bestand te selecteren
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Je kunt alleen afbeeldingen uploaden met een maximale grootte van 2MB.
            </Text>
          </div>
        </Group>
      </Dropzone>
    </>
  );
}

export default EditAvatarForm;
