import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import CreateSectionForm from "../../components/forms/section/CreateSectionForm";

type openModalProps = {
  reportTemplateId: string;
};

export default function useCreateSection(navigateAway: boolean = false) {
  const modals = useModals();
  const navigate = useNavigate();

  const openModal = (props: openModalProps) => {
    const { reportTemplateId } = props;

    const modalId = modals.openModal({
      size: "xl",
      title: "Sectie aanmaken",
      closeOnClickOutside: false,
      centered: true,
      children: (
        <CreateSectionForm
          reportTemplateId={reportTemplateId}
          onClose={() => closeModal(modalId, reportTemplateId)}
        />
      ),
    });
  };

  const closeModal = (id: string, reportTemplateId: string) => {
    modals.closeModal(id);
    navigateAway && navigate(`/report-templates/${reportTemplateId}`);
  };

  return {
    openModal,
  };
}
