import { Button } from "@mantine/core";
import { IconFiles } from "@tabler/icons-react";
import useDuplicateReportTemplate from "../../hooks/report-template/useDuplicateReportTemplate";

type DuplicateReportTemplateButtonProps = {
  reportTemplateId: string;
};

export default function DuplicateReportTemplateButton(props: DuplicateReportTemplateButtonProps) {
  const { reportTemplateId } = props;
  const { openModal: openDuplicateReportTemplateModal } = useDuplicateReportTemplate();

  return (
    <Button
      title="Verslag template dupliceren"
      onClick={() => openDuplicateReportTemplateModal(reportTemplateId)}
    >
      <IconFiles />
    </Button>
  );
}
