import { AutocompleteItem, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableSchoolClass, ReportTemplate, SchoolClass, SchoolYear } from "../../../../types";
import { fetchReportTemplates } from "../../../api/report-templates";
import { fetchSchoolYears } from "../../../api/school-years";
import { validateString } from "../../../validator";
import { FancySelect } from "../../selects/FancySelect";

type SchoolClassFormProps = {
  schoolClass?: SchoolClass;
  submitText?: string;
  submitAction: (values: PatchableSchoolClass) => void;
  isLoading?: boolean;
};

function SchoolClassForm(props: SchoolClassFormProps) {
  const { schoolClass, submitText, submitAction, isLoading = false } = props;

  const form = useForm<PatchableSchoolClass>({
    initialValues: {
      id: schoolClass?.id ?? "",
      name: schoolClass?.name ?? "",
      school_year_id: schoolClass?.school_year?.id ?? "",
      report_template_id: schoolClass?.report_template?.id ?? "",
    },

    validate: {
      name: (value) => validateString(value),
      school_year_id: (value) => validateString(value, "Een geldig schooljaar"),
      report_template_id: (value) => validateString(value, "Een geldig verslag template"),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput data-autofocus mb={"md"} required label={"Naam"} {...form.getInputProps("name")} />

      <FancySelect<SchoolYear>
        error={form.errors.school_year_id ?? null}
        required
        pb={"sm"}
        label={"Schooljaar"}
        placeholder={"Zoek een schooljaar"}
        defaultValue={schoolClass?.school_year?.name}
        onClear={() => form.setFieldValue("school_year_id", "")}
        onItemSubmit={(item: AutocompleteItem) =>
          form.setFieldValue("school_year_id", item.data.id)
        }
        queryFn={(query: string) => fetchSchoolYears(0, 100, [], query)}
        labelRenderer={(item) => item.name}
      />

      <FancySelect<ReportTemplate>
        error={form.errors.report_template_id ?? null}
        required
        pb={"sm"}
        label={"Standaard template"}
        placeholder={"Zoek een template"}
        defaultValue={schoolClass?.report_template?.name}
        onClear={() => form.setFieldValue("report_template_id", "")}
        onItemSubmit={(item: AutocompleteItem) =>
          form.setFieldValue("report_template_id", item.data.id)
        }
        queryFn={(query: string) => fetchReportTemplates(0, 100, [], query)}
        labelRenderer={(item) => item.name}
      />

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default SchoolClassForm;
