import { Stack } from "@mantine/core";
import useSetupTwoFactorAuth from "../../../hooks/auth/2fa/useSetupTwoFactorAuth";
import QRCode from "react-qr-code";
import { useContext } from "react";
import { UserContext } from "../../../providers/user";
import EnableTwoFactorAuthForm from "../../forms/auth/EnableTwoFactorAuthForm";
import { FullPageSpinner } from "../../FullPageSpinner";
import { Text } from "@mantine/core";

export default function TwoFactorAuthDisabled() {
  const { data, isLoading, error } = useSetupTwoFactorAuth();
  const { data: user } = useContext(UserContext);

  return (
    <Stack>
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <>
          <Text>2FA uitgeschakeld</Text>
          <QRCode
            value={`otpauth://totp/MVH:%20${encodeURIComponent(user?.email ?? "")}?secret=${
              data.two_factor_secret ?? ""
            }&issuer=MVH`}
          />
          <EnableTwoFactorAuthForm twoFactorSecret={data.two_factor_secret} />
        </>
      )}
    </Stack>
  );
}
