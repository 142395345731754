import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useState } from "react";
import { API_URL } from "../../constants";
import { formatErrors, isEmptyArray } from "../../tools";
import useLazyFetch from "../useLazyFetch";

/**
 * Hook for handling email verification
 */
export default function usePasswordForgotten() {
  const [formData, setFormData] = useState({
    email: "",
    email_confirmation: "",
  });

  const { doFetch, loading } = useLazyFetch({
    url: `${API_URL}/auth/password-request`,
    options: {
      method: "POST",
      body: JSON.stringify(formData),
    },
  });

  const onSubmit = async () => {
    const res = await doFetch();
    const errors = formatErrors(res);

    if (!isEmptyArray(errors)) {
      showNotification({
        title: `Er ging iets mis`,
        message: `We konden geen wachtwoord herstel e-mail naar je sturen. ${errors[0]}`,
        color: "red",
        autoClose: 10000,
      });

      return;
    }

    showNotification({
      title: "Wachtwoord herstel e-mail verzonden",
      icon: <IconCheck />,
      message: `Er is een e-mail naar je gestuurd met instructies om je wachtwoord te herstellen. Kijk in je spambox als je deze niet binnen 5 minuten hebt ontvangen.`,
      autoClose: 30000,
    });
  };

  return {
    onSubmit,
    setFormData,
    loading,
  };
}
