import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  IconEye,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  HeaderGroup,
  SortingRule,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { TableDataFilter } from "../../../types";
import { SearchContext } from "../../providers/search";
import UserError from "../UserError";
import { useSearchParams } from "react-router-dom";

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

// @ts-ignore
const reducer = (state: any, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const useStyles = createStyles((theme) => ({
  tableDataCell: {
    whiteSpace: "nowrap",
  },
  table: { borderCollapse: "separate", borderSpacing: "0 15px" },
  tableCell: {
    border: "none !important",
  },
  tableCellHidden: {
    visibility: "hidden",
  },
  baseTableBody: {
    tr: {
      "td, th": {
        padding: "1rem !important",
      },
    },
  },
  simpleTableBody: {
    tr: {
      "&:nth-child(even):not(:last-child) > td": {
        background: "#FAFAFA",
      },

      "&:nth-child(odd):not(:last-child) > td": {
        background: "#F5F9FF",
      },

      "&:hover > td": {
        background: "#DCEDFA !important",
      },

      // Add border radius to the first child
      "& > td:first-child": {
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      },

      // Add border radius to the last child
      "& > td:last-child": {
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
      },
    },
  },
  extendedTableBody: {
    tr: {
      "&:nth-child(even) > td:not(:last-child)": {
        background: "#FAFAFA",
      },

      "&:nth-child(odd) > td:not(:last-child)": {
        background: "#F5F9FF",
      },

      // Add border radius to the first child
      "& > td:first-child": {
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      },

      // Add border radius to the second-last child
      "& > td:nth-last-child(2)": {
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
      },

      // Remove padding from last child
      "& > td:last-child": {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "inherit",
      },

      "&:hover > td": {
        "&:not(:last-child)": {
          background: "#DCEDFA !important",
        },

        "&:last-child": {
          visibility: "visible",
        },
      },
    },
  },

  actionsCell: {
    width: 1,
  },

  tableRow: {
    height: 1,

    // Apply border radius of 20px to the first child and the second last child
    "&:first-child": {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },

    "&:last-child": {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
  },

  buttonContainer: {
    "& > button": {
      width: 75,
      height: 75,
    },
  },

  deleteButton: {
    backgroundColor: "#F7F7F7",

    "&:hover": {
      backgroundColor: "#F7F7F7",
    },
  },
}));

export type FancyTableProps = {
  queryKey: string;
  queryFn: (
    queryPageIndex: number,
    queryPageSize: number,
    sortByState: SortingRule<object>[],
    ...args: any[]
  ) => any;
  queryPageSize?: number;
  columns: any;
  filters?: TableDataFilter[];

  disableSortBy?: boolean;

  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onDetails?: (id: string) => void;
  additionalActionsRenderer?: (row: any) => ReactNode;
};

function FancyTable(props: FancyTableProps) {
  const [sortByState, setSortByState] = useState<SortingRule<object>[]>([]);
  const { debouncedQuery } = useContext(SearchContext);
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const {
    queryKey,
    queryFn,
    queryPageSize: queryPageSizeProps = 10,
    columns,
    additionalActionsRenderer,
    onEdit,
    onDelete,
    onDetails,
    disableSortBy = false,
    filters = [],
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = parseInt(searchParams.get(queryKey + "_page") ?? "1") - 1;

  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] = useReducer(
    reducer,
    {
      queryPageIndex: currPage,
      queryPageSize: queryPageSizeProps,
      totalCount: null,
    }
  );

  const {
    status,
    isError,
    error,
    isFetching,
    isSuccess,
    data: originalData,
  } = useQuery(
    [
      queryKey,
      queryPageIndex,
      queryPageSize,
      sortByState,
      debouncedQuery,
      filters,
    ],
    () =>
      queryFn(
        queryPageIndex,
        queryPageSize,
        sortByState,
        debouncedQuery,
        filters
      ),
    { keepPreviousData: true }
  );

  const data = React.useMemo(() => {
    return isSuccess ? originalData : { data: [], meta: { total: 0 } };
  }, [originalData]);

  const isLoading = status === "loading" || isFetching;
  const hasActions =
    onDetails || onEdit || onDelete || additionalActionsRenderer;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data.data,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: sortByState,
      },
      // Tell the usePagination hook that we'll handle our own data fetching
      // This means we'll also have to provide our own pageCount.
      manualPagination: true,
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : undefined,

      // Sorting
      manualSortBy: true,
      disableSortBy: disableSortBy,

      // Row checkboxes
      getRowId: (row: any, index) => row.id,
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetRowState: false,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const currPage = parseInt(searchParams.get(queryKey + "_page") ?? "1") - 1;
    if (currPage !== queryPageIndex) {
      dispatch({ type: PAGE_CHANGED, payload: currPage });
      gotoPage(currPage);
    }
  }, [searchParams, queryKey, queryPageIndex]);

  // Update sortBy state and goto page zero on sortBy update
  useEffect(() => {
    setSortByState(sortBy);
  }, [sortBy]);

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
  }, [pageSize]);

  useEffect(() => {
    if (data?.meta?.total) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.meta.total,
      });
    }
  }, [data?.meta?.total]);

  const isSortable = (column: HeaderGroup<any>) =>
    !disableSortBy && !column.disableSortBy;

  const onDeleteRow = (id: string) => onDelete && onDelete(id);

  const onChangePageHandler = (page: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      [queryKey + "_page"]: page.toString(),
    });
    if (!isLoading) gotoPage(page - 1);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <LoadingOverlay overlayColor={"#EFF6FB"} visible={isLoading} />
        {isSuccess && (
          <ScrollArea>
            <Table
              verticalSpacing={0}
              className={classes.table}
              {...getTableProps()}
            >
              <thead className={classes.baseTableBody}>
                {headerGroups.map((headerGroup, index) => {
                  const { key, ...restProps } =
                    headerGroup.getHeaderGroupProps();
                  return (
                    index > 0 && (
                      <tr key={key} {...restProps}>
                        {headerGroup.headers.map((column) => {
                          const { key, ...restColumn } = column.getHeaderProps(
                            column.getSortByToggleProps()
                          );
                          return (
                            (column.parent !== undefined ||
                              column.id === "selection" ||
                              column.id === "expander") && (
                              <th
                                key={key}
                                className={classes.tableCell}
                                {...restColumn}
                              >
                                <Group noWrap spacing={5}>
                                  <Text
                                    size={"sm"}
                                    color={"#004877"}
                                    styles={{
                                      root: {
                                        whiteSpace: "nowrap",
                                      },
                                    }}
                                  >
                                    {column.render("Header")}
                                  </Text>
                                  {isSortable(column) && (
                                    <Group spacing={3}>
                                      <IconChevronDown
                                        size={12}
                                        color={
                                          column.isSorted &&
                                          !column.isSortedDesc
                                            ? "#3B7DBE"
                                            : "#ABBBC7"
                                        }
                                      />
                                      <IconChevronUp
                                        size={12}
                                        color={
                                          column.isSorted && column.isSortedDesc
                                            ? "#3B7DBE"
                                            : "#ABBBC7"
                                        }
                                      />
                                    </Group>
                                  )}
                                </Group>
                              </th>
                            )
                          );
                        })}
                        {headerGroup.headers.length > 1 && hasActions && (
                          <th className={classes.tableCell}></th>
                        )}
                      </tr>
                    )
                  );
                })}
              </thead>
              <tbody
                className={cx(
                  classes.baseTableBody,
                  hasActions
                    ? classes.extendedTableBody
                    : classes.simpleTableBody
                )}
                {...getTableBodyProps()}
              >
                {page.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  return (
                    <React.Fragment key={key}>
                      <tr
                        {...restRowProps}
                        className={classes.tableRow}
                        style={{ borderRadius: 20 }}
                      >
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();
                          return (
                            <td
                              key={key}
                              {...restCellProps}
                              className={cx(
                                classes.tableDataCell,
                                classes.tableCell
                              )}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        {hasActions && (
                          <td
                            className={cx(
                              classes.actionsCell,
                              classes.tableCell,
                              classes.tableCellHidden
                            )}
                          >
                            <Group
                              position="left"
                              noWrap
                              className={classes.buttonContainer}
                            >
                              {additionalActionsRenderer &&
                                additionalActionsRenderer(row)}
                              {onDetails && (
                                <Button onClick={() => onDetails(row.id)}>
                                  <IconEye />
                                </Button>
                              )}
                              {onEdit && (
                                <Button onClick={() => onEdit(row.id)}>
                                  <IconPencil />
                                </Button>
                              )}
                              {onDelete && (
                                <Button
                                  className={classes.deleteButton}
                                  variant="subtle"
                                  onClick={() => onDeleteRow(row.id)}
                                >
                                  <IconTrash color={theme.colors.blue[0]} />
                                </Button>
                              )}
                            </Group>
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td colSpan={100} style={{ border: "none" }}>
                    <Text>
                      {page.length} van {data.meta.total} resultaten worden
                      getoond
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ScrollArea>
        )}
        {isError && <UserError error={error as string} />}
      </div>
      {!isError && (
        <Pagination
          mt={"md"}
          page={pageIndex + 1}
          onChange={onChangePageHandler}
          total={pageCount}
        />
      )}
    </>
  );
}

export default FancyTable;
