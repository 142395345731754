import { Box as Flex, Image, Loader, Stack } from "@mantine/core";
import useAvatarData from "../../hooks/avatar/useAvatarData";
import UpdateProfileImageButton from "../buttons/UpdateAvatarButton";

export default function ProfileImageTab() {
  const { data: avatar, isFetching } = useAvatarData();

  const src = !avatar ? undefined : (avatar as string);

  return (
    <Stack>
      {isFetching ? (
        <Flex
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
            width: 300,
          }}
        >
          <Loader />
        </Flex>
      ) : (
        <Image
          width={300}
          height={300}
          styles={{
            image: {
              objectFit: "cover",
            },
          }}
          withPlaceholder
          src={src}
        />
      )}
      <UpdateProfileImageButton />
    </Stack>
  );
}
