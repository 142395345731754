import { Group, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { Report, SchoolClass } from "../../../../types";

type ReportNameTextProps = {
  report: Report;
};

export default function ReportNameText(props: ReportNameTextProps) {
  const { report } = props;

  const schoolClass =
    report.student?.school_classes
      ?.filter((sc: SchoolClass) => sc.school_year?.active)
      ?.slice(0, 1)[0] ?? null;

  return (
    <>
      {report.student ? (
        <Group spacing={5}>
          <Text>
            {report.student?.last_name}, {report.student?.first_name}{" "}
            {report.student?.last_name_affix}
          </Text>
          {schoolClass && <Text c="dimmed">{schoolClass.name}</Text>}
          {schoolClass?.school_year && (
            <Text c="dimmed">
              {"- "}
              {schoolClass.school_year?.name}
            </Text>
          )}
        </Group>
      ) : (
        <Group spacing={5} noWrap>
          <IconTrash color={"red"} size={18} />
          <Text color={"red"}>Niet beschikbaar</Text>
        </Group>
      )}
    </>
  );
}
