import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { Role } from "../../../types";
import { UserContext } from "../../providers/user";

export default function useMutateRoleData(
  mutationFn: (args: any) => Promise<unknown>,
  onSuccess?: (values: Role) => void,
  onError?: (error: string) => void
) {
  const queryClient = useQueryClient();
  const { refetch: refetchUser } = useContext(UserContext);

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      // Reload role data
      queryClient.invalidateQueries(["roles"]);

      // Reload the permissions of the current user,
      // since it might be possible that the user has changed permissions that are applicable to himself.
      refetchUser();

      onSuccess?.(data as any);
    },
    onError: (data) => onError?.(data as any),
  });
}
