import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { formatErrors, isArray, isEmptyArray, setAuthToken } from "../../tools";
import { UserContext } from "../../providers/user";
import { API_URL, FETCH_HEADERS } from "../../constants";

type LoginProps = {
  email: string;
  password: string;
};

type LoginValues = {
  email: string;
  password: string;
  device_name: string;
};

export default function useLogin() {
  const { refetch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async function (loginValues: LoginValues) {
    return await fetch(`${API_URL}/auth/login`, {
      method: "POST",
      ...FETCH_HEADERS(),
      body: JSON.stringify(loginValues),
    }).then((res) => res.json());
  };

  /**
   * Login the user.
   */
  const login = async function (loginProps: LoginProps) {
    setLoading(true);

    const res = await fetchData({ ...loginProps, device_name: window.navigator.userAgent });

    if (res?.data) {
      setAuthToken(res.data.token);

      refetch();
      navigate("/");

      setLoading(false);
      return;
    }

    let errors = formatErrors(res);

    showNotification({
      title: `Er ging iets mis`,
      message: errors ? errors[0] : "Het e-mailadres of wachtwoord is onjuist.",
      color: "red",
    });

    setLoading(false);
  };

  return {
    login,
    loading,
  };
}
