import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import useTwoFactorAuth from "../../../hooks/auth/2fa/useTwoFactorAuth";
import { validateString } from "../../../validator";
import { useEffect } from "react";

export default function TwoFactorAuthForm() {
  const { loading, login } = useTwoFactorAuth();

  const form = useForm({
    initialValues: {
      otp: "",
    },
    validate: {
      otp: (value) => validateString(value, "OTP-code", { numbers: true, length: 6 }),
    },
  });

  useEffect(() => {
    if (form.isValid("otp")) {
      login(form.values);
    }
  }, [form.values.otp]);

  return (
    <form onSubmit={form.onSubmit((values) => login(values))} style={{ width: "100%" }}>
      <TextInput
        data-autofocus
        required
        readOnly={loading}
        label="OTP-code"
        placeholder="123456"
        mb={"md"}
        maxLength={6}
        {...form.getInputProps("otp")}
      />

      <Button fullWidth disabled={!form.isValid("otp") || loading} type="submit">
        Verstuur
      </Button>
    </form>
  );
}
