import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableSchoolYear, SchoolYear } from "../../../../types";
import { createSchoolYear } from "../../../api/school-years";
import useMutateSchoolYearData from "../../../hooks/school-year/useMutateSchoolYearData";
import SchoolYearForm from "./SchoolYearForm";

type CreateSchoolYearFormProps = {
  onClose: () => void;
};

function CreateSchoolYearForm(props: CreateSchoolYearFormProps) {
  const { onClose } = props;

  const onSuccess = (values: SchoolYear) => {
    showNotification({
      title: "Schooljaar aangemaakt",
      icon: <IconCheck />,
      message: `Schooljaar ${values.name} is aangemaakt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van het schooljaar. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateSchoolYearData(createSchoolYear, onSuccess, onError);

  const onSubmit = (values: PatchableSchoolYear) => mutate(values);

  return (
    <>
      <SchoolYearForm isLoading={isLoading} submitAction={(values) => onSubmit(values)} />
    </>
  );
}

export default CreateSchoolYearForm;
