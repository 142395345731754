import { Rating, Section } from "../../types";
import { API_URL, FETCH_HEADERS } from "../constants";
import { handleRequestErrors } from "../tools";

type FetchRatingProps = {
    reportTemplateId: string;
    sectionId: string;
    ratingId: string;
}

export const fetchRating = async (values: FetchRatingProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.sectionId}/rating/${values.ratingId}`, {
        ...FETCH_HEADERS()
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
};

type MutateRatingProps = {
    reportTemplateId: string;
    sectionId: string;
    rating: Rating;
}

export const createRating = async (values: MutateRatingProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.sectionId}/rating`, {
        ...FETCH_HEADERS(),
        method: 'POST',
        body: JSON.stringify(values.rating)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const updateRating = async (values: MutateRatingProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.sectionId}/rating/${values.rating.id}`, {
        ...FETCH_HEADERS(),
        method: 'PUT',
        body: JSON.stringify(values.rating)
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .then((data) => {
            return data.data;
        })
        .catch((error) => {
            throw error.message;
        });
}

export const deleteRating = async (values: FetchRatingProps) => {
    return await fetch(`${API_URL}/report-template/${values.reportTemplateId}/section/${values.sectionId}/rating/${values.ratingId}`, {
        ...FETCH_HEADERS(),
        method: 'DELETE',
    })
        .then((data) => handleRequestErrors(data))
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            throw error.message;
        });
}