import { Button, Group, Title } from "@mantine/core";
import { IconDotsVertical, IconPencil, IconPlus, IconTrash } from "@tabler/icons-react";
import { useContext } from "react";
import { Section } from "../../types";
import useCreateRating from "../hooks/rating/useCreateRating";
import useDeleteSection from "../hooks/section/useDeleteSection";
import { UserContext } from "../providers/user";
import SectionNameInput from "./inputs/SectionNameInput";
import TemplateSectionRatingList from "./lists/TemplateSectionRatingList";
import ReportSectionContainer from "./ReportSectionContainer";
import useEditSection from "../hooks/section/useEditSection";

type ReportTemplateSectionProps = {
  reportTemplateId: string;
  section: Section;
  listeners: any;
  attributes: any;
};

export default function ReportTemplateSection(props: ReportTemplateSectionProps) {
  const { isAuthorized } = useContext(UserContext);
  const { reportTemplateId, section, listeners, attributes } = props;
  const { openModal: openDeleteSection } = useDeleteSection();
  const { openModal: openCreateRating } = useCreateRating();
  const { openModal: openEditSection } = useEditSection();

  return (
    <ReportSectionContainer>
      <Group position="apart" mb={"md"}>
        <Group spacing={5} sx={{ flexGrow: 1 }}>
          {isAuthorized("report_template_update") && (
            <IconDotsVertical
              style={{
                cursor: "grab",
                flexGrow: 0,
              }}
              {...attributes}
              {...listeners}
            />
          )}
          <SectionNameInput
            sx={{ flexGrow: 1 }}
            reportTemplateId={reportTemplateId}
            section={section}
            readOnly={!isAuthorized("report_template_update")}
          />
        </Group>
        {isAuthorized("report_template_update") && (
          <Group spacing={5}>
            <Button onClick={() => openEditSection({ reportTemplateId, sectionId: section.id })}>
              <IconPencil />
            </Button>
            <Button
              color={"red"}
              onClick={() => openDeleteSection({ reportTemplateId, sectionId: section.id })}
            >
              <IconTrash />
            </Button>
          </Group>
        )}
      </Group>

      <Group position="apart" mb={"md"} sx={{ alignItems: "flex-start" }}>
        <Title order={5}>Beoordelingspunten</Title>
        {isAuthorized("report_template_update") && (
          <Button
            color={"green"}
            onClick={() => openCreateRating({ reportTemplateId, sectionId: section.id })}
          >
            <IconPlus />
          </Button>
        )}
      </Group>

      <TemplateSectionRatingList
        reportTemplateId={reportTemplateId}
        section={section}
        ratings={section.ratings}
      />
    </ReportSectionContainer>
  );
}
