import { Button, Center, Container, createStyles, Group, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colors.gray[9],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export default function NotFound() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Center sx={{ height: "100%" }}>
      <Container className={classes.root}>
        <div className={classes.label}>404</div>
        <Title className={classes.title}>Pagina niet gevonden</Title>
        <Text color="dimmed" size="lg" align="center" className={classes.description}>
          De pagina die je probeert te bezoeken bestaat niet. Controleer de URL en probeer het
          opnieuw.
        </Text>
        <Group position="center">
          <Button variant="subtle" size="md" onClick={() => navigate("/")}>
            Terug naar de startpagina
          </Button>
        </Group>
      </Container>
    </Center>
  );
}
