import { PatchableSection, Section } from "../../../../types";
import { showNotification } from "@mantine/notifications";
import useMutateSectionData from "../../../hooks/section/useMutateSectionData";
import { createSection } from "../../../api/sections";
import SectionForm from "./SectionForm";
import { IconCheck } from "@tabler/icons-react";

type CreateSectionFormProps = {
  reportTemplateId: string;
  onClose: () => void;
};

function CreateSectionForm(props: CreateSectionFormProps) {
  const { reportTemplateId, onClose } = props;

  const onSuccess = (values: Section) => {
    showNotification({
      title: "Sectie aangemaakt",
      icon: <IconCheck />,
      message: `De sectie ${values.name} is aangemaakt`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van de sectie. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateSectionData(createSection, onSuccess, onError);

  const onSubmit = (values: PatchableSection) => {
    mutate({
      reportTemplateId,
      section: values,
    });
  };

  return (
    <>
      <SectionForm
        isLoading={isLoading}
        submitAction={(values: PatchableSection) => onSubmit(values)}
      />
    </>
  );
}

export default CreateSectionForm;
