import { Button, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { cleanNotifications, showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { finishReport } from "../../api/reports";
import useMutateReportData from "./useMutateReportData";

export default function useFinishReport() {
  const modals = useModals();

  const onSuccess = () => {
    cleanNotifications();

    showNotification({
      title: "Verslag afgerond",
      icon: <IconCheck />,
      message: `Het verslag is afgerond.`,
    });
  };

  const onError = (error: string) => {
    cleanNotifications();

    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het afronden van het verslag. ${error}`,
      color: "red",
      autoClose: 20000,
    });
  };

  const { mutate } = useMutateReportData(finishReport, onSuccess, onError);

  const onSubmit = async (
    modalId: string,
    reportId: string,
    period: string | number
  ) => {
    modals.closeModal(modalId);

    showNotification({
      disallowClose: true,
      loading: true,
      title: "Verslag afronden",
      message: "Bezig met afronden ...",
    });

    await mutate({ reportId, period });
  };

  type openModalProps = {
    reportId: string;
    period: string | number;
  };

  const openModal = (props: openModalProps) => {
    const { reportId, period } = props;

    const modalId = modals.openModal({
      centered: true,
      title: "Weet je het zeker dat je dit verslag wilt afronden?",
      children: (
        <>
          <Text size="sm" mb={"md"}>
            Je staat op het punt om een verslag af te ronden in het systeem. Dit
            is een permanente actie en kan alleen ongedaan worden gemaakt door
            een administrator. Weet je het zeker?
          </Text>
          <Group grow>
            <Button onClick={() => modals.closeModal(modalId)}>
              Annuleren
            </Button>
            <Button
              onClick={() => onSubmit(modalId, reportId, period)}
              color="red"
            >
              Afronden
            </Button>
          </Group>
        </>
      ),
    });
  };

  return {
    openModal,
  };
}
