import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Box, Button, Group, LoadingOverlay, ScrollArea, Stack, Text } from "@mantine/core";
import { IconDotsVertical, IconPencil, IconTrash } from "@tabler/icons-react";
import { useContext } from "react";
import { Rating, Section } from "../../../types";
import { updateSection } from "../../api/sections";
import useDeleteRating from "../../hooks/rating/useDeleteRating";
import useEditRating from "../../hooks/rating/useEditRating";
import useMutateSectionData from "../../hooks/section/useMutateSectionData";
import { UserContext } from "../../providers/user";
import SortableItem from "../SortableItem";
import TemplateRatingDetailsTable from "../tables/TemplateRatingDetailsTable";

type SectionRatingListProps = {
  reportTemplateId: string;
  section: Section;
  ratings: Rating[];
};

export default function TemplateSectionRatingList(props: SectionRatingListProps) {
  const { reportTemplateId, section } = props;
  const { isAuthorized } = useContext(UserContext);
  const { openModal: openEditRating } = useEditRating();
  const { openModal: openDeleteRating } = useDeleteRating();
  const { mutate: mutateSection, isLoading: isMutating } = useMutateSectionData(updateSection);
  const isLoading = isMutating;

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const ratings: Rating[] = section?.ratings ?? [];
  const ratingIds = ratings.map((rating: Rating) => rating.id);

  const sortedData: any =
    ratingIds.map((id: string) => ratings.find((item: Rating) => item.id === id)) ?? [];

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activeIndex = ratingIds.indexOf(active.id as string);
      const overIndex = ratingIds.indexOf(over?.id as string);

      mutateSection({
        reportTemplateId,
        section: { ...section, rating_order: arrayMove(ratingIds, activeIndex, overIndex) },
      });
    }
  };

  const itemRenderer = (rating: Rating, listeners: any, attributes: any) => (
    <Stack spacing={5}>
      <Group position={"apart"} sx={{ alignItems: "flex-start", flexWrap: "nowrap" }}>
        <Group spacing={5} sx={{ flexWrap: "nowrap" }}>
          {isAuthorized("report_template_update") && (
            <Box>
              <IconDotsVertical
                style={{
                  cursor: "grab",
                  flexGrow: 0,
                }}
                {...attributes}
                {...listeners}
              />
            </Box>
          )}
          <Text weight={"bold"} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {rating.name}{" "}
            <Text sx={{ display: "inline" }} c={"dimmed"} size={"xs"}>
              ({rating.required ? "verplicht" : "optioneel"})
            </Text>
          </Text>
        </Group>
        {isAuthorized("report_template_update") && (
          <Group spacing={5}>
            <Button
              onClick={() =>
                openEditRating({ reportTemplateId, sectionId: section.id, ratingId: rating.id })
              }
            >
              <IconPencil />
            </Button>
            <Button
              color={"red"}
              onClick={() =>
                openDeleteRating({ reportTemplateId, sectionId: section.id, ratingId: rating.id })
              }
            >
              <IconTrash />
            </Button>
          </Group>
        )}
      </Group>
      <TemplateRatingDetailsTable rating={rating} />
    </Stack>
  );

  return (
    <ScrollArea offsetScrollbars sx={{ flexGrow: 1 }}>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <LoadingOverlay visible={isLoading} />
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
          <SortableContext items={sortedData} strategy={verticalListSortingStrategy}>
            <Box sx={{ display: "flex", gap: 16, height: "100%", flexDirection: "column" }}>
              {ratings.map((rating: Rating) => (
                <SortableItem
                  key={rating.id}
                  id={rating.id}
                  item={rating}
                  itemRenderer={itemRenderer}
                />
              ))}
            </Box>
          </SortableContext>
        </DndContext>
      </div>
    </ScrollArea>
  );
}
