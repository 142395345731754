import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { PatchableSchoolClass, SchoolClass } from "../../../../types";
import { createSchoolClass } from "../../../api/school-class";
import useMutateSchoolClassData from "../../../hooks/school-class/useMutateSchoolClassData";
import SchoolClassForm from "./SchoolClassForm";

type CreateSchoolClassFormProps = {
  onClose: () => void;
};

function CreateSchoolClassForm(props: CreateSchoolClassFormProps) {
  const { onClose } = props;

  const onSuccess = (values: SchoolClass) => {
    showNotification({
      title: "Klas aangemaakt",
      icon: <IconCheck />,
      message: `Klas ${values.name} is aangemaakt.`,
    });

    onClose();
  };

  const onError = (error: string) => {
    showNotification({
      title: `Er ging iets mis`,
      message: `Er is een fout opgetreden tijdens het aanmaken van de klas. ${error}`,
      color: "red",
    });
  };

  const { mutate, isLoading } = useMutateSchoolClassData(createSchoolClass, onSuccess, onError);

  const onSubmit = (values: PatchableSchoolClass) => mutate(values);

  return (
    <>
      <SchoolClassForm isLoading={isLoading} submitAction={(values) => onSubmit(values)} />
    </>
  );
}

export default CreateSchoolClassForm;
