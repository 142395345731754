import { Button, createStyles, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { PatchableStudent, SchoolClass, Student } from "../../../../types";
import { fetchSchoolClasses } from "../../../api/school-class";
import { validateString } from "../../../validator";
import FancyMultiSelect from "../../selects/FancyMultiSelect";

type StudentFormProps = {
  student?: Student;
  submitText?: string;
  submitAction: (values: PatchableStudent) => void;
  isLoading?: boolean;
};

const useStyles = createStyles((theme, _params, getRef) => ({
  group: {
    alignItems: "flex-start",
  },
}));

function StudentForm(props: StudentFormProps) {
  const { student, submitText, submitAction, isLoading = false } = props;
  const { classes } = useStyles();

  const form = useForm<PatchableStudent>({
    initialValues: {
      id: student?.id ?? "",
      first_name: student?.first_name ?? "",
      last_name_affix: student?.last_name_affix ?? "",
      last_name: student?.last_name ?? "",
      school_classes: student?.school_classes?.map((i: SchoolClass) => i.id) ?? [],
    },

    validate: {
      first_name: (value) => validateString(value),
      last_name: (value) => validateString(value),
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitAction(values))}>
      <TextInput
        mb={"md"}
        data-autofocus
        required
        label="Voornaam"
        {...form.getInputProps("first_name")}
      />

      <Group className={classes.group} grow pb={"md"}>
        <TextInput label="Tussenvoegsel" {...form.getInputProps("last_name_affix")} />
        <TextInput required label="Achternaam" {...form.getInputProps("last_name")} />
      </Group>

      <FancyMultiSelect<SchoolClass>
        onSelectedValuesChange={(values) => form.setFieldValue("school_classes", values)}
        defaultValue={student?.school_classes?.map((val: SchoolClass) => `${val.id}`)}
        queryFn={(query: string) => fetchSchoolClasses(0, 100, [], query)}
        labelRenderer={(val) =>
          `${val.name} (${val.school_year?.name}${
            !val.school_year?.active ? " - niet actief" : ""
          })`
        }
        label="Klas"
        pb={"md"}
      />

      <Group position="right" mt="md">
        <Button loading={isLoading} type="submit">
          {submitText ?? "Opslaan"}
        </Button>
      </Group>
    </form>
  );
}

export default StudentForm;
